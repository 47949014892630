/**
 * Module definition and dependencies
 */
angular.module('App.MesContacts', [
    'App.MesContacts.Controller',
    'App.Crm.FiltreContacts',
    'ngTable',
    'infinite-scroll'
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('mesContacts', {
            parent: 'app',
            url: '/mes-contacts',
            component: 'mesContactsRoute',
            params: {
                filtres: null,
            },
            data: {
                auth: true,
                isActive: 'contacts',
            },
        });
    })

    /**
     * Route component
     */
    .component('mesContactsRoute', {
        controller: 'MesContactsCtrl',
        templateUrl: 'mes-contacts/mes-contacts.html',
    })
    .component('mesContactsList', {
        controller: 'ContactsListViewCtrl',
        templateUrl: 'mes-contacts/mes-contacts-list.html',
        bindings: {
            filtresContacts: '<',
            nombreResultat: '=',
            contactsSelectionner: '=',
            besoinContact : '=',
            vendeurSelected: '<',
        }
    })
    .component('mesContactsCards', {
        controller: 'ContactsCardsViewCtrl',
        templateUrl: 'mes-contacts/mes-contacts-cards.html',
        bindings: {
            filtresContacts: '<',
            nombreResultat: '=',
            contactsSelectionner: '=',
            loading: '=',
        }
    })
    .component('rechercheContacts', {
        controller: 'RechercheContactsCtrl',
        templateUrl: 'mes-contacts/rechercheContacts.html',
        bindings: {
            filtresContacts: '=',
            loading: '<',
            contactsSelectionner: '<',
            nombreResultat: '<',
        }
    })
    .component('rechercheContactsBesoin', {
      controller: 'RechercheContactsCtrl',
      templateUrl: 'mes-contacts/rechercheContactsBesoin.html',
      bindings: {
        filtresContacts: '=',
        loading: '<',
        contactsSelectionner: '<',
        nombreResultat: '<',
      }
    })
    .component('contactsPanel', {
      controller: 'ContactsPanelCtrl',
      templateUrl: 'mes-contacts/contacts-panel.html',
      bindings: {
        params: '<',
      }
    });