/**
 * Module definition and dependencies
 */
angular
  .module("App.Coacquereurs", ["App.Coacquereurs.Controller"])

  /**
   * Config
   */
  .component("coacquereurs", {
    controller: "coacquereursCtrl",
    templateUrl: "coacquereurs/coacquereurs.html",
    bindings: {
      contact: "<",
    },
  });
