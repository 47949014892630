/**
 * Module definition and dependencies
 */
angular.module('App.Crm.ContactsDenonces', [
  'App.Crm.ContactsDenonces.Controller',
])

  .component('tableContactsDenonces', {
    controller: 'TableContactsDenoncesCtrl',
    templateUrl: 'crm/contacts-denonces/table-contacts-denonces.html',
  });
