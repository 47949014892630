/**
 * Module definition and dependencies
 */
function downloadURI(uri, name) {
  var link = document.createElement("a");
  link.download = name;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

angular
  .module("App.Admin.Export.Controller", ["transactionsService.Service"])

  /**
   * Controller
   */
  .controller("exportContactsCtrl", [
    "toaster",
    "Contacts",
    function(toaster, Contacts) {
      var self = this;
      self.exportExcelContacts = function() {
        var query = angular.copy(self.filtres);
        query.output = "xls";
        query.limit = -1;
        return Contacts.queryExport(query)
          .$promise.then(function(response) {
            if (response.url) {
              downloadURI(response.url);
              // maWindow = window.open();
              // maWindow.location.href = response.url;
              // setTimeout(function() {
              //     maWindow.close();
              // }, 10);
            }
          })
          .catch(function(err) {
            console.log("promise error", err);
          });
      };
    }
  ])
  .controller("exportTransactionsCtrl", [
    "Transactions",
    function(Transactions) {
      var self = this;
      self.exportExcelTransactions = function() {
        var query = angular.copy(self.filtres);
        query.output = "xls";
        query.limit = -1;
        return Transactions.queryExport(query)
          .$promise.then(function(response) {
            if (response.url) {
              downloadURI(response.url);
            }
          })
          .catch(function(err) {
            console.log("promise error", err);
          });
      };
    }
  ])
  .controller("exportActionsCtrl", [
    "Actions",
    function(Actions) {
      var self = this;
      self.exportExcelActions = function() {
        var query = angular.copy(self.filtres);
        query.output = "xls";
        query.limit = -1;
        return Actions.queryNoTransformResponse(query)
          .$promise.then(function(response) {
            if (response.url) {
              downloadURI(response.url);
            }
          })
          .catch(function(err) {
            console.log("promise error", err);
          });
      };
    }
  ]);
