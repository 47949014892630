/**
 * Module definition and dependencies
 */
angular.module('App.FiltreOrder.Controller', []).controller('filtreOrderCtrl', [
  '$scope',
  'debounce',
  function($scope, debounce) {
    let self = this;
    self.defaultSort;

    self.$onInit = function() {
      angular.forEach(self.listeElements, function(item) {
        if (item.default) {
          self.defaultSort = item.value;
        }
      });
      if (!self.defaultOrder) {
        self.defaultOrder = 'desc';
      }
      self.filtreOrder = {
        order: self.defaultOrder,
        sort: self.defaultSort,
      };
    };

    self.changeOrder = function() {
      self.filtreOrder.order =
        self.filtreOrder.order === 'asc' ? 'desc' : 'asc';
    };

    $scope.$watch(
      () => this.filtres,
      debounce(200, function() {
        if (self.filtres) {
          self.filtreOrder.order =
            self.filtres.order !== undefined
              ? self.filtres.order
              : self.filtreOrder.order;
          self.filtreOrder.sort =
            self.filtres.sort !== undefined
              ? self.filtres.sort
              : self.filtreOrder.sort;
        }
      }),
      true
    );

    $scope.$watch(
      () => this.filtreOrder,
      debounce(200, function() {
        angular.extend(self.filtres, self.filtreOrder);
      }),
      true
    );
  },
]);
