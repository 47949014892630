angular.module('App.AuthDirective', [])
.directive('adminOnly', function($compile, Auth) {
    return {
        restrict: 'A',
        priority: 1,
        link: function(scope, element, attrs) {
            if (!Auth.hasRole('admin')) {
                element.remove();
            }
        },
    };
})
.directive('adminPromoteurOnly', function($compile, Auth) {
    return {
        restrict: 'A',
        priority: 1,
        link: function(scope, element, attrs) {
            if (!Auth.hasRole('admin') || (Auth.hasRole('admin') && Auth.hasRole('partenaire')) ) {
                element.remove();
            }
        },
    };
});
