/**
 * Module definition and dependencies
 */
angular.module('App.Panier', [
    'App.Panier.Controller',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('panier', {
            parent: 'app',
            url: '/panier',
            component: 'panier',
            data: {
                auth: true,
            },
        });
    })
    /**
     * Route component
     */
    .component('panier', {
        controller: 'PanierCtrl',
        templateUrl: 'panier/panier.html',
        bindings: {
            contact: '<',
            hidePanelHeading: '<'
        }
    })
    .component('panierBtn', {
        controller: 'PanierBtnCtrl',
        templateUrl: 'panier/panier-btn.html',
        bindings: {
            contact: '<',
            lot: '<',
            panierContact: '=',
        }
    });
