/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsContacts.Controller', [
  'statsService.Service',
])
  .controller('ListeStatsContactsCtrl', ['$state', 'StatsContacts',
    function ($state, StatsContacts) {
      var self = this;
      self.$onInit = function () {
          var filter = {
              is_client : 1,
              is_contact : 1,
          };
          getStatsContacts(filter);
      };

      self.lienContactGroupe = function(id) {
        $state.go('mesContacts', {filtres: { groupe_avancement:id }} );
      }

      self.getPercent = function (val) {
        if (self.totalStatsContacts === 0) {
          return 0;
        }
        return Math.round(val / self.totalStatsContacts * 100);
      };

      function getStatsContacts(params){
          StatsContacts.getStatsContacts(params).then(function (response) {
              self.totalStatsContacts = response.data.total;
              self.statsContacts = response.data.data;
          },function errorCallback(response) {
              console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
              return false;
          });
      }

    }
  ])
.filter('removeSpaces', [function() {
  return function(string) {
    if (!angular.isString(string)) {
      return string;
    }
    return string.replace(/[\s]/g, '');
  };
}]);
