angular.module("Login.Service", []).factory("Login", [
  "Config",
  "$http",
  function (Config, $http) {
    return {
      prelogin: function (email, password) {
        return $http.post(Config.API_BASE_URL_PUBLIC + "/auth/prelogin", {
          email: email,
          password: password,
        });
      },
      login: function (email, password, employeurId, discr) {
        return $http
          .post(Config.API_BASE_URL_PUBLIC + "/auth/login", {
            email: email,
            password: password,
            commercialisateur_id: employeurId,
            discr: "membre",
            referer: "immoseller",
          })
          .then((result) => {
            return result.data;
          });
      },
    };
  },
]);
