/**
 * Module definition and dependencies
 */
angular
  .module('App.Profil', ['App.Profil.Controller'])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider
      .state('profil', {
        parent: 'app',
        abstract: true,
        url: '/profil',
        component: 'profil',
        resolve: {
          membre: function(Profil) {
            return Profil.get().$promise;
          },
        },
        data: {
          auth: true,
        },
      })
      .state('profil.monprofil', {
        url: '/monprofil',
        templateUrl: 'profil/tabs/monprofil.html',
        controller: 'MonProfilCtrl',
        controllerAs: '$ctrl',
      })
      .state('profil.monpanier', {
        url: '/monpanier',
        templateUrl: 'profil/tabs/monpanier.html',
        controller: function() {},
      });
  })
  .component('profil', {
    controller: 'ProfilCtrl',
    templateUrl: 'profil/profil.html',
    bindings: {
      membre: '<',
    },
  });
