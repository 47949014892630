/**
 * Module definition and dependencies
 */
angular
  .module("App.Crm.Action.Controller", [
    "actionsService.Service",
    "contactsService.Service",
    "Crm.AsDateDirective",
    "configsService.Service"
  ])

  .controller("TableActionCtrl", [
    "ConfigsContacts",
    "Actions",
    "Contacts",
    "$state",
    "toaster",
    "$q",
    function(ConfigsContacts, Actions, Contacts, $state, toaster, $q) {
      let self = this;
      self.hstep = 1;
      self.mstep = 1;
      self.ismeridian = false;
      self.$onInit = function() {
        getConfigsContacts();
        getAvancements();
        self.action = new Actions();
        self.contact = self.contact;
      };
      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Paris"
      };
      self.$onInit = function() {
        if (self.contact) {
          self.initialContact = true;
        }
      };
      self.saveAction = function() {
        self.action.date_echeance.date = moment(self.action.date_echeance.date);
        self.action.contact_id = self.contact.id;
        $q.all([self.contact.$update(), self.action.$save()])
          .then(function(response) {
            toaster.pop({
              type: "success",
              title: "",
              body: "Votre Action a bien été ajouté"
            });
            $state.go("mesActions");
          })
          .catch(function(err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de l'ajout de votre action "
            });
          });
      };

      function getAvancements() {
        return ConfigsContacts.getAvancements().then(
          function successCallback(response) {
            return (self.listeConfigAvancement = response.data);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
      }

      function getConfigsContacts() {
        return ConfigsContacts.getActions().then(
          function successCallback(response) {
            return (self.listeConfigActions = response.data);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
      }
    }
  ])
  .controller("actionModalCtrl", [
    "$uibModalInstance",
    "ConfigsContacts",
    "Actions",
    "Contacts",
    "toaster",
    "contact",
    "$state",
    "actionId",
    "ConfigsCalendriers",
    "$q",
    function(
      $uibModalInstance,
      ConfigsContacts,
      Actions,
      Contacts,
      toaster,
      contact,
      $state,
      actionId,
      ConfigsCalendriers,
      $q
    ) {
      let self = this;
      self.hstep = 1;
      self.mstep = 1;
      self.ismeridian = false;
      self.$onInit = function() {
        ConfigsCalendriers.getConfigsAlertes().then(
          function successCallback(response) {
            self.listeRappel = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsCalendriers getConfigsAlertes : " +
                response
            );
            return false;
          }
        );

        ConfigsContacts.getAvancements().then(
          function successCallback(response) {
            self.listeConfigAvancement = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
        ConfigsContacts.getActions().then(
          function successCallback(response) {
            self.listeConfigActions = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );

        if (contact) {
          self.loadMembreConnecter = true;
          self.contact = contact;
          self.action = new Actions({
            date_echeance: {
              date: Date.now()
            },
            contact_id: contact.id
          });
        }

        if (actionId) {
          self.editAction = true;
          self.actionId = actionId;
          self.loadMembreConnecter = false;

          Actions.queryNoTransformResponse({ id: actionId }).$promise.then(
            function successCallback(response) {
              self.action = response;
              if (response.vendeur_id) {
                self.vendeur = response.vendeur_id;
              }

              Contacts.queryExport({
                contactId: response.contact_id
              }).$promise.then(
                function successCallback(resp) {
                  self.contact = resp;
                },
                function errorCallback(error) {
                  console.log("Erreur factory Contacts query : " + error);
                  return false;
                }
              );
            },
            function errorCallback(error) {
              console.log(
                "Erreur factory Actions queryNoTransformResponse : " + error
              );
              return false;
            }
          );
        }

        if (!self.action.alarm_trigger) {
          self.action.alarm_trigger = "0";
        }
      };

      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Paris"
      };

      self.saveAction = function() {
        self.action.alarm = 0;
        if (self.action.alarm_trigger && self.action.alarm_trigger != "0") {
          self.action.alarm = 1;
        }
        if (self.vendeur) {
          self.action.vendeur_id = self.vendeur;
        }
        if (self.action.date_echeance && self.action.date_echeance.date) {
          self.action.date_echeance = moment(
            self.action.date_echeance.date
          ).format("YYYY-MM-DD HH:mm:ss");
        }
        var promise = self.contact.$update();
        var promise2;
        if (self.action.id && self.action.contact_id) {
          promise2 = self.action.$update();
        } else {
          promise2 = self.action.$save();
        }
        $q.all([promise, promise2])
          .then(function(response) {
            contact
              .$get()
              .then(function() {
                toaster.pop({
                  type: "success",
                  title: "",
                  body: "L'action a bien été enregistrée."
                });
                $uibModalInstance.close(response);
                $state.reload();
              })
              .catch(function(error) {
                console.log(error);
              });
          })
          .catch(function(err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de l'ajout de votre action "
            });
          });
      };

      self.annuler = function() {
        $uibModalInstance.dismiss("cancel");
      };
    }
  ])
  .controller("newActionModalCtrl", [
    "$uibModalInstance",
    "ConfigsContacts",
    "Actions",
    "toaster",
    "$rootScope",
    "contactId",
    "ConfigsCalendriers",
    "$q",
    "$state",
    function(
      $uibModalInstance,
      ConfigsContacts,
      Actions,
      toaster,
      $rootScope,
      contactId,
      ConfigsCalendriers,
      $q,
      $state
    ) {
      let self = this;
      self.hstep = 1;
      self.mstep = 1;
      self.ismeridian = false;
      self.contact = null;
      self.$onInit = function() {
        ConfigsCalendriers.getConfigsAlertes().then(
          function successCallback(response) {
            self.listeRappel = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsCalendriers getConfigsAlertes : " +
                response
            );
            return false;
          }
        );
        ConfigsContacts.getAvancements().then(
          function successCallback(response) {
            self.listeConfigAvancement = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
        ConfigsContacts.getActions().then(
          function successCallback(response) {
            self.listeConfigActions = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
        self.action = new Actions({
          date_echeance: {
            date: Date.now()
          }
        });
        if (contactId) {
          self.contactId = contactId;
        }
        self.action.alarm_trigger = "0";
      };
      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Paris"
      };
      self.saveAction = function() {
        self.action.alarm = 0;
        if (self.action.alarm_trigger && self.action.alarm_trigger != "0") {
          self.action.alarm = 1;
        }
        if (self.vendeur) {
          self.action.vendeur_id = self.vendeur;
        }
        self.action.date_echeance = moment(
          self.action.date_echeance.date
        ).format("YYYY-MM-DD HH:mm:ss");
        self.action.contact_id = self.contact.id;
        $q.all([self.action.$save(), self.contact.$update()])
          .then(function(response) {
            toaster.pop({
              type: "success",
              title: "",
              body: "L'action a bien été enregistrée."
            });
            $state.reload();
            $uibModalInstance.close(response);
            $rootScope.$broadcast("updateMesActions");
          })
          .catch(function(err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de l'ajout de votre action "
            });
          });
      };
      self.annuler = function() {
        $uibModalInstance.dismiss("cancel");
      };
    }
  ])
  .controller("actionCtrl", [
    "ConfigsContacts",
    "toaster",
    "$rootScope",
    "ConfigsCalendriers",
    function(ConfigsContacts, toaster, $rootScope, ConfigsCalendriers) {
      let self = this;
      self.hstep = 1;
      self.mstep = 1;
      self.ismeridian = false;
      self.$onInit = function() {
        ConfigsCalendriers.getConfigsAlertes().then(
          function successCallback(response) {
            self.listeRappel = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsCalendriers getConfigsAlertes : " +
                response
            );
            return false;
          }
        );

        ConfigsContacts.getActions().then(
          function successCallback(response) {
            self.listeConfigActions = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsActions getStatsActions : " + response
            );
            return false;
          }
        );
        if (!self.action.alarm_trigger) {
          self.action.alarm_trigger = "0";
        }
      };
      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Paris"
      };
      self.saveAction = function() {
        self.action.alarm = 0;
        if (self.action.alarm_trigger && self.action.alarm_trigger != "0") {
          self.action.alarm = 1;
        }
        if (self.vendeur) {
          self.action.vendeur_id = self.vendeur;
        }
        self.action.date_echeance = moment(
          self.action.date_echeance.date
        ).format("YYYY-MM-DD HH:mm:ss");
        return self.action
          .$update()
          .then(function(response) {
            toaster.pop({
              type: "success",
              title: "",
              body: "L'action a bien été enregistrée."
            });
            $rootScope.$broadcast("updateMesActions");
          })
          .catch(function(err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de l'ajout de votre action "
            });
          });
      };
    }
  ]);
