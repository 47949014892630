/**
 * Module definition and dependencies
 */
angular.module('App.Actualites', [
    'App.Actualites.Controller',
])

/**
 * Config
 */
    .component('actualitesProgramme', {
        controller: 'ActualitesProgrammeCtrl',
        templateUrl: 'actualites/actualites-programme.html',
        bindings: {
            programme: '<',
        },
    })
    .component('btnAjouterActualiteProgramme', {
        controller: 'BtnAjouterActualiteProgrammeCtrl',
        templateUrl: 'actualites/btn-ajouter-actualite-programme.html',
        bindings: {
            programme: '<',
        },
    });
