/**
 * Module definition and dependencies
 */
angular.module('denonciationsService.Service', [
  'ngResource',
])

/**
 * Service
 */
  .factory('Denonciations', ['$resource', 'Config', function ($resource, Config) {
    var denonciationsApi = $resource(Config.API_BASE_URL + '/denonciations/:contactId', {
      contactId: '@contactId'
    }, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    denonciationsApi.prototype.createOrUpdate = function () {
      if (this.id) {
        return this.$update();
      } else {
        return this.$save();
      }
    }
    return denonciationsApi;
  }])
  .factory('DenoncerContact', ['$resource', 'Config', function ($resource, Config) {
    var denonciationsApi = $resource(Config.API_BASE_URL + '/denonciations/', {
      contact_id: '@contactId',
      start_date: '@startDate',
    }, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    denonciationsApi.prototype.createOrUpdate = function () {
      if (this.id) {
        return this.$update();
      } else {
        return this.$save();
      }
    }
    return denonciationsApi;
  }])
  .factory('Denonciations', ['$resource', 'Config', function($resource, Config) {
    var api = $resource(Config.API_BASE_URL + '/denonciations/', {
    }, {
      query: {
        isArray: false
      }
    });
    return api;
  }])

