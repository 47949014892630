/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsBusiness', [
    'App.Crm.StatsBusiness.Controller',
  ])
  .component('listeStatsBusiness', {
    controller: 'ListeStatsBusinessCtrl',
    templateUrl: 'crm/stats-business/liste-stats-business.html',
    bindings: {
      startDate: '<',
      endDate: '<',
    },
  });
