/**
 * Module definition and dependencies
 */
angular
  .module("App.MesActions.Controller", [
    "actionsService.Service",
    "Crm.AsDateDirective",
    "configsService.Service",
    "contactsService.Service",
    "statsService.Service",
    "rzModule",
    "ngMessages",
  ])
  .controller("MesActionsCtrl", [
    "$stateParams",
    "$uibModal",
    "Actions",
    "$state",
    "$q",
    "toaster",
    "$rootScope",
    "$scope",
    function (
      $stateParams,
      $uibModal,
      Actions,
      $state,
      $q,
      toaster,
      $rootScope,
      $scope
    ) {
      var self = this;
      self.loading = false;
      self.actualiserStats = false;
      self.vendeurSelected = null;
      var formatDate = "YYYY-MM-DD";

      self.$onInit = function () {
        self.arrayInitDate = [];
        self.arrayInitDone = [];
        if (self.contactId) {
          self.getActionsContact();
        }
      };

      self.actionsToday = {
        title: "Aujourd'hui",
        id: "aujourdhui",
        isCollapsed: false,
        date: {
          date_start: moment().format(formatDate),
          date_end: moment().format(formatDate),
        },
        actions: [],
      };
      self.getIconClass = function (action) {
        var icons = {
          2: "person", // rdv physique
          3: "contact_phone", // rdv tel
          4: "phonelink_ring", // relance tel
          5: "person", // 1er appel
          7: "mail_outline", // envoyer un mail avant le
          9: "message", // compte rendu
        };
        return icons[action.action_id];
      };

      self.getLabelClass = function (action) {
        var label = {
          2: "Rdv", // rdv physique
          3: "Rdv Appel", // rdv tel
          4: "Appel", // relance tel
          5: "1er Appel", // 1er appel
          7: "Mail", // envoyer un mail avant le
          9: "Note", // compte rendu
        };
        return label[action.action_id];
      };
      self.actionsEnRetard = {
        title: "En retard",
        id: "enretrard",
        isCollapsed: true,
        date: {
          date_start: "",
          date_end: moment().subtract(1, "days").format(formatDate),
        },
        actions: [],
      };

      self.actionDemain = {
        title: "Demain",
        id: "demain",
        isCollapsed: false,
        date: {
          date_start: moment().add(1, "days").format(formatDate),
          date_end: moment().add(1, "days").format(formatDate),
        },
        actions: [],
      };

      self.actionsPlusTard = {
        title: "Plus tard",
        id: "plustard",
        isCollapsed: true,
        date: {
          date_start: moment().add(2, "days").format(formatDate),
          date_end: "",
        },
        actions: [],
      };

      self.actionsSansEcheances = {
        title: "Sans échéance",
        isCollapsed: true,
        date: {
          date_start: "",
          date_end: "",
        },
        actions: [],
        nodate: 1,
      };

      self.actionsRealisees = {
        title: false,
        isCollapsed: false,
        date: {
          date_start: "",
          date_end: "",
        },
        actions: [],
      };

      self.updateAction = function (action) {
        return $q
          .all([action.$update()])
          .then(function (response) {
            toaster.pop({
              type: "success",
              title: "",
              body: "Votre Action a été modifiée.",
            });
            $rootScope.$broadcast("updateMesActions");
          })
          .catch(function (err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de la modification de l'action.",
            });
          });
      };

      if ($stateParams.paramsFiltresActions) {
        self.paramsFiltresActions = $stateParams.paramsFiltresActions;
      }

      self.getActionsContact = function () {
        self.date = new Date();
        var params = {
          limit: 1,
          contact_id: self.contactId,
          done: 0,
        };
        Actions.query(params)
          .$promise.then(function (response) {
            self.listActionContact = response.data;
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };

      /*
       * Modal
       */
      self.newAction = function () {
        var modalInstance = $uibModal.open({
          templateUrl: "crm/action/modal-form-new-action.html",
          controller: "newActionModalCtrl",
          controllerAs: "$ctrl",
          resolve: {
            contactId: function () {
              return false;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            self.selected = selectedItem;
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };

      self.moveToAction = function (actionId) {
        $state.go("mesActions", { actionEnCours: actionId });
      };
    },
  ])
  .controller("MesActionsListCtrl", [
    "Actions",
    "$scope",
    "ConfigsContacts",
    "toaster",
    "Contacts",
    "$q",
    "debounce",
    "$state",
    "ListeContacts",
    "$uibModal",
    "$rootScope",
    function (
      Actions,
      $scope,
      ConfigsContacts,
      toaster,
      Contacts,
      $q,
      debounce,
      $state,
      ListeContacts,
      $uibModal,
      $rootScope
    ) {
      var self = this;
      self.expanded = 0;
      self.loadingPromise = $q.resolve();
      var limitPage = 8;
      var page = 1;
      var NbActionsByPage = 0;
      var totalActions = 0;
      self.loadingAction = false;
      var i = 0;

      self.$onInit = function () {
        self.getConfigsContacts();
        self.getAvancements();
        if (self.contact_id) {
          self.getActionsContact();
        }
        if (self.actionEnCours) {
          self.expanded = self.actionEnCours;
        }
      };

      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Berlin",
      };

      $scope.$on("updateMesActions", function (event, data) {
        console.log("on updateMesActions");
        self.queryActions(self.paramsDate);
      });

      self.queryActions = function (paramsActions, eventScroll) {
        return (self.loadingPromise = self.loadingPromise
          .then(function () {
            if (
              self.nombreResultat !== undefined &&
              self.nombreResultat <= paramsActions.actions.length &&
              eventScroll
            ) {
              self.allLoaded = true;
              return $q.resolve();
            } else if (!eventScroll) {
              page = 1;
              paramsActions.actions = [];
            }

            var params = {
              limit: limitPage,
              page: page,
              start_date: paramsActions.date.date_start,
              end_date: paramsActions.date.date_end,
            };

            if (paramsActions.nodate) {
              params.nodate = paramsActions.nodate;
            }

            angular.extend(params, self.paramsFiltresActions);

            self.loadingAction = true;

            return Actions.query(params).$promise.then(function (response) {
              page++;
              self.nombreResultat = response.total;
              paramsActions.totalActions = response.total;
              totalActions = response.total;

              angular.forEach(response.data, function (value, key) {
                if (value.id == self.actionEnCours) {
                  paramsActions.isCollapsed = false;
                }
                if (value.date_echeance) {
                  self.arrayInitDate[value.id] = value.date_echeance.date;
                }
                self.arrayInitDone[value.id] = value.done;
                self.getContact(value.contact_id);
                paramsActions.actions.push(value);
              });
            });
          })
          .then(function () {
            self.loadingAction = false;
          })
          .catch(function (err) {
            console.log("promise error", err);
          }));
      };

      self.disable = function (item) {
        if (item === true) {
          return true;
        } else {
          return false;
        }
      };

      self.format = function (date) {
        var dateresult = moment(Date.parse(date)).format();
        date = moment(dateresult).format("YYYY-MM-DD HH:mm:ss");
      };

      self.getContact = function getContact(id) {
        Contacts.get({
          contactId: id,
        })
          .$promise.then(function (response) {
            self.paramsDate.actions.map(function (item, index) {
              if (item.contact_id === id) {
                item.contact_action = response;
              }
            });
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };

      self.getAvancements = function getAvancements() {
        return ConfigsContacts.getAvancements().then(
          function successCallback(response) {
            return (self.listeConfigAvancement = response.data);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsContacts getAvancements : " + response
            );
            return false;
          }
        );
      };

      self.updateContact = function (action) {
        return $q
          .all([action.contact_action.$update()])
          .then(function (response) {
            toaster.pop({
              type: "success",
              title: "",
              body: "Votre avancement a été modifiée.",
            });
          })
          .catch(function (err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de la modification de l'avancement.",
            });
          });
      };

      self.updateAction = function (action) {
        if (action.date_echeance && action.date_echeance.date) {
          if (action.date_echeance.date instanceof Date) {
            action.date_echeance.date = moment(
              action.date_echeance.date
            ).format("YYYY-MM-DD HH:mm:ss");
          }
        }

        if (self.arrayInitDate) {
          if (
            action.date_echeance &&
            action.date_echeance.date &&
            self.arrayInitDate[action.id] !== action.date_echeance.date
          ) {
            self.actionReloadDate = true;
          }
        }
        if (self.arrayInitDone) {
          if (self.arrayInitDone[action.id] != action.done) {
            self.actionReloadDone = true;
          }
        }
        return action
          .$update()
          .then(function (response) {
            //Ajout du contact dans l'action
            Contacts.get({ contactId: response.contact_id })
              .$promise.then(function (result) {
                action.contact_action = result;
              })
              .catch(function (err) {
                console.log("promise error", err);
              });

            toaster.pop({
              type: "success",
              title: "",
              body: "Votre Action a été modifiée.",
            });

            // ajout classe fadeOut si validée
            var done = action.done !== false ? 1 : 0;

            if (self.paramsFiltresActions.done !== done) {
              action.event_realised = true;
            }

            $rootScope.$broadcast("updateMesActions");
          })
          .catch(function (err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de la modification de l'action.",
            });
          });
      };

      self.getConfigsContacts = function getConfigsContacts() {
        return ConfigsContacts.getActions().then(
          function successCallback(response) {
            return (self.listeConfigActions = response.data);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsContacts getActions : " + response
            );
            return false;
          }
        );
      };

      self.toggleAction = function (action) {
        if (self.expanded) {
          self.expanded = 0;
        } else {
          self.getContact(action.contact_id);
          self.expanded = action.id;
        }
      };

      self.scroll = function (paramsDate) {
        if (self.loadingAction || paramsDate.isCollapsed) {
          return;
        }
        self.queryActions(paramsDate, true);
      };

      self.editFormContact = function (contactId) {
        $state.go("formContact.historique", {
          contactId: contactId,
        });
      };

      self.getIconClass = function (action) {
        var icons = {
          2: "person", // rdv physique
          3: "contact_phone", // rdv tel
          4: "phonelink_ring", // relance tel
          5: "person", // 1er appel
          7: "mail_outline", // envoyer un mail avant le
          9: "message", // compte rendu
        };
        return icons[action.action_id];
      };

      self.getLabelClass = function (action) {
        var label = {
          2: "Rdv", // rdv physique
          3: "Rdv Appel", // rdv tel
          4: "Appel", // relance tel
          5: "1er Appel", // 1er appel
          7: "Mail", // envoyer un mail avant le
          9: "Note", // compte rendu
        };
        return label[action.action_id];
      };
      self.openModalContact = function (item) {
        console.log(item);
        Contacts.get({
          contactId: item.contact_id,
        })
          .$promise.then(function (response) {
            ListeContacts.setListeContacts([response]);
            var modalInstance = $uibModal.open({
              windowTemplateUrl: "templates/modal/modal-window-lg.html",
              templateUrl: "contacter-contacts/modal-contacter-contacts.html",
              controller: "ContacterContactsCtrl",
              controllerAs: "$ctrl",
            });
            modalInstance.result.then(
              function (selectedItem) {
                self.selected = selectedItem;
              },
              function () {
                $log.info("Modal dismissed at: " + new Date());
              }
            );
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };

      /*
       * Modal
       */
      self.newAction = function (contactId) {
        var modalInstance = $uibModal.open({
          templateUrl: "crm/action/modal-form-new-action.html",
          controller: "newActionModalCtrl",
          controllerAs: "$ctrl",
          resolve: {
            contactId: function () {
              return contactId;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            self.selected = selectedItem;
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };

      $scope.$watch(
        () => self.paramsFiltresActions,
        debounce(200, function () {
          if (self.paramsDate) {
            self.paramsDate.actions.length = 0;
          }
          page = 1;
          i = 0;
          self.queryActions(self.paramsDate, true);
        }),
        true
      );
    },
  ])
  .controller("ActionsPanelCtrl", [
    "Actions",
    "$state",
    "Contacts",
    "$scope",
    function (Actions, $state, Contacts, $scope) {
      var self = this;
      var formatDate = "YYYY-MM-DD";
      self.nombreResultat = 0;

      self.actionsToday = {
        title: "Actions d'aujourd'hui",
        id: "aujourdhui",
        isCollapsed: false,
        isEmptyMessage: "Aucune action pour aujourd'hui",
        date: {
          date_start: moment().format(formatDate),
          date_end: moment().format(formatDate),
        },
        actions: [],
      };

      self.actionsEnRetard = {
        title: "Actions en retard",
        id: "enretrard",
        isCollapsed: true,
        isEmptyMessage: "Aucune action en retard",
        date: {
          date_start: "",
          date_end: moment().subtract(1, "days").format(formatDate),
        },
        actions: [],
      };

      self.actionsPlusTard = {
        title: "Actions futures",
        id: "futures",
        isCollapsed: true,
        isEmptyMessage: "Aucune action à venir",
        date: {
          date_start: moment().add(1, "days").format(formatDate),
          date_end: "",
        },
        actions: [],
      };

      self.$onInit = function () {
        self.queryActions(self.actionsToday, true);
        self.queryActions(self.actionsEnRetard, true);
        self.queryActions(self.actionsPlusTard, true);
      };

      self.queryActions = function (paramsActions, reset) {
        var params = {
          start_date: paramsActions.date.date_start,
          end_date: paramsActions.date.date_end,
        };
        if (paramsActions.nodate) {
          params.nodate = paramsActions.nodate;
        }
        if (reset) {
          paramsActions.actions.length = 0;
        }
        angular.extend(params, self.params.filtres);
        Actions.query(params)
          .$promise.then(function (response) {
            paramsActions.totalActions = response.total;
            self.nombreResultat += parseInt(response.total);
            angular.forEach(response.data, function (value, key) {
              Contacts.get({
                contactId: value.contact_id,
              })
                .$promise.then(function (resp) {
                  value.contact = resp;
                  paramsActions.actions.push(value);
                })
                .catch(function (err) {
                  console.log("promise error", err);
                });
            });
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };

      self.getIconClass = function (action) {
        var icons = {
          2: "person", // rdv physique
          3: "contact_phone", // rdv tel
          4: "phonelink_ring", // relance tel
          5: "person", // 1er appel
          7: "mail_outline", // envoyer un mail avant le
          9: "message", // compte rendu
        };
        return icons[action.action_id];
      };

      self.getLabelClass = function (action) {
        var label = {
          2: "Rdv", // rdv physique
          3: "Rdv Appel", // rdv tel
          4: "Appel", // relance tel
          5: "1er Appel", // 1er appel
          7: "Mail", // envoyer un mail avant le
          9: "Note", // compte rendu
        };
        return label[action.action_id];
      };

      self.goToContact = function (contact) {
        $state.go("formContact.historique", {
          contactId: contact.contact_id,
        });
      };
    },
  ])
  .filter("formatDate", function () {
    return function (maDate) {
      if (maDate) {
        if (maDate.date) {
          maDate = maDate.date;
        }
        if (maDate instanceof Date) {
          maDate = maDate.toString();
        }
        var match = maDate.match(/([0-9\-]+)T([0-9:]+)/);
        if (match) {
          maDate = match[1] + " " + match[2];
        }
        return moment(maDate).format("DD/MM/YYYY HH:mm");
      }
    };
  });
