/**
 * Module definition and dependencies
 */
angular
  .module("App.MesTransactions", [
    "App.MesTransactions.Controller",
    "Crm.Transactions",
    "Crm.Transaction",
    "Crm.Signature",
    "App.Crm.FiltreTransactions",
    "ngTable",
    "Crm.transactions.templates",
    "mockFiltresTransactions.Service",
  ])

  /**
   * Config
   */
  .config(($stateProvider) => {
    $stateProvider.state("mesTransactions", {
      parent: "app",
      url: "/mes-transactions",
      component: "mesTransactions",
      params: {
        paramsFiltresTransactions: null,
        paramsFiltresTransactionsSignature: null,
      },
      resolve: {
        listStatuts: function (ConfigsTransactions) {
          return ConfigsTransactions.getStatuts().then(
            function successCallback(response) {
              return response.data;
            },
            function errorCallback(response) {
              console.log(
                "Erreur factory mesTransactions listStatuts : " + response
              );
              return false;
            }
          );
        },
      },
      data: {
        auth: true,
      },
    });
  })
  .config(($stateProvider) => {
    $stateProvider.state("mesOptions", {
      parent: "app",
      url: "/mes-options",
      component: "mesOptions",
      resolve: {},
    });
  })
  /**
   * Route component
   */
  .component("mesTransactions", {
    controller: "MesTransactionsCtrl",
    templateUrl: "mes-transactions/mes-transactions.html",
    bindings: {
      listStatuts: "<",
      programme: "<",
    },
  })
  .component("mesTransactionsCards", {
    controller: "MesTransactionsCardsCtrl",
    templateUrl: "mes-transactions/mes-transactions-cards.html",
    bindings: {
      filtresTransactions: "<",
      labelTransactions: "=",
      loading: "=",
    },
  })
  .component("mesOptions", {
    controller: "MesTransactionsCtrl",
    templateUrl: "mes-transactions/mes-options.html",
  })
  .component("transactionsPanel", {
    controller: "TransactionsPanelCtrl",
    templateUrl: "mes-transactions/transactions-panel.html",
    bindings: {
      params: "<",
    },
  })
  .component("transactionTopBar", {
    controller: "TransactionTopBarCtrl",
    templateUrl: "mes-transactions/transaction-top-bar.html",
    bindings: {
      transaction: "<",
    },
  });
