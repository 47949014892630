/**
 * Module definition and dependencies
 */
angular.module('App.MesContactsDenonces.Controller', [
    'denonciationsService.Service',
    'ngMessages',
])

/**
 * Controller
 */
    .controller('MesContactsDenoncesCtrl', ['NgTableParams', 'Denonciations','$scope',
        function (NgTableParams, Denonciations, $scope) {
            var self = this;

            self.$onInit = function () {
                self.filtre = [];
            }
            self.listDenonciations = new NgTableParams({}, {
                getData: function (params) {
                    var query = {
                        'limit': params.count(),
                        'page': params.page(),
                        'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
                        'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
                    }
                    self.limit = params.count();
                    angular.extend(query, params.filter());
                    return Denonciations.query(query).$promise.then(function (data) {
                        self.totalDenonciations = data.total;
                        return data.data;
                    }).catch(function (err) {
                        console.log('promise error', err);
                    });
                },
            });

            $scope.$watch(function () {
                return self.filtre
            }, function () {
                var filter = {
                    statuts_denonciation: self.filtre.join(','),
                };
                if(filter && self.filtre.length == 0){
                    statuts_denonciation: [1,2,3,4].join(',');
                }
                angular.extend(self.listDenonciations.filter(), filter);
            }, true);
        }]
    );
