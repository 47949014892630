angular
  .module('App.FiltreSearch.Controller', [])
  .controller('FiltreSearchCtrl', [
    '$scope',
    'debounce',
    function($scope, debounce) {
      const minLengthSearch = 2;
      const debounceTime = 600;

      $scope.$watch(
        () => this.search,
        debounce(debounceTime, (value) => {
          if (!this.filtres) {
            this.filtres = {};
          }

          if ((!value || value.length === 0) && this.filtres.search) {
            delete this.filtres.search;
            this.filtres.force = new Date().getTime();
          }
          else if (value && value.length >= minLengthSearch) {
            this.filtres.search = this.search;
            delete this.filtres.force;
          }
        }),
        true
      );
    },
  ]);
