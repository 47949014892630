/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreContacts.Controller', [
  'statsService.Service',
  'contactsService.Service',
  'angularPromiseButtons',
  'selectionModel',
]).controller('TableFiltreContactsCtrl', [
  'StatsContacts', '$scope', 'debounce',
  function(StatsContacts, $scope, debounce) {
    var self = this;
    self.$onInit = function() {
      self.disableCheck = false;
      self.filtreContacts = {};
      self.filtreContacts.groupe_avancement = [];
      self.filtreContacts.avancement_commercial = [];
      if (self.filtresContacts.is_contact) {
        self.filtreContacts.is_contact = self.filtresContacts.is_contact === '1' ? true : false;
      }
      if (self.filtresContacts.is_client) {
        self.filtreContacts.is_client = self.filtresContacts.is_client === '1' ? true : false;
      }
      if (self.filtresContacts.groupe_avancement) {
        self.filtreContacts.groupe_avancement.push(self.filtresContacts.groupe_avancement);
      }
      if (self.filtresContacts.avancement_commercial) {
        self.filtreContacts.avancement_commercial = self.filtresContacts.avancement_commercial.split(',').map(item => parseInt(item));
      }
    };

    self.contactDenonces = function() {
      if (self.checkboxExclu === true) {
        self.filtreContacts.denonciation_statut_id = 2;
      } else {
        self.filtreContacts.denonciation_statut_id = null;
      }
    };

    self.getPercent = function(val) {
      if (self.totalStatsContacts === 0) {
        return 0;
      }
      return Math.round(val / self.totalStatsContacts * 100);
    };

    self.modifierSelection = function(item) {
      var index = self.filtreContacts.groupe_avancement.indexOf(item.id);
      if (index > -1) {
        self.filtreContacts.groupe_avancement.splice(index, 1);

        angular.forEach(item.avancements, function(value, key) {
          var indexAvancement = self.filtreContacts.avancement_commercial.indexOf(value.id);
          if(indexAvancement > -1) {
            self.filtreContacts.avancement_commercial.splice(indexAvancement, 1);
          }
        });

      } else {
        self.filtreContacts.groupe_avancement.push(item.id);

        if(item.avancements) {
          angular.forEach(item.avancements, function(value, key) {
            if(self.filtreContacts.avancement_commercial.indexOf(value.id) == -1) {
              self.filtreContacts.avancement_commercial.push(value.id);
            }
          });
        }

      }
    };

    self.modifierAvancementCommercial = function(item, i) {
      var index = self.filtreContacts.avancement_commercial.indexOf(i.id);
      if (index > -1) {
        self.filtreContacts.avancement_commercial.splice(index, 1);

        var indexGroupeAvancement = self.filtreContacts.groupe_avancement.indexOf(item.id);
        if(indexGroupeAvancement > -1) {
          self.filtreContacts.groupe_avancement.splice(indexGroupeAvancement, 1);
        }

      } else {
        self.filtreContacts.avancement_commercial.push(i.id);

        if(item.avancements) {
          var checkParentGroupeAvancement = true;
          angular.forEach(item.avancements, function(value, key){
            if(self.filtreContacts.avancement_commercial.indexOf(value.id) == -1) {
              checkParentGroupeAvancement = false;
            }
          })
          if(checkParentGroupeAvancement) {
            self.filtreContacts.groupe_avancement.push(item.id);
          }
        }

      }
    };

    self.checkAvancementCommercial = function(item, i) {
      if(self.filtreContacts.avancement_commercial.indexOf(i.id) > -1) {
        item.expanded = true;
        if(item.userClose == undefined) {
          item.userClose = true;
        }
        return true;
      }
      return false;
    }

    self.expandMore = function(item) {
      item.expanded = !item.expanded;
      item.userClose = !item.userClose;
    }

    self.boolToInt = function(query) {
      if (query.is_client !== undefined) {
        query.is_client = query.is_client === true ? '1' : '0';
      }
      if (query.is_contact !== undefined) {
        query.is_contact = query.is_contact === true ? '1' : '0';
      }
      if (query.is_client === '0' && query.is_contact === '0') {
        query.is_client = '1';
        query.is_contact = '1';
      }
    };

    self.getStatsContacts = function(query) {
      StatsContacts.getStatsContacts(query).then(function(response) {
        self.totalStatsContacts = response.data.total;
        self.statsContacts = response.data.data;
      }, function errorCallback(response) {
        console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
        return false;
      });
    };

    self.formateFiltreContacts = function(val) {
      var query = {};
      angular.extend(query, val);
      self.boolToInt(query);
      angular.forEach(query, function(value, key) {
        if (Array.isArray(value)) {
          query[key] = value.join(',').toString();
        }
      });
      query.search = true;
      return query;
    };

    self.actualiserStats = function(val) {
      var query = self.formateFiltreContacts(val);
      angular.extend(self.filtresContacts, query);
      var queryStats = angular.copy(self.filtresContacts);
      if (queryStats.groupe_avancement) {
        delete queryStats.groupe_avancement;
      }
      if (queryStats.avancement_commercial) {
        delete queryStats.avancement_commercial;
      }
      queryStats.avancements = 1;
      self.getStatsContacts(queryStats);
    };

    $scope.$on('updateContacts', function(event, data) {
      self.actualiserStats(self.filtreContacts);
    });

    $scope.$watch(() => this.filtreContacts, debounce(200, function() {
      self.actualiserStats(self.filtreContacts);
    }), true);
  },
]);
