/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsSourcesContacts', [
  'App.Crm.StatsSourcesContacts.Controller',
])
.component('statsSourcesContacts', {
    controller: 'StatsSourcesContactsCtrl',
    templateUrl: 'crm/stats-sources-contacts/stats-sources-contacts.html',
});
