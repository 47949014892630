/**
 * Module definition and dependencies
 */
angular.module('App.MesActions', [
  'App.MesActions.Controller',
  'App.Crm.FiltreActions',
]).config($stateProvider => {
  $stateProvider.state('mesActions', {
    parent: 'app',
    url: '/mes-actions',
    component: 'mesActionsRoute',
    params: {
      paramsFiltresActions: null,
      actionEnCours  : null,
    },
    data: {
      auth: true,
    },
  });
})

/**
 * Route component
 */.component('mesActionsRoute', {
  controller: 'MesActionsCtrl',
  templateUrl: 'mes-actions/mes-actions.html',
  bindings: {
    contactId: '<',
  },
}).component('mesActionsList', {
  controller: 'MesActionsListCtrl',
  templateUrl: 'mes-actions/mes-actions-list.html',
  bindings: {
    paramsFiltresActions: '<',
    actionEnCours : '<',
    paramsDate: '=',
    contactId: '<',
    loading : '=',
    actualiserStats: '=',
    arrayInitDate : '=',
    arrayInitDone : '=',
  },
}).component('actionsPanel', {
  controller: 'ActionsPanelCtrl',
  templateUrl: 'mes-actions/actions-panel.html',
  bindings: {
    params: '<',
  },
});
