/**
 * Module definition and dependencies
 */
angular
  .module("App.Crm.StatsBusiness.Controller", ["statsService.Service"])
  .controller("ListeStatsBusinessCtrl", [
    "$state",
    "$scope",
    "StatsContactTransactions",
    "TransactionStatutService",
    function (
      $state,
      $scope,
      StatsContactTransactions,
      TransactionStatutService
    ) {
      var self = this;

      self.$onInit = function () {
        getStatsTransactions().then(function (response) {
          self.totalStatsTransactions = response.data.total;
          self.statsTransactions = response.data.data;
        });
      };

      self.lienTransactionStatut = function (id) {
        $state.go("mesContacts", {
          filtres: {
            transaction_types: id,
            transaction_etat: 4,
            start_date: moment(self.startDate).format("YYYY-MM-DD"),
            end_date: moment(self.endDate).format("YYYY-MM-DD"),
          },
        });
      };

      self.getPercent = function (val) {
        if (self.totalStatsTransactions === 0) {
          return 0;
        }
        const result = Math.round((val / self.totalStatsTransactions) * 100);
        return result;
      };

      function getStatsTransactions(params) {
        var params = {
          etat: [4].join(","),
        };

        if (self.startDate instanceof Date) {
          params.start_date = moment(self.startDate).format("YYYY-MM-DD");
        }

        if (self.endDate instanceof Date) {
          params.end_date = moment(self.endDate).format("YYYY-MM-DD");
        }

        return StatsContactTransactions.getStatsContactTransactions(
          params
        ).then(
          function successCallback(response) {
            return response;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsTransactions getStatsTransactions : " +
                response
            );
            return false;
          }
        );
      }

      $scope.$watch(
        () => this.startDate,
        (val, old) => {
          getStatsTransactions().then(function (response) {
            self.totalStatsTransactions = response.data.total;
            self.statsTransactions = response.data;
          });
        },
        true
      );

      $scope.$watch(
        () => this.endDate,
        (val, old) => {
          getStatsTransactions().then(function (response) {
            self.totalStatsTransactions = response.data.total;
            self.statsTransactions = response.data;
          });
        },
        true
      );
    },
  ]);
