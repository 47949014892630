angular.module('actualitesService.Service', [
    'ngResource',
])

/**
 * Service
 */.factory('ActualitesProgramme', [
    '$resource', 'Config', function($resource, Config) {
        var actionsApi = $resource(Config.API_BASE_URL + '/programmes/actualites/:id', {
            id: '@id'
        }, {
            update: {
                method: 'PUT',
            },
            query: {
                isArray: false,
                transformResponse: function(data) {
                    var result = angular.fromJson(data);
                    result.data = result.data.map(a => new actionsApi(a));
                    return result;
                },
            },
        });
        actionsApi.prototype.createOrUpdate = function() {
            if (this.id) {
                return this.$update();
            } else {
                return this.$save();
            }
        };
        return actionsApi;
    }
])
.factory('ActualitesProgrammeMedias', [
    '$resource', 'Config', function($resource, Config) {
        var actionsApi = $resource(Config.API_BASE_URL + '/programmes/actualites/medias/:media_id', {
            media_id: '@id'
        }, {
            update: {
                method: 'PUT',
            },
            query: {
                isArray: true,
            },
        });
        actionsApi.prototype.createOrUpdate = function() {
            if (this.id) {
                return this.$update();
            } else {
                return this.$save();
            }
        };
        return actionsApi;
    }
]);