/**
 * Module definition and dependencies
 */
angular.module('App.Crm.ContactsDenonces.Controller', [
    'contactsService.Service',

])

  .controller('TableContactsDenoncesCtrl',['ContactFicheConsultees', function (ContactFicheConsultees) {
    this.config = {
      titreColonneContacts: {
        dateDuContact: "contact",
        nom: "email contact",
        email: "téléphone contact",
        tel: "prériode exclusivité",
        ville: "statut",
        avancementCommercial: "Demandeur ",
        besoin: "Partenaire",
        type: "Vendeur Email",
        villeSouhaitee: "Vendeur Téléphone",
        programme: "statut de la demande"
      }
    };

  }]);
