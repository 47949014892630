/**
 * Module definition and dependencies
 */
angular
  .module('Crm.FormContact')

  /**
   * Config
   */

  .config($stateProvider => {
    $stateProvider
      .state('formContact', {
        parent: 'app',
        abstract: true,
        url: '/contact/:contactId',
        params: {
          paramsNavigation: null,
        },
        component: 'formContact',
        resolve: {
          contact: function(Contacts, $stateParams, toaster, $state) {
            if (
              $stateParams.contactId &&
              parseInt($stateParams.contactId) !== 0
            ) {
              return Contacts.get({
                contactId: $stateParams.contactId,
              }).$promise.then(
                function successCallback(response) {
                  return response;
                },
                function errorCallback(error) {
                  toaster.pop({
                    type: 'error',
                    title: '',
                    body:
                      'Ce contact ne vous est pas attribué, vous ne pouvez accéder à sa fiche',
                  });
                  $state.go('mesContacts');
                  $event.preventDefault();
                  $event.stopPropagation();
                  console.log('Erreur factory Contacts get : ' + error);
                  return false;
                }
              );
            }
            return new Contacts();
          },
          listOrigines: function(ConfigsContacts) {
            return ConfigsContacts.getOrigines().then(
              function successCallback(response) {
                return response.data;
              },
              function errorCallback(response) {
                console.log(
                  'Erreur factory ContactsInfos getOrigines : ' + response
                );
                return false;
              }
            );
          },
          listSources: function(ConfigsContacts) {
            return ConfigsContacts.getSources().then(
              function successCallback(response) {
                return response.data;
              },
              function errorCallback(response) {
                console.log(
                  'Erreur factory ContactsInfos getSources: ' + response
                );
                return false;
              }
            );
          },
          listAvancements: function(ConfigsContacts) {
            return ConfigsContacts.getAvancements().then(
              function successCallback(response) {
                return response.data;
              },
              function errorCallback(response) {
                console.log(
                  'Erreur factory ConfigsContacts getAvancements: ' + response
                );
                return false;
              }
            );
          },
          listGroupesAvancements: function(ConfigsContacts) {
            return ConfigsContacts.getGroupesAvancements().then(
              function successCallback(response) {
                return response.data;
              },
              function errorCallback(response) {
                console.log(
                  'Erreur factory ConfigsContacts getGroupeAvancements: ' +
                  response
                );
                return false;
              }
            );
          },
        },
        data: {
          auth: true,
          isActive: 'contacts',
        },
      })
      .state('formContact.note', {
        url: '/note',
        templateUrl: 'src/form-contact/tabs/note.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.coacquereurs', {
        url: '/co-acquereurs',
        templateUrl: 'src/form-contact/tabs/co-acquereurs.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.historique', {
        url: '/historique',
        templateUrl: 'src/form-contact/tabs/historique.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.transactions', {
        url: '/transactions',
        templateUrl: 'src/form-contact/tabs/transactions.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.documents', {
        url: '/documents',
        templateUrl: 'src/form-contact/tabs/documents.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.infoscomplementaires', {
        url: '/infos-complementaires',
        templateUrl: 'src/form-contact/tabs/infos-complementaires.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.panier', {
        url: '/panier',
        templateUrl: 'src/form-contact/tabs/panier.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      })
      .state('formContact.denonciations', {
        url: '/denonciations',
        templateUrl: 'src/form-contact/tabs/denonciations.html',
        controller: 'FormContactContactCtrl',
        controllerAs: 'contactCtrl',
      });
  })
