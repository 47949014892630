angular.module('notificationsService.Service', [
    'ngResource',
])

/**
 * Service
 */.factory('NotificationsMessages', [
    '$resource', 'Config', function($resource, Config) {
        var api = $resource(Config.API_NOTIFICATIONS + '/messages/:id', {
            id: '@id'
        }, {
            update: {
                method: 'PUT',
            },
        });
        api.prototype.createOrUpdate = function() {
            if (this.id) {
                return this.$update();
            } else {
                return this.$save();
            }
        };
        return api;
    }
]);