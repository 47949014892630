/**
 * Module definition and dependencies
 */
angular.module('App.Recherche', [
    'App.Recherche.Controller',
    'ngTable',
])

/**
 * Config
 */.config($stateProvider => {
    $stateProvider.state('recherche', {
        parent: 'app',
        url: '/recherche',
        component: 'rechercheLotsList',
        params: {
            paramsRecherche: null,
        },
        data: {
            auth: true,
        },
    });
}).config($stateProvider => {
    $stateProvider.state('rechercheBiens', {
        parent: 'app',
        url: '/recherche/{id: int}',
        component: 'rechercheBiens',
        data: {
            auth: true,
        },
    });
}).config($stateProvider => {
    $stateProvider.state('rechercheDernieresOpportunites', {
        parent: 'app',
        url: '/recherche-dernieres-opportunites',
        component: 'rechercheLotsList',
        params: {
            paramsRecherche: {search: true, etat_avancement: 'Dernières opportunités'},
            page: {pageTitle: 'Dernières opportunités'},
        },
        data: {
            auth: true,
        },
    });
}).config($stateProvider => {
    $stateProvider.state('rechercheLivraisonsImminentes', {
        parent: 'app',
        url: '/recherche-livraisons-imminentes',
        component: 'rechercheLotsList',
        params: {
            paramsRecherche: {search: true, etat_avancement: 'Livraison imminente'},
            page: {pageTitle: 'Livraisons imminentes'},
        },
        data: {
            auth: true,
        },
    });
})

/**
 * Route component
 */.component('formRechercheLots', {
    controller: 'FormRechercheLotsCtrl',
    templateUrl: 'recherche/newFormRechercheLot.html',
    bindings: {
        filtresLots: '=',
        filtreLots: '<',
        initFiltreLots: '<',
        btnShow: '<',
        initialized: '=',
    },
}).component('formRechercheLotsDetail', {
    controller: 'FormRechercheLotsCtrl',
    templateUrl: 'recherche/newFormRechercheLotDetail.html',
    bindings: {
        filtresLots: '=',
        filtreLots: '<',
        initFiltreLots: '<',
        btnShow: '<',
        initialized: '=',
    },
}).component('formRechercheProgrammeLots', {
    controller: 'FormRechercheLotsCtrl',
    templateUrl: 'recherche/formRechercheProgrammeLots.html',
    bindings: {
        filtresLots: '=',
        filtreLots: '<',
        initFiltreLots: '<',
        initialized: '=',
    },
}).component('rechercheLotsList', {
    controller: 'RechercheLotsListTableCtrl',
    templateUrl: 'recherche/rechercheLotsListTable.html',
    bindings: {
        filtresLots: '<',
        filtreLots: '<',
        paramsRecherche: '<',
        showTitle: '<',
        showColProgramme: '<',
        programmePage: '<',
        btnShow: '<',
        contact: '<',
        panierContact: '=',
    },
}).component('rechercheMinForm', {
    controller: 'RechercheMinFormCtrl',
    templateUrl: 'recherche/rechercheMinForm.html',
    bindings: {
        filtresLots: '<',
        filtreLots: '<',
        showColProgramme: '<',
        initFiltreLots: '<',
        programmeId: '<',
    },
}).component('rechercheBiens', {
    controller: 'RechercheBiensCtrl',
    templateUrl: 'recherche/rechercheBiens.html',
}).component('formRechercheBiens', {
    controller: 'FormRechercheLotsCtrl',
    templateUrl: 'recherche/formBiens.html',
    bindings: {
        filtresLots: '=',
        filtreLots: '<',
    },
});
