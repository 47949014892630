/**
 * Module definition and dependencies
 */
angular
  .module('App.EspaceDocuments', ['App.EspaceDocuments.Controller'])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider.state('espaceDocuments', {
      parent: 'app',
      url: '/dashboard/espace-documents',
      component: 'espaceDocumentsRoute',
      resolve: {
        listProgrammes: function(Programmes) {
          return Programmes.query({
            sort: 'asc',
            pagination: 0,
          }).$promise.then(
            function successCallback(response) {
              return response;
            },
            function errorCallback(response) {
              console.log(
                'Erreur factory Programmes get programmes ' + response
              );
              return false;
            }
          );
        },
        listTypesDocuments: function(DocumentsCategories) {
          return DocumentsCategories.query({
            sort: 'asc',
            pagination: 0,
          }).$promise.then(
            function successCallback(response) {
              return response;
            },
            function errorCallback(response) {
              console.log(
                'Erreur factory DocumentsCategories get categories ' + response
              );
              return false;
            }
          );
        },
      },
      data: {
        auth: true,
      },
    });
  })
  /**
   * Route component
   */
  .component('espaceDocumentsRoute', {
    controller: 'EspaceDocumentsCtrl',
    templateUrl: 'espace-documents/espace-documents.html',
    bindings: {
      listProgrammes: '<',
      listTypesDocuments: '<',
    },
  });
