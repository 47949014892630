/**
 * Module definition and dependencies
 */
angular.module('App.Calendrier', [
    'App.Calendrier.Controller',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('calendrier', {
            parent: 'app',
            url: '/calendrier',
            component: 'calendrier',
            data: {
                auth: true,
            },
        });
    })
    .component('calendrier', {
        controller: 'CalendrierCtrl',
        templateUrl: 'calendrier/calendrier.html',
    });
