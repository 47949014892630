/**
 * Module definition and dependencies
 */
angular.module('App.Admin.SelectVendeur.Controller', [])

/**
 * Controller
 */
.controller('SelectVendeurCtrl', ['ConfigsVendeurs', 'ConfigService', '$state', '$rootScope', 'debounce', '$scope',
    function(ConfigsVendeurs, ConfigService, $state, $rootScope, debounce, $scope) {
        var self = this;
        self.listVendeurs = [];
        self.labelMultiSelect = {
            'itemsSelected': 'éléments sélectionnés',
            'selectAll': 'Tout sélectionner',
            'unselectAll': 'Tout déselectionner',
            'search': 'Chercher',
        };
        self.$onInit = function() {
            if(!self.titleNonAttribue) {
                self.titleNonAttribue = 'Non attribué';
            }

            if(!self.noSave) {
                //Sélectionne par défault le membreConnecter pour une nouvelle action par exemple
                if(self.loadMembreConnecter && $rootScope.membreConnecter && $rootScope.membreConnecter.id) {
                    self.vendeurSelected = $rootScope.membreConnecter.id;
                }

                //Récupération du vendeur sélectionné pour les stats par exemple
                if(!self.loadMembreConnecter) {
                    var paramsConfigService = ConfigService.getParams();
                    if(paramsConfigService && paramsConfigService.vendeur_id !== undefined) {
                        var vendeurs = paramsConfigService.vendeur_id;
                        if(self.isSelect && typeof(vendeurs) == 'string') {
                            vendeurs = paramsConfigService.vendeur_id.split(',');
                            self.vendeurSelected = parseInt(vendeurs[0]);
                            ConfigService.setParams({vendeur_id: self.vendeurSelected});
                        }
                        else {
                            self.vendeurSelected = vendeurs;
                        }
                    }
                }
            }

            if(!self.titleSelect && self.titleSelect !== false) {
                self.titleSelect = 'Tous les vendeurs';
            }

            ConfigsVendeurs.getVendeurs().then(function(response) {
                angular.forEach(response.data, function(item, key){
                    if(item.nom) {
                        item.nom = item.nom.toUpperCase();
                    }
                    if(item.nom && item.prenom) {
                        item.label = item.nom + ' ' + item.prenom;
                    }
                });
                self.listVendeurs = response.data;
                if(self.showNonAttribue) {
                    self.listVendeurs.splice(0, 0, {id:0, nom: self.titleNonAttribue, prenom: '', label: self.titleNonAttribue});
                }
            }, function errorCallback(response) {
                console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
                return false;
            });
        };

        $scope.$watch(() => self.vendeurSelected, debounce(800, function(val, old) {
            if(val !== undefined && val !== old && !self.noSave) {
                ConfigService.setParams({vendeur_id: self.vendeurSelected});
                if(self.reload !== false) {
                    $state.reload();
                }
            }
		}), true);
    },
]);