/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreActions', [
    'App.Crm.FiltreActions.Controller',
])
    .component('tableFiltreActions', {
        controller: 'TableFiltreActionsCtrl',
        templateUrl: 'crm/filtre-actions/table-filtre-actions.html',
        bindings: {
            loading: '=',
            paramsFiltresActions: '=',
            actualiserStats: '=',
        }
    });