angular.module('ResetPassword.Service', [])
  .factory('ResetPassword', ['Config', '$http', function (Config, $http) {
    return {
      retrieveTokenInformation: function (token) {
        return $http.get(Config.API_BASE_URL_PUBLIC + '/auth/password-reset?code=' + token, {}).then(res => res.data)
      },
      resetPassword: function (userId, token, password) {
        return $http.post(Config.API_BASE_URL_PUBLIC + '/auth/password-reset', {
          'user_id': userId,
          'token': token,
          'password': password,
        });
      }
    }
  }]);
