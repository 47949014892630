/**
 * Module definition and dependencies
 */
angular
  .module("App.Crm.FiltreTransactions", [
    "App.Crm.FiltreTransactions.Controller"
  ])
  .component("tableFiltreTransactions", {
    controller: "TableFiltreTransactionsCtrl",
    templateUrl: "crm/filtre-transactions/table-filtre-transactions.html",
    bindings: {
      programme: "<",
      loading: "<",
      filtresTransactions: "=",
      filtresTransactionsSignature: "=",
      filtreSelected: "="
    }
  });
