/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FichesContactsConsultees', [
    'App.Crm.FichesContactsConsultees.Controller',
])
    .config($stateProvider => {
        $stateProvider.state('mesFichesConsultees', {
            parent: 'app',
            url: '/table-fiches-contacts-consultees',
            component: 'tableFichesContactsConsultees',
            data: {
                auth: true,
            },
        });
    })
    .component('tableFichesContactsConsultees', {
        controller: 'TableFichesContactsConsulteesCtrl',
        templateUrl: 'crm/fiches-contacts-consultees/table-fiches-contacts-consultees.html',
    });
