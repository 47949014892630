/**
 * Module definition and dependencies
 */
angular.module('App.DenoncerContact.Controller', [
  'ngMessages',
  'configsService.Service',
  'programmesService.Service',
  'contactsService.Service',
])

  /**
   * Controller
   */
  .controller('DenoncerContactModalCtrl', ['$state', '$uibModalInstance', 'DenoncerContact', 'contact', 'ConfigsDenonciations', 'moment', 'toaster', 'ConfigsProgrammes', '$rootScope', 'PromoteursContactsCheck',
    function($state, $uibModalInstance, DenoncerContact, contact, ConfigsDenonciations, moment, toaster, ConfigsProgrammes, $rootScope, PromoteursContactsCheck) {
      let $ctrl = this;

      $ctrl.$onInit = function() {
        $ctrl.contact = contact;
        $ctrl.dateDebut = new Date();
        $ctrl.denonciationProgrammeActive = false;
        $ctrl.showMessage = null;

        /**
         * Pour les partenaires on doit check si la personne n'est pas déjà presente en BDD
         * il faut : le nom, prénom avec le tel ou l'email
         * Si on trouve le contact chez le promoteur on affiche un message comme quoi la personne est deja présente dans la base promoteur.
         * Sinon on affiche la modal de denonciation
         */
        if ($rootScope.membreConnecter.is_partenaire && $ctrl.contact.nom && $ctrl.contact.prenom && ($ctrl.contact.tel || $ctrl.contact.email)) {

          const contactQuery = {
            commercialisateurId: $rootScope.membreConnecter.rattachement_id,
            nom: $ctrl.contact.nom,
            prenom: $ctrl.contact.prenom,
            tel: $ctrl.contact.tel,
            email: $ctrl.contact.email,
          };

          PromoteursContactsCheck.query(contactQuery).$promise.then(response => {
              if (response.isFind) {
                $ctrl.showMessage = true;
                $ctrl.contact.commercialisateur_nom = response.commercialisateur_nom;
                return;
              }
              $ctrl.showMessage = false;
              $ctrl.getConfigsDenonciations();
            })
            .catch(error => {
              console.log('error', error);
            });
        } else {
          $ctrl.showMessage = false;
          $ctrl.getConfigsDenonciations();
        }
      };

      $ctrl.getConfigsDenonciations = function() {
        ConfigsDenonciations.getInfosDenonciations()
          .then(function(response) {
            $ctrl.duree_denonciation = response.data.denonciation_duree;
            $ctrl.getLastDate();
            if (response.data && response.data.denonciation_programme_active) {
              $ctrl.denonciationProgrammeActive = true;
              $ctrl.getProgrammes();
            }
          })
          .catch(error => {
            console.log('error', error);
          });
      };

      $ctrl.getProgrammes = function() {
        ConfigsProgrammes.getProgrammes()
          .then(function successCallback(response) {
            $ctrl.listProgrammes = response.data.data;
            if ($ctrl.listProgrammes[0] && $ctrl.listProgrammes[0].id) {
              $ctrl.denoncer_programme = String($ctrl.listProgrammes[0].id);
            }
          }, function errorCallback(response) {
            console.log('Erreur factory Programmes get programmes ' + response);
            return false;
          });
      };

      $ctrl.datePickerOptions = {
        dateDisabled: disabled,
        formatYear: 'yy',
        startingDay: 1,
      };

      function disabled(data) {
        let date = data.date,
          mode = data.mode;
        return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
      }

      $ctrl.getLastDate = function() {
        if ($ctrl.dateDebut) {
          $ctrl.lastDate = moment($ctrl.dateDebut)
            .add($ctrl.duree_denonciation, 'days')
            .format('DD/MM/YYYY');
        }
      };
      $ctrl.valider = function(idContact) {
        let query = {};
        query.contactId = idContact;
        query.startDate = moment($ctrl.dateDebut)
          .format('YYYY-MM-DD');
        if ($ctrl.denonciationProgrammeActive) {
          query.programme_id = $ctrl.denoncer_programme;
        }
        DenoncerContact.save(query)
          .$promise
          .then(function successCallback(response) {
            if (response.success === true) {
              $rootScope.$broadcast('updateListeDenonciations');
              toaster.pop({
                type: 'success',
                title: '',
                body: response.message,
              });
              $uibModalInstance.close();
            }
            else {
              toaster.pop({
                type: 'error',
                title: '',
                body: 'Erreur : ' + response.message,
              });
            }
          }, function errorCallback(response) {
            $uibModalInstance.close();
            toaster.pop({
              type: 'error',
              title: '',
              body: 'Erreur : ' + response.message,
            });
          });
      };
      $ctrl.annuler = function() {
        $uibModalInstance.dismiss('cancel');
      };
    }],
  )
  .controller('DenonciationsCtrl', ['Denonciations', '$scope', 'NgTableParams',
    function(Denonciations, $scope, NgTableParams) {
      let self = this;

      self.$onInit = function() {

        self.tableListeDenonciations = new NgTableParams({}, {
          getData: function(params) {
            return Denonciations.query({
              'limit': params.count(),
              'page': params.page(),
              'order': params.sorting() ? params.sorting()[Object
                .keys(params.sorting())[0]] : '',
              'sort': params.sorting() ? Object.keys(params.sorting())[
                0] : '',
              'contact_id': self.contact.id,
            })
              .$promise
              .then(function(response) {
                params.total(response.total);
                self.totalDenonciations = response.total;
                return response.data;
              })
              .catch(function(err) {
                console.log('Promise error', err);
              });
          },
        });

      };

      $scope.$on('updateListeDenonciations', function(event, data) {
        self.tableListeDenonciations.reload();
      });

    },
  ]);
