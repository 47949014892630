/**
 * Module definition and dependencies
 */
angular
  .module("App.IframeComponent.Controller", ["ngMessages"])

  /**
   * Controller
   */
  .controller("IframeComponentCtrl", [
    "$sce",
    "$state",
    "Config",
    "$rootScope",
    function ($sce, $state, Config, $rootScope) {
      var self = this;
      self.$onInit = function () {
        if (!self.width) {
          self.width = "100%";
        }
        if (!self.height) {
          self.height = "650";
        }
        if (self.programme) {
          self.urlPageLot = encodeURIComponent(
            $state.href("lot", { id: "" }, { absolute: false })
          );
          self.urlPageLot =
            window.location.origin + window.location.pathname + self.urlPageLot;
          self.item = self.item
            ? self.item
            : encodeURIComponent(
                Config.API_BASE_URL_PUBLIC +
                  "/configs/lots/urls?programme_id=" +
                  self.programme +
                  "&url_page_lot=" +
                  self.urlPageLot
              );
        }
        if (self.lot) {
          if (
            (self.lot.statut == 1 || self.lot.statut == 2) &&
            $rootScope.membreConnecter.use_options
          ) {
            self.item =
              self.item +
              "&urlOption=" +
              encodeURIComponent(
                Config.APP_BASE_URL +
                  Config.APP_BASE +
                  "#!/optionner/" +
                  self.lot.id
              );
            if (self.lot.option_rang) {
              self.item = self.item + "&rangOption=" + self.lot.option_rang;
            }
          }
        }
      };

      self.trustSrc = function (src) {
        return $sce.trustAsResourceUrl(src);
      };
    },
  ]);
