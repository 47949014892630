/**
 * Module definition and dependencies
 */
angular
  .module("App.Crm.RechercheContacts.Controller", [
    "ngMessages",
    "contactsService.Service",
  ])

  /**
   * Controller
   */
  .controller("RechecheContactsCtrl", [
    "Contacts",
    "$state",
    "ContactFicheConsultees",
    function (Contacts, $state, ContactFicheConsultees) {
      let self = this;
      self.contactsSelectionner = [];
      self.getContacts = function (search) {
        if (search === undefined || search.length === 0) {
          return ContactFicheConsultees.getContactFicheConsultees()
            .then(function (response) {
              angular.forEach(response.data, function (value, key) {
                value.label = value.nom + " " + value.prenom;
              });
              return response.data;
            })
            .catch(function (err) {
              console.log("promise error", err);
            });
        } else {
          return Contacts.query({
            q: search,
            limit: 25,
          }).$promise.then(function (response) {
            angular.forEach(response.data, function (value, key) {
              value.label = value.nom + " " + value.prenom;
            });
            return response.data;
          });
        }
      };
      self.focusContact = function ($model) {
        self.contact = "";
        $state.go("formContact.historique", {
          contactId: $model.id,
        });
      };
      // self.listeContactsSelectionner = function () {
      //   if(self.contactsSelectionner){
      //     ListeContacts.setListeContacts(self.contactsSelectionner);
      //     $state.go('contacterContacts');
      //   }
      // };
      self.format = function (contact) {
        return contact.nom;
      };
    },
  ]);
