/**
 * Module definition and dependencies
 */

function config(NotificationRTProvider, Config) {
  if (!Config.API_NOTIFICATIONS) {
    throw new console.error(
      'Aucune configuration pour API_NOTIFICATIONS dans le fichier de config'
    );
  }
  NotificationRTProvider.setEndPoint(Config.API_NOTIFICATIONS + '/keys');
}
config.$inject = ['NotificationRTProvider', 'Config'];

angular
  .module('App.Notifications', [
    'App.Notifications.Controller',
    'Common.Notification',
  ])

  .config(config)
  .component('dropdownNotifications', {
    controller: 'dropdownNotificationsCtrl',
    templateUrl: 'notifications/dropdown-notifications.html',
    bindings: {
      contact: '<',
    },
  });
