/**
 * Module definition and dependencies
 */
angular.module('App.Lot', [
    'App.Lot.Controller',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('lot', {
            parent: 'app',
            url: '/lot/:id',
            params: {
                params: null,
            },
            component: 'lotRoute',
            resolve: {
                lot: function (Lot, $stateParams) {
                    return Lot.get({
                        lotId: $stateParams.id,
                        lots_annexes: 1
                    }).$promise;
                },
                lotDetails: function (LotDetails, $stateParams) {
                    return LotDetails.get({
                        lotId: $stateParams.id
                    }).$promise;
                },
                programme: function (Programme, lot) {
                    return Programme.get({
                        programmeId: lot.programme_id,
                    }).$promise;
                },
            },
            data: {
                auth: true,
                isActive: 'programmes',
            },
        });
    })
    /**
     * Route component
     */
    .component('lotRoute', {
        controller: 'LotCtrl',
        templateUrl: 'lot/lot.html',
        bindings: {
            lot: '<',
            lotDetails: '<',
            programme: '<',
        },
    });
