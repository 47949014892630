/**
 * Module definition and dependencies
 */
angular.module('App.Crm.TransactionsContact', [
  'App.TransactionsContact.Controller',
])
  .component('transactionsContact', {
    controller: 'TransactionsContactCtrl',
    templateUrl: 'crm/transactions-contact/transactions-contact.html',
    bindings: {
      contact: '<',
    },
  });
