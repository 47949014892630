/**
 * Module definition and dependencies
 */
angular.module('messageService.Service', [
  'ngResource',
])

/**
 * Service
 */
  .factory('Message', ['$resource', 'Config', function ($resource, Config) {
    var api = $resource(Config.API_BASE_URL + '/messages/', {}, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    return api;
  }]);
