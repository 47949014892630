/**
 * Module definition and dependencies
 */
angular
  .module("App.ListLotTransactions.Controller", [
    "transactionsService.Service",
    "ngMessages"
  ])

  /**
   * Controller
   */
  .controller("ListLotTransactionsCtrl", [
    "NgTableParams",
    "TransactionsLot",
    function(NgTableParams, TransactionsLot) {
      let self = this;
      self.$onInit = function() {
        self.listTransactions = new NgTableParams(
          {},
          {
            getData: function(params) {
              let query = {
                limit: params.count(),
                page: params.page(),
                order: params.sorting()
                  ? params.sorting()[Object.keys(params.sorting())[0]]
                  : "",
                sort: params.sorting() ? Object.keys(params.sorting())[0] : ""
              };
              angular.extend(query, params.filter());
              return TransactionsLot.query({
                lotId: self.lot.id
              })
                .$promise.then(function(data) {
                  params.total(data.total);
                  return data.data;
                })
                .catch(function(err) {
                  console.log("promise error", err);
                });
            }
          }
        );
      };
    }
  ]);
