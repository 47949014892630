/**
 * Module definition and dependencies
 */
angular.module('App.Optionner', [
    'App.Optionner.Controller',
    'lotsService.Service',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('optionner', {
            parent: 'app',
            url: '/optionner/{lotId:int}',
            component: 'optionnerRoute',
            resolve: {
                lot: function (Lot, $stateParams) {
                    return Lot.get({
                        lotId: $stateParams.lotId,
                        lots_annexes: 1
                    }).$promise
                },
                listLotPrix: function (LotPrix, $stateParams) {
                    return LotPrix.get({
                        lotId: $stateParams.lotId,
                    }).$promise
                },
                programme: function (Programme, lot) {
                    return Programme.get({
                        programmeId: lot.programme_id,
                    }).$promise
                },
            },
            params: {
                contact: null,
            },
            data: {
                auth: true,
            },
        });
    })

    /**
     * Route component
     */
    .component('optionnerRoute', {
        controller: 'OptionnerCtrl',
        templateUrl: 'optionner/optionner.html',
        bindings: {
            lot: '<',
            listLotPrix: '<',
            programme: '<',
        },
    });
