/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Attribution.Controller', [
    'contactsService.Service'
])

/**
 * Controller
 */
.controller('AttributionCtrl', ['$uibModalInstance', 'ListeContacts', 'ContactsAttribution', 'toaster',
    function($uibModalInstance, ListeContacts, ContactsAttribution, toaster) {
        var self = this;

        self.$onInit = function() {
            self.vendeur;
            self.contactsSelectionner = ListeContacts.getListeContacts();
        }

        self.annuler = function() {
            $uibModalInstance.dismiss('cancel');
        };

        self.saveAction = function() {
            self.contactsAttribution = new ContactsAttribution({
                vendeur_id: self.vendeur,
                contact_id: self.contactsSelectionner.map(item => item.id).join(',').toString(),
            });
            return self.contactsAttribution.$save().then(function(response) {
                if(response.success) {
                    toaster.pop({
                        type: 'success',
                        title: '',
                        body: response.message,
                    });
                    $uibModalInstance.close();
                }
                else {
                    toaster.pop({
                        type: 'error',
                        title: '',
                        body: 'Erreur lors l\'attribution du vendeur',
                    });
                }

            }).catch(function(err) {
                toaster.pop({
                    type: 'error',
                    title: '',
                    body: 'Erreur lors l\'attribution du vendeur',
                });
            });

        }
    }
]);
