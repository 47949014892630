
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreDenonces', [
    'App.Crm.FiltreDenonces.Controller',
])
    .component('tableFiltreDenonces', {
        controller: 'TableFiltreDenoncesCtrl',
        templateUrl: 'crm/filtre-denonces/table-filtre-denonces.html',
        bindings : {
            filtre : '=',
        }
    });
