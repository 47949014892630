
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreDenonces.Controller', ['statsService.Service'])

    .controller('TableFiltreDenoncesCtrl',['StatsDenonciations','$scope', function (StatsDenonciations, $scope) {

            var self = this;
            self.$onInit = function () {
                getStatsDenonciations();
            };

            function getStatsDenonciations(params) {
                return StatsDenonciations.getStatsDenonciations(params).then(function successCallback(response) {
                    return    self.listeStatDenonciation = response.data.data;
                }, function errorCallback(response) {
                    console.log('Erreur factory StatsActions getStatsActions : ' + response);
                    return false;
                });
            }
            $scope.modifierSelection = function modifierSelection(choix) {
                var index = self.filtre.indexOf(choix);
                if (index > -1) {
                    self.filtre.splice(index, 1);
                }
                else {
                    self.filtre.push(choix);
                }
            };
        }
     ]);

