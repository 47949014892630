/**
 * Module definition and dependencies
 */
angular
  .module("statsService.Service", ["ngResource"])

  /**
   * Service
   */
  .factory("StatsContacts", [
    "$http",
    "Config",
    "$q",
    function($http, Config) {
      return {
        getStatsContacts: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/contacts",
            params: params
          });
        },
        getStatsSourcesProspects: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/contacts/sources",
            params: params
          });
        }
      };
    }
  ])
  .factory("StatsClients", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsSourcesClients: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/clients/sources",
            params: params
          });
        }
      };
    }
  ])
  .factory("StatsDenonciations", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsDenonciations: function() {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/denonciations/"
          });
        }
      };
    }
  ])
  .factory("StatsTransactions", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsTransactions: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/transactions/",
            params: {
              etat: params.etat,
              programme_id: params.programme_id
            }
          }).then(res => {
            const result = {
              total: res.data.total
            };
            result.data = res.data.data.map(i => {
              const obj = {
                id: i.id,
                label: i.label,
                total: i.total
              };
              if (Number.isInteger(i.id)) {
                obj.type_id = i.id;
              } else {
                const split = i.id.split("-");
                // split 0 = type_id
                if (split[0]) {
                  obj.type_id = parseInt(split[0]);
                }
                // split 1 = signature_procedure_status
                if (split[1].length === 0 && split[2]) {
                  obj.signature_procedure_status = parseInt("-" + split[2]);
                } else {
                  obj.signature_procedure_status = parseInt(split[1]);
                }
              }
              return obj;
            });
            return result;
          });
        }
      };
    }
  ])
  .factory("StatsContactTransactions", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsContactTransactions: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/contacts/transactions/",
            params: params
          });
        }
      };
    }
  ])
  .factory("StatsCa", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsCa: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/ca/",
            params: params
          });
        }
      };
    }
  ])
  .factory("StatsActions", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getStatsActions: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/actions",
            params: {
              done: params.done,
              start_date: params.start_date
            }
          });
        }
      };
    }
  ])
  .factory("TypesAction", [
    "$http",
    "Config",
    function($http, Config) {
      return {
        getTypesAction: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/actions",
            params: params
          });
        },
        getTotalActionsContact: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/actions",
            params: {
              done: params.done,
              limit: 1,
              is_contact: 1,
              is_client: 0
            }
          });
        },
        getTotalActionsClient: function(params) {
          return $http({
            method: "GET",
            url: Config.API_BASE_URL + "/stats/actions",
            params: {
              done: params.done,
              limit: 1,
              is_contact: 0,
              is_client: 1
            }
          });
        }
      };
    }
  ]);
