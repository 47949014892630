/**
 * Module definition and dependencies
 */
angular
  .module("App.Optionner.Controller", [
    "ngMessages",
    "contactsService.Service",
    "selectionModel"
  ])

  /**
   * Controller
   */
  .controller("OptionnerCtrl", [
    "$scope",
    "$state",
    "Contacts",
    "Transactions",
    "toaster",
    "$stateParams",
    "ConfigsCommercialisateurs",
    "Auth",
    "ContactsCheck",
    function(
      $scope,
      $state,
      Contacts,
      Transactions,
      toaster,
      $stateParams,
      ConfigsCommercialisateurs,
      Auth,
      ContactsCheck
    ) {
      let self = this;
      self.form = {};

      self.$onInit = function() {
        if ($stateParams.contact) {
          self.contact = $stateParams.contact;
        } else {
          self.contact = {};
        }
        if (Auth && Auth.membreId) {
          self.vendeurTransaction = parseInt(Auth.membreId);
        }

        /* Transform le prix des lot avec lot inclus */
        self.map1 = self.listLotPrix.map(function(x) {
          if (x.lots_lies_inclus) {
            x.prix_ttc_raw -= self.prixTotalAnnexeAll();
          }
          return x;
        });

        /*
         * Initialisation des prix
         */
        self.optionsLotSelectionner = [];
        self.optionsLotAnnexeSelectionner = [];

        // init Lot : Selectionne le premier lot
        self.listLotPrix[0].selected = true;

        // init lot tva
        self.tva = self.listLotPrix[0].tva;

        // init du prix total lot annexe
        self.totalPriceAnnexe = 0;

        // init du prix total
        if (self.listLotPrix[0].selected) {
          self.totalPrice =
            self.listLotPrix[0].prix_ttc_raw + self.prixTotalAnnexeAll();
        } else {
          self.totalPrice = 0;
        }

        // init Lot Annexe : Tout séléctionner
        if (self.lot.lots_annexes != undefined) {
          self.lot.lots_annexes.forEach(function(element) {
            element.selected = true;
          });
        }

        self.transaction = new Transactions();
        self.transaction.lot_id = self.lot.id;
        self.afficherContactForm = false;

        /*
         * Allow commercialisateur lot annexe
         */
        ConfigsCommercialisateurs.getConfigCommercialisateurs()
          .then(function(response) {
            self.configCommercialisateursTransactions = response.data;
          })
          .catch(function(error) {
            console.log(error);
          });
      };

      self.labelMultiSelect = {
        itemsSelected: "éléments sélectionnés",
        selectAll: "Tous sélectionner",
        unselectAll: "Tous déselectionner",
        search: "Rechercher"
      };

      self.checkContactBeforeOptionner = () => {
        if(self.contact.id) {
          self.optionner();
        }
        else {
          ContactsCheck.query(self.contact).$promise.then(response => {
            if(response.type && response.type === "contact_denonce") {
              const bodyMessageContactDenonce = response.contact ? `Création impossible. Ce contact est déjà dénoncé par ${response.contact.commercialisateur_nom} jusqu'au ${moment(response.contact.date_fin.date).format('DD/MM/YYYY')}` : response.message;
              toaster.pop({
                type: "error",
                body: bodyMessageContactDenonce,
              })
              return;
            }
            else {
              self.optionner();
            }
          }).catch(error => {
            console.error('error', error);
          })
        }
      }

      self.optionner = function() {
        let contact = new Contacts(self.contact);
        if (self.contact.id) {
          //Mise a jour du contact
          if (self.formContact && !self.formContact.$pristine) {
            return contact.$update().then(function(data) {
                return self.saveTransaction();
                toaster.pop({
                  type: "success",
                  title: "",
                  body: "Le contact a été mise à jour."
                });
              })
              .catch(function(err) {
                toaster.pop({
                  type: "error",
                  title: "",
                  body: "Erreur lors de la mise à jour du contact." + err
                });
              });
          } else {
            return self.saveTransaction();
          }
        } else {
          //Nouveau contact
          return contact.$save().then(function(data) {
              self.contact = data;
              self.saveTransaction();
              toaster.pop({
                type: "success",
                title: "",
                body: "Le contact a été enregistré."
              });
            })
            .catch(function(err) {
              toaster.pop({
                type: "error",
                title: "",
                body: "Erreur lors de l'enregistrement du contact." + err
              });
            });
        }
      };

      self.saveTransaction = function() {
        let totalPriceAnnexe = 0;
        let id_lot_annexe = "";
        let totalPriceLot = 0;

        if (self.optionsLotSelectionner[0]) {
          totalPriceLot = self.optionsLotSelectionner[0].prix_ttc_raw;
        }

        self.optionsLotAnnexeSelectionner.forEach(function(element) {
          totalPriceAnnexe += element.prix_vente_total;
          id_lot_annexe += element.id + ",";
        });

        if (self.optionsLotAnnexeSelectionner.length != 0) {
          id_lot_annexe = id_lot_annexe.substring(0, id_lot_annexe.length - 1);
        }

        self.transaction.client_id = self.contact.id;
        self.transaction.prix_TTC = totalPriceLot;
        self.transaction.tva = self.tva;
        self.transaction.vendeur_id = self.vendeurTransaction;
        self.transaction.lot_annexes = self.lot.lots_annexes;
        self.transaction.transaction_lots_annexes = id_lot_annexe;
        self.transaction.transaction_prix_annexes_TTC = totalPriceAnnexe;

        return self.transaction
          .$save()
          .then(function() {
            toaster.pop({
              type: "success",
              title: "",
              body: "Votre transaction a été enregistrée."
            });
            $state.transitionTo("mesTransactions", null, { reload: true });
          })
          .catch(function(err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de l'enregistrement de la transaction." + err
            });
          });
      };

      /* Change lot annexe */
      self.optionsLotAnnexeSelectChanged = lotliee => {
        if (!lotliee.selected) {
          self.totalPrice -= lotliee.prix_vente_total;
        } else {
          self.totalPrice += lotliee.prix_vente_total;
        }
      };

      /* Change lot  */
      self.optionsLotSelectChanged = item => {
        if (
          self.optionsLotSelectionner.length === 0 &&
          self.optionsLotAnnexeSelectionner.length === 0
        ) {
          self.totalPrice = 0;
          self.tva = null;
        } else if (
          self.optionsLotSelectionner.length === 0 &&
          self.optionsLotAnnexeSelectionner.length != 0
        ) {
          self.totalPrice = self.prixTotalAnnexeSelect();
        } else {
          self.tva = self.optionsLotSelectionner[0].tva;
          self.totalPrice =
            self.optionsLotSelectionner[0].prix_ttc_raw +
            self.prixTotalAnnexeSelect();
        }
      };

      /* Total : prix des lot annexes selectionne */
      self.prixTotalAnnexeSelect = () => {
        let totalPriceAnnexe = 0;

        if (self.optionsLotAnnexeSelectionner != undefined) {
          self.optionsLotAnnexeSelectionner.forEach(function(element) {
            totalPriceAnnexe += element.prix_vente_total;
          });
        }

        return totalPriceAnnexe;
      };

      /* Total : prix de tous les lots annexes */
      self.prixTotalAnnexeAll = () => {
        let totalPriceAnnexe = 0;

        if (self.lot.lots_annexes != undefined) {
          self.lot.lots_annexes.forEach(function(element) {
            totalPriceAnnexe += element.prix_vente_total;
          });
        }

        return totalPriceAnnexe;
      };

      self.nouveauContact = function() {
        self.contact = {};
        self.afficherContactForm = true;
      };

      $scope.$watch(
        () => self.contact,
        function() {
          if (angular.equals(self.contact, {}) === false) {
            self.afficherContactForm = true;
          }
        },
        true
      );
    }
  ]);
