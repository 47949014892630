/**
 * Module definition and dependencies
 */
angular
  .module("App.Crm.FiltreTransactions.Controller", [
    "configsService.Service",
    "statsService.Service",
  ])
  .controller("TableFiltreTransactionsCtrl", [
    "$scope",
    "ConfigsTransactionsEtats",
    "StatsTransactions",
    "debounce",
    "Transactions",
    "mockFiltresTransactions",
    function (
      $scope,
      ConfigsTransactionsEtats,
      StatsTransactions,
      debounce,
      Transactions,
      mockFiltresTransactions
    ) {
      this.$onInit = () => {
        this.statsTransactions = mockFiltresTransactions.getListe();

        this.filtreTransactionsDefault = {
          id: "default",
          filtre: {
            sort: "date_last_update",
            etat: "4",
            order: "desc",
            not_type_id: 3,
          },
        };

        this.filtreSelected = angular.copy(this.filtreTransactionsDefault);
        this.filtresTransactions = this.formateVal(this.filtreSelected.filtre);

        if (
          !this.filtresTransactions ||
          Object.keys(this.filtresTransactions).length === 0
        ) {
          this.filtres();
        } else {
          this.monFiltresTransactions = {};
          angular.extend(this.monFiltresTransactions, this.filtresTransactions);

          if (
            this.monFiltresTransactions.type_id &&
            !Array.isArray(this.monFiltresTransactions.type_id)
          ) {
            var filtreTypeId = this.monFiltresTransactions.type_id.split(",");
            this.monFiltresTransactions.type_id = filtreTypeId.map(function (
              value
            ) {
              return parseInt(value, 10);
            });
          }
        }

        this.initFiltres();
        this.getTransactionsEtats();
      };

      this.check = (item) => {
        this.monFiltresTransactions.etat = item;
      };

      this.getTransactionsEtats = () => {
        ConfigsTransactionsEtats.getEtats().then(
          (response) => {
            this.filtreTransactionsEtats = response.data;
          },
          (error) => {
            console.log(
              "Erreur factory StatsActions getTransactionsEtats : " + error
            );
          }
        );
      };

      this.initFiltres = () => {
        if (!this.statsTransactions) {
          return;
        }

        this.statsTransactions.map((data) => {
          if (!data || !data.filtre) {
            return;
          }
          const filtre = angular.copy(data.filtre);
          filtre.limit = 1;
          Transactions.query(filtre)
            .$promise.then((response) => {
              data.total = response.total;
            })
            .catch((error) => {
              console.log("error", error);
            });
        });
      };

      this.handleFiltres = (item) => {
        if (!item) {
          return;
        }

        if (
          this.filtreSelected === undefined ||
          item.id === this.filtreSelected.id
        ) {
          this.filtreSelected = angular.copy(this.filtreTransactionsDefault);
        } else {
          this.filtreSelected = item;
        }

        this.filtres();
      };

      this.formateVal = (val) => {
        let query = Object.assign({}, val);
        angular.forEach(query, (value, key) => {
          if (Array.isArray(value)) {
            query[key] = value.join(",").toString();
          }
        });
        return query;
      };

      this.filtres = () => {
        if (!this.filtreSelected || !this.filtreSelected.filtre) {
          return;
        }
        this.filtresTransactions = angular.copy(
          this.formateVal(this.filtreSelected.filtre)
        );
      };

      $scope.$on("actualiserStatsTransactions", (event, data) => {
        this.filtres();
      });
    },
  ]);
