/**
 * Module definition and dependencies
 */
angular
  .module('App.Profil.Controller', [
    'profilService.Service',
    'ngFileUpload',
    'trumbowyg-ng',
    'toaster',
    'ngAnimate',
  ])

  /**
   * Controller
   */
  .controller('ProfilCtrl', [
    function() {
      let self = this;
      self.tabsData = [
        {
          heading: 'Mon profil',
          route: 'profil.monprofil',
        },
        {
          heading: 'Mon panier',
          route: 'profil.monpanier',
        },
      ];
    },
  ])
  .controller('MonProfilCtrl', [
    'Upload',
    '$rootScope',
    'toaster',
    '$uibModal',
    '$cacheFactory',
    'Auth',
    'Token',
    'membre',
    'Config',
    function(
      Upload,
      $rootScope,
      toaster,
      $uibModal,
      $cacheFactory,
      Auth,
      Token,
      membre,
      Config
    ) {
      let self = this;
      self.titleButtonImage = 'Sélectionner';
      self.$onInit = function() {
        self.membre = membre;
        self.file =
          Config.BASE_URL_AVATARS +
          'users/' +
          self.membre.uuid +
          '/profil/picture';
        self.titleButtonImage = 'Modifier';
      };
      self.uploadFiles = function(file, errFiles) {
        self.errFile = errFiles && errFiles[0];
        if (file) {
          self.file = file;
          file.upload = Upload.base64DataUrl(file).then(function(urls) {
            self.membre.image = urls;
          });
        }
      };
      self.submitForm = function() {
        return self.membre
          .createOrUpdate()
          .then(function(response) {
            $rootScope.membreConnecter = response;
            toaster.pop({
              type: 'success',
              title: '',
              body: 'Votre profil à été mis à jour.',
            });
          })
          .catch(function(err) {
            toaster.pop({
              type: 'error',
              title: '',
              body: 'Erreur lors de la mis à jour de votre profil.',
            });
            console.log('Promise error', err);
          });
      };
      self.deconnexion = function() {
        let $httpDefaultCache = $cacheFactory.get('$http');
        $httpDefaultCache.removeAll();
        Auth.onUnauthenticated(false, $rootScope.url_source);
        Token.clear();
      };

      /*******************************************************************************
       *
       * Modal openModalContact
       *
       ******************************************************************************/
      self.openModalResetPassword = function() {
        let modalInstance = $uibModal.open({
          templateUrl: 'profil/modal-reset-password.html',
          controller: 'ProfilResetPasswordCtrl',
          controllerAs: '$ctrl',
          resolve: {
            membre: function() {
              return self.membre;
            },
          },
        });
        modalInstance.result.then(
          function(selectedItem) {},
          function() {
            $log.info('Modal dismissed at: ' + new Date());
          }
        );
      };
    },
  ])
  .controller('ProfilResetPasswordCtrl', [
    '$uibModalInstance',
    'membre',
    'toaster',
    function($uibModalInstance, membre, toaster) {
      let self = this;
      self.$onInit = function() {
        self.formPasswordDisabled = true;
        if (membre) {
          self.membre = membre;
        }
      };

      self.valider = function() {
        return self.membre
          .$update(self.password)
          .then(function(response) {
            $uibModalInstance.close();
            toaster.pop({
              type: 'success',
              title: '',
              body: 'Votre mot de passe a été réinitialisé.',
            });
          })
          .catch(function(error) {
            console.log('Promise error', err);
          });
      };

      self.checkPassword = function() {
        self.formPasswordDisabled = true;
        self.passwordMatch = false;
        if (
          self.password &&
          self.password.password &&
          self.password.password_validation
        ) {
          if (self.password.password === self.password.password_validation) {
            self.passwordMatch = 'match';
            if (self.password.password_old) {
              self.formPasswordDisabled = false;
            }
          }
          else {
            self.passwordMatch = 'nomatch';
          }
        }
      };

      self.annuler = function() {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ]);
