/**
 * Module definition and dependencies
 */
angular
  .module("App.MesContacts.Controller", [
    "ngMessages",
    "selectionModel",
    "Crm.ModelStringToArray",
    "statsService.Service",
    "contactsService.Service",
  ])
  /**
   * Controller
   */
  .controller("MesContactsCtrl", [
    "$storage",
    "$stateParams",
    "$scope",
    "$state",
    function ($storage, $stateParams, $scope, $state) {
      let self = this;
      self.$onInit = function () {
        let mode = $storage.local.get("contactGridPref", "cards");
        self.filtresContacts = {};
        self.contactsSelectionner = [];
        self.nombreResultat = 0;
        self.vendeurSelected = null;
        self.viewMode = mode;
        if ($stateParams.filtres) {
          angular.extend(self.filtresContacts, $stateParams.filtres);
        }
        /**
         * Pour filtre orderby
         */
        self.listeElements = [
          {
            value: "date",
            label: "Date de création",
            default: true,
          },
          {
            value: "date_consultation",
            label: "Date de consultation",
            default: false,
          },
          {
            value: "nom",
            label: "Nom",
            default: false,
          },
          {
            value: "prenom",
            label: "Prénom",
            default: false,
          },
        ];
      };
      self.setViewMode = function (mode) {
        $storage.local.set("contactGridPref", mode);
        self.viewMode = mode;
        self.contactsSelectionner = [];
        if (self.viewMode === "list" && self.filtresContacts.limit) {
          delete self.filtresContacts.limit;
          delete self.filtresContacts.page;
        }
      };
      self.resetFiltres = function () {
        $state.go(
          "mesContacts",
          {
            filtres: null,
          },
          {
            reload: true,
          }
        );
      };
      $scope.$watch(
        () => this.nombreResultat,
        (val, old) => {
          if (parseInt(self.nombreResultat) > 1) {
            self.titlenombreResultat =
              self.nombreResultat + " contacts trouvés";
          } else {
            self.titlenombreResultat = self.nombreResultat + " contact trouvé";
          }
        },
        true
      );
    },
  ])
  .controller("ContactsListViewCtrl", [
    "$state",
    "$scope",
    "NgTableParams",
    "Contacts",
    "ListeContacts",
    "debounce",
    "Auth",
    "$filter",
    function (
      $state,
      $scope,
      NgTableParams,
      Contacts,
      ListeContacts,
      debounce,
      Auth,
      $filter
    ) {
      let self = this;
      self.nombreResultat = 0;
      self.loading = false;
      self.contactsSelectionnerModel = [];

      self.$onInit = () => {
        self.hasRolesAdmin = Auth.hasRole("admin");
        if (!self.filtresContacts) {
          self.filtresContacts = {};
        }

        self.listContacts = new NgTableParams(
          {},
          {
            getData: (params) => {
              let query = {
                limit: params.count(),
                page: params.page(),
                order: params.sorting()
                  ? params.sorting()[Object.keys(params.sorting())[0]]
                  : "",
                sort: params.sorting() ? Object.keys(params.sorting())[0] : "",
              };
              if (self.filtresContacts.sort && query.sort) {
                self.filtresContacts.sort = query.sort;
              }
              if (self.filtresContacts.order && !query.order) {
                query.order = self.filtresContacts.order;
              } else if (self.filtresContacts.order && query.order) {
                self.filtresContacts.order = query.order;
              }
              angular.extend(query, params.filter());
              if (query.search) {
                self.loading = true;
                return Contacts.query(query)
                  .$promise.then(function (data) {
                    self.nombreResultat = data.total;
                    self.loading = false;
                    params.total(data.total);
                    return data.data;
                  })
                  .catch(function (err) {
                    console.log("promise error", err);
                  });
              }
            },
          }
        );
      };

      self.writeToContact = function (contact) {
        ListeContacts.setListeContacts([contact]);
        $state.go("contacterContacts");
      };
      self.editFormContact = function (index, contactId, total) {
        $state.go("formContact.historique", {
          contactId: contactId,
          paramsNavigation: {
            contactId: contactId,
            byindex: self.getIndex(index),
            total: total,
            filtresContacts: self.filtresContacts,
          },
        });
      };
      self.getIndex = function (index) {
        return (
          self.listContacts._params.count *
            (self.listContacts._params.page - 1) +
          index
        );
      };
      self.isChecked = function (item) {
        if (item) {
          let filterElement = $filter("filter")(
            self.contactsSelectionner,
            { id: item.id },
            true
          )[0];
          if (filterElement !== undefined) {
            return true;
          }
        }
        return false;
      };
      $scope.checkAll = function () {
        $scope.isAllChecked = !$scope.isAllChecked;
        if ($scope.isAllChecked && self.listContacts.data) {
          self.contactsSelectionner = angular.copy(self.listContacts.data);
          if (self.listContacts && self.listContacts.data) {
            angular.forEach(self.listContacts.data, function (item) {
              item.selected = true;
            });
          }
        } else {
          self.contactsSelectionner.length = 0;
          self.contactsSelectionnerModel.length = 0;
          if (self.listContacts && self.listContacts.data) {
            angular.forEach(self.listContacts.data, function (item) {
              if (item.selected) {
                item.selected = false;
              }
            });
          }
        }
      };
      $scope.$on("updateContacts", function (event, data) {
        self.contactsSelectionner.length = 0;
        // self.listContacts.reload();
      });
      $scope.$watch(
        () => self.listContacts.data,
        debounce(100, function (val, old) {
          if ($scope.isAllChecked) {
            self.contactsSelectionner = val;
          }
        }),
        true
      );

      $scope.$watch(
        () => self.filtresContacts,
        debounce(300, (val) => {
          const cpVal = angular.copy(val);
          delete cpVal.sort;
          delete cpVal.order;
          self.listContacts._params.filter = cpVal;
        }),
        true
      );

      $scope.$watch(
        () => self.contactsSelectionnerModel,
        debounce(100, function (val, old) {
          self.contactsSelectionner = val;
          if ($scope.isAllChecked && val.length < old.length) {
            $scope.isAllChecked = false;
          }
        }),
        true
      );
    },
  ])
  .filter("filterContactType", function () {
    return function (type) {
      return type === "contact" ? "Prospect" : type;
    };
  })
  .controller("ContactsCardsViewCtrl", [
    "Contacts",
    "$state",
    "$scope",
    "$uibModal",
    "debounce",
    "$q",
    "ListeContacts",
    function (
      Contacts,
      $state,
      $scope,
      $uibModal,
      debounce,
      $q,
      ListeContacts
    ) {
      let self = this;
      let limit = 8;
      self.$onInit = function () {
        self.loadingPromise = $q.resolve();
        self.contacts = [];
        self.allLoaded = false;
        self.nombreResultat = 0;
        self.filtresContacts.limit = limit;
        self.filtresContacts.page = 0;
        self.filtresContactsScroll = {};
        angular.extend(self.filtresContactsScroll, self.filtresContacts);
      };
      self.writeToContact = function (contact) {
        ListeContacts.setListeContacts([contact]);
        $state.go("contacterContacts");
      };
      self.editFormContact = function (index, contact, total, typeLien) {
        if (typeLien && contact.typeLien === 1) {
          $state.go("formContact.panier", {
            contactId: contact.id,
            paramsNavigation: {
              contactId: contact.id,
              byindex: index,
              total: total,
              filtresContacts: self.filtresContacts,
            },
          });
        } else {
          $state.go("formContact.historique", {
            contactId: contact.id,
            paramsNavigation: {
              contactId: contact.id,
              byindex: index,
              total: total,
              filtresContacts: self.filtresContacts,
            },
          });
        }
      };
      self.goToContactTab = function (index, contact, total) {
        let paramsNavigation = {
          contactId: contact.id,
          paramsNavigation: {
            contactId: contact.id,
            byindex: index,
            total: total,
            filtresContacts: self.filtresContacts,
          },
        };
        let groupe_avancement_id = parseInt(contact.groupe_avancement_id);
        let states = [
          "",
          "formContact.historique",
          "formContact.historique",
          "formContact.transactions",
          "formContact.transactions",
          "formContact.transactions",
          "formContact.historique",
          "formContact.historique",
        ];
        $state.go(states[groupe_avancement_id], paramsNavigation);
      };
      self.getAvancementClass = function (contact) {
        let groupe_avancement_id = parseInt(contact.groupe_avancement_id);
        if ([1].indexOf(groupe_avancement_id) > -1) {
          return "class-avancement--atraiter";
        } //1
        if ([2].indexOf(groupe_avancement_id) > -1) {
          return "class-avancement--transaction";
        } //3
        if ([3].indexOf(groupe_avancement_id) > -1) {
          return "class-avancement--relation";
        } // 2
        if ([4].indexOf(groupe_avancement_id) > -1) {
          return "class-avancement--sansSuite";
        } // 7
      };
      /*
       * Modal
       */
      self.newAction = function (item) {
        let modalInstance = $uibModal.open({
          templateUrl: "crm/action/modal-form-new-action.html",
          controller: "newActionModalCtrl",
          controllerAs: "$ctrl",
          resolve: {
            contactId: function () {
              return item.id;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            self.selected = selectedItem;
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
      self.queryContacts = function () {
        console.log("queryContacts");
        return (self.loadingPromise = self.loadingPromise
          .then(function () {
            self.loading = true;
            if (!self.allLoaded) {
              self.filtresContactsScroll.page =
                self.filtresContactsScroll.page + 1;
              let query = self.filtresContactsScroll;
              return Contacts.query(query).$promise.then(function (contacts) {
                self.nombreResultat = contacts.total;
                angular.forEach(contacts.data, function (c) {
                  let contact = new Contacts(c);
                  self.contacts.push(contact);
                  return self.getPanier(contact);
                });
                if (self.nombreResultat <= self.contacts.length) {
                  self.allLoaded = true;
                  return $q.resolve();
                }
              });
            }
          })
          .then(function () {
            self.loading = false;
          })
          .catch(function (err) {
            console.log("promise error", err);
          }));
      };
      self.getPanier = function (contact) {
        // Paniers.query({
        //     contact_id: contact.id
        // }).$promise.then(function (data) {
        //     contact.panier = data.data
        //Si panier === 0 => on affiche le lien besoin
        contact.panier = [];
        if (!contact.preference_ville && !contact.preference_types) {
          contact.typeLien = 0;
          contact.typeLien_label = "Besoin";
        }
        //Si panier > 1 => on affiche le lien vers la fiche contact/panier
        else {
          contact.typeLien = 1;
          contact.typeLien_label = "Sélection";
        }
        // }).catch(function (err) {
        //     console.log('promise error', err);
        // });
      };
      $scope.$watch(
        () => {
          let obj = {};
          for (let key in self.filtresContacts) {
            if (self.filtresContacts[key]) {
              obj[key] = self.filtresContacts[key];
            }
          }
          return obj;
        },
        debounce(200, function (val, old) {
          if (angular.equals(val, old)) {
            return;
          }
          self.loadingPromise.then(() => {
            self.nombreResultat = 0;
            self.allLoaded = false;
            self.contacts = [];
            angular.extend(self.filtresContactsScroll, self.filtresContacts);
            self.filtresContactsScroll.page = 0;
            $scope.$broadcast("filter");
            self.queryContacts();
          });
        }),
        true
      );

      $scope.$on("updateContacts", function (event, data) {
        self.contactsSelectionner = [];
        self.nombreResultat = 0;
        self.allLoaded = false;
        self.contacts = [];
        self.filtresContactsScroll.page = 0;
        self.queryContacts();
      });
    },
  ])
  .controller("RechercheContactsCtrl", [
    "$scope",
    "ConfigsLots",
    "ConfigsProgrammes",
    "$filter",
    "debounce",
    "ConfigsCommercialisateurs",
    "ConfigsContacts",
    "ConfigsVendeurs",
    function (
      $scope,
      ConfigsLots,
      ConfigsProgrammes,
      $filter,
      debounce,
      ConfigsCommercialisateurs,
      ConfigsContacts,
      ConfigsVendeurs
    ) {
      let self = this;
      self.labelMultiSelect = {
        itemsSelected: "éléments sélectionnés",
        selectAll: "Tout sélectionner",
        unselectAll: "Tout déselectionner",
        search: "Chercher",
      };
      self.$onInit = function () {
        this.sliderRayonValue = 10;
        this.sliderRayonOptions = {
          id: "sliderRayon",
          floor: 10,
          ceil: 50,
          step: 5,
          //disabled: true,
          onChange: this.changeValueRayon,
        };
        this.sliderRayonMin = 10;
        this.sliderRayonMax = 50;

        self.listBesoins = ["Habiter", "Investir"];
        self.filtresContacts = {};
        self.filtreContacts = {
          search: false,
          ignoreVendeursInterceptor: true,
        };
        self.filtresDate = {};
        ConfigsLots.getNatures().then(
          function successCallback(response) {
            self.listNatures = response.data.map((item) => item.label);
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getNatures : " + response);
            return false;
          }
        );
        ConfigsLots.getTypes().then(
          function successCallback(response) {
            self.listTypes = response.data.map((item) => item.label);
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getTypes : " + response);
            return false;
          }
        );
        ConfigsProgrammes.getVilles().then(
          function successCallback(response) {
            self.listVilles = response.data.map((item) =>
              item.label.toUpperCase()
            );
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsProgrammes getVilles : " + response
            );
            return false;
          }
        );
        ConfigsLots.getStatuts().then(
          function successCallback(response) {
            self.listStatuts = response.data;
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getStatuts : " + response);
            return false;
          }
        );
        ConfigsProgrammes.getAvancementsProgrammes().then(
          function successCallback(response) {
            self.listAvancements = response.data.map((item) => item.label);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsProgrammes getListAvancementsProgramme : " +
                response
            );
            return false;
          }
        );
        ConfigsProgrammes.getProgrammes({
          etat: "1,2,3,6",
          limit: -1,
          statut: "0,1,2",
          sort: "nom",
          programme_allowed: true,
          programme_non_dispo: true,
        }).then(
          function successCallback(response) {
            self.listProgrammes = response.data.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsProgrammes getProgrammes : " + response
            );
            return false;
          }
        );
        ConfigsLots.getSurfaces().then(
          function successCallback(response) {
            response = response.data;
            self.surfaceMin = self.initMinMaxSlider(
              self.filtreContacts.surface_min,
              response.surface_min
            );
            self.surfaceMax = self.initMinMaxSlider(
              self.filtreContacts.surface_max,
              response.surface_max
            );
            self.sliderSurfaces = {
              initValue: {
                min: self.surfaceMin,
                max: self.surfaceMax,
              },
              min: self.surfaceMin,
              max: self.surfaceMax,
              options: {
                id: "sliderSurfacse",
                floor: Math.ceil(response.surface_min),
                ceil: Math.ceil(response.surface_max),
                pushRange: true,
                onChange: self.setValueSliderSurfaces,
                translate: function (value) {
                  return value + "&nbsp;m<sup>2</sup>";
                },
              },
            };
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getSurfaces : " + response);
            return false;
          }
        );
        ConfigsLots.getPrix().then(
          function successCallback(response) {
            response = response.data;
            self.prixMin = self.initMinMaxSlider(
              self.filtreContacts.prix_min,
              response.prix_min
            );
            self.prixMax = self.initMinMaxSlider(
              self.filtreContacts.prix_max,
              response.prix_max
            );
            self.sliderPrixLots = {
              initValue: {
                min: self.prixMin,
                max: self.prixMax,
              },
              min: self.prixMin,
              max: self.prixMax,
              options: {
                id: "sliderPrixLots",
                floor: Math.ceil(response.prix_min),
                ceil: Math.ceil(response.prix_max),
                //step: 1000,
                pushRange: true,
                onChange: self.setValueSliderPrixLots,
                translate: function (value) {
                  return $filter("currency")(value, response.unite, 0);
                },
              },
            };
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getPrix : " + response);
            return false;
          }
        );
        ConfigsLots.getEtages().then(
          function successCallback(response) {
            self.listEtages = response.data.map((item) => item.label);
          },
          function errorCallback(response) {
            console.log("Erreur factory ConfigsLots getEtages : " + response);
            return false;
          }
        );
        ConfigsCommercialisateurs.getFiscalites().then(
          function successCallback(response) {
            self.listDispositifFiscal = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsLots getFiscalites : " + response
            );
            return false;
          }
        );
        ConfigsContacts.getOrigines().then(
          function successCallback(response) {
            self.listOrigine = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ContactsInfos getOrigines : " + response
            );
            return false;
          }
        );
        ConfigsContacts.getSources().then(
          function successCallback(response) {
            self.listSource = response.data;
          },
          function errorCallback(response) {
            console.log("Erreur factory ContactsInfos getSources: " + response);
            return false;
          }
        );
        ConfigsVendeurs.getVendeurs().then(
          function (response) {
            angular.forEach(response.data, function (item, key) {
              if (item.nom) {
                item.nom = item.nom.toUpperCase();
              }
            });
            self.listVendeurs = response.data;
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsVendeurs getVendeurs : " + response
            );
            return false;
          }
        );
        ConfigsContacts.getAvancements().then(
          function successCallback(response) {
            self.listAvancements = response.data;
            ConfigsContacts.getGroupesAvancements().then(
              function successCallback(response) {
                self.listGroupesAvancementsView = [];
                angular.forEach(response.data, function (value, key) {
                  angular.forEach(self.listAvancements, function (v, k) {
                    if (v.groupe_avancement_id === value.id) {
                      self.listGroupesAvancementsView.push({
                        groupe_avancement_id: value.id,
                        groupe_avancement_label: value.label,
                        avancement_id: v.id,
                        avancement_label: v.label,
                      });
                    }
                  });
                });
              },
              function errorCallback(response) {
                console.log(
                  "Erreur factory ConfigsContacts getGroupeAvancements: " +
                    response
                );
                return false;
              }
            );
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory ConfigsContacts getAvancements: " + response
            );
            return false;
          }
        );
        let prefPeriodeAchat;
        self.tabPeriodeAchat = getQuarter(prefPeriodeAchat, 5);
      };

      function getQuarter(contactPreferences, nbYears) {
        let tabReturn = [];
        let currentQuarter = moment().quarter();
        let currentYear = moment().format("YYYY");
        if (contactPreferences) {
          let quarterPreferencesPeriodeAchat = parseInt(
            moment(contactPreferences.date).quarter()
          );
          let yearPreferencesPeriodeAchat = parseInt(
            moment(contactPreferences.date).format("YYYY")
          );
          if (yearPreferencesPeriodeAchat < currentYear) {
            tabReturn.push(
              getQuarterRange(
                quarterPreferencesPeriodeAchat,
                yearPreferencesPeriodeAchat
              )
            );
          }
        }
        for (var q = currentQuarter; q <= 4; q++) {
          tabReturn.push(getQuarterRange(q, parseInt(currentYear)));
        }
        for (let y = 1; y <= nbYears; y++) {
          for (var q = 1; q <= 4; q++) {
            tabReturn.push(getQuarterRange(q, parseInt(currentYear) + y));
          }
        }
        return tabReturn;
      }

      function getQuarterRange(quarter, year) {
        let start = moment({
          year: year,
        })
          .quarter(quarter)
          .startOf("quarter");
        let end = moment({
          year: year,
        })
          .quarter(quarter)
          .endOf("quarter")
          .format("DD/MM/YYYY");
        let label =
          quarter === 1
            ? quarter + "er trimestre " + year
            : quarter + "eme trimestre " + year;
        let value = start.format("YYYY-MM-DD");
        start = start.format("DD/MM/YYYY");
        return {
          start,
          end,
          year,
          label,
          value,
        };
      }

      self.setValueSliderSurfaces = function (str, min, max) {
        self.filtreContacts.surface_min =
          parseInt(min) === self.surfaceMin ? null : parseInt(min);
        self.filtreContacts.surface_max =
          parseInt(max) === self.surfaceMax ? null : parseInt(max);
      };
      self.setValueSliderPrixLots = function (str, min, max) {
        self.filtreContacts.prix_min =
          parseInt(min) === self.prixMin ? null : parseInt(min);
        self.filtreContacts.prix_max =
          parseInt(max) === self.prixMax ? null : parseInt(max);
      };
      self.initMinMaxSlider = function (valeur, valeurDefault) {
        if (valeur) {
          return Math.ceil(valeur);
        } else {
          return Math.ceil(valeurDefault);
        }
      };
      self.reinitialiserFiltres = function () {
        self.filtreContacts = {
          search: false,
          ignoreVendeursInterceptor: true,
        };
        self.filtresDate = {};
        self.filtresContacts = {};
        self.sliderRayonOptions.disabled = true;
        //Slider prixLots
        self.reinitialiserRZSlider(self.sliderPrixLots);
        //Slider surfaces
        self.reinitialiserRZSlider(self.sliderSurfaces);
        refreshSlider();

        $scope.$broadcast("reset-search-ville", self.villeSelected);
      };

      self.reinitialiserRZSlider = function (rzslider) {
        if (
          rzslider &&
          rzslider.initValue &&
          rzslider.initValue.min !== undefined
        ) {
          rzslider.min = rzslider.initValue.min;
        }
        if (
          rzslider &&
          rzslider.initValue &&
          rzslider.initValue.max !== undefined
        ) {
          rzslider.max = rzslider.initValue.max;
        }
      };

      function refreshSlider() {
        setTimeout(function () {
          $scope.$broadcast("rzSliderForceRender");
        }, 800);
      }

      this.changeValueRayon = () => {
        if (!this.villeSelected) {
          return;
        }
        self.filtreContacts.rayon = this.sliderRayonValue;
      };

      $scope.$watch(
        function () {
          return self.filtreContacts;
        },
        debounce(400, function () {
          if (!self.filtresContacts) {
            self.filtresContacts = {};
          }
          self.filtreContacts.search = true;
          angular.extend(self.filtresContacts, self.filtreContacts);
        }),
        true
      );
      $scope.$watch(
        function () {
          return self.filtresDate;
        },
        debounce(400, function () {
          let filtres_date = {};
          if (
            self.filtresDate &&
            self.filtresDate.date_end &&
            self.filtresDate.date_end.date
          ) {
            self.datePickerOptionsDateStart.maxDate =
              self.filtresDate.date_end.date;
            filtres_date.contact_end_date = moment(
              self.filtresDate.date_end.date
            ).format("YYYY-MM-DD");
          }
          if (
            self.filtresDate &&
            self.filtresDate.date_start &&
            self.filtresDate.date_start.date
          ) {
            self.datePickerOptionsDateEnd.minDate =
              self.filtresDate.date_start.date;
            filtres_date.contact_start_date = moment(
              self.filtresDate.date_start.date
            ).format("YYYY-MM-DD");
          }
          angular.extend(self.filtresContacts, filtres_date);
        }),
        true
      );

      $scope.$watch(
        () => {
          return this.villeSelected;
        },
        debounce(400, (val, old) => {
          if (!val && !old) {
            return;
          }

          if (!val || !val.coordinates) {
            delete this.filtreContacts.latlng;
            delete this.filtreContacts.rayon;
            delete this.filtresContacts.latlng;
            delete this.filtresContacts.rayon;
            this.sliderRayonOptions.disabled = true;
          } else {
            if (!this.filtreContacts) {
              this.filtreContacts = {};
            }
            this.filtreContacts.latlng = `${val.coordinates[1]},${val.coordinates[0]}`;
            this.filtreContacts.rayon = this.sliderRayonValue;
            this.sliderRayonOptions.disabled = false;
          }

          angular.extend(this.filtresContacts, this.filtreContacts);
        }),
        true
      );
    },
  ])
  .controller("ContactsPanelCtrl", [
    "Contacts",
    "$state",
    function (Contacts, $state) {
      let self = this;
      self.items = [];
      self.$onInit = function () {
        if (self.params.filtres) {
          Contacts.query(self.params.filtres)
            .$promise.then(function (response) {
              self.nombreResultat = response.total;
              self.items = response.data;
            })
            .catch(function (err) {
              console.log("promise error", err);
            });
        }
      };
      self.goToContact = function (contact) {
        $state.go("formContact.historique", {
          contactId: contact.id,
        });
      };
      self.goToPage = function () {
        $state.go("mesContacts", {
          filtres: {
            groupe_avancement: self.params.filtres.groupe_avancement,
          },
        });
      };
    },
  ])
  .filter("filterCivilite", function () {
    return function (item) {
      if (item) {
        if (item === "F") {
          return "Mme";
        }
        return item;
      }
      return "";
    };
  });
