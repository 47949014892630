/**
 * Module definition and dependencies
 */
angular.module('App.AlertesProgrammes.Controller', [
  'programmesService.Service',
])

/**
 * Controller
 */.controller('AlertesProgrammesCtrl', [
  'NgTableParams', 'AlertesProgrammes',
  function(NgTableParams, AlertesProgrammes) {
    var self = this;
    self.listAlertesProgrammes = [];
    self.listAlertesProgrammesMaquees = [];
    self.tableParamsAlertesProgrammes = new NgTableParams({}, {
      getData: function(params) {
        return AlertesProgrammes.query({
          'limit': params.count(),
          'page': params.page(),
          'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
          'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
        }).$promise.then(function(data) {
          params.total(data.total);
          self.listAlertesProgrammes = data.data;
          return data.data;
        }).catch(function(err) {
          console.log('Promise error', err);
        });
      },
    });
    self.listAlertesProgrammesMaquees = new NgTableParams({}, {
      getData: function(params) {
        return AlertesProgrammes.query({
          'limit': params.count(),
          'page': params.page(),
          'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
          'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
        }).$promise.then(function(data) {
          params.total(data.total);
          self.listAlertesProgrammes = data.data;
          return data.data;
        }).catch(function(err) {
          console.log('Promise error', err);
        });
      },
    });
    self.masquerAlerteProgramme = function() {
      console.log('masquerAlerteProgramme ');
    };
    self.afficherAlerteProgramme = function() {
      console.log('afficherAlerteProgramme ');
    };
  }]).controller('ListeAlertesProgrammesCtrl', [
  'AlertesProgrammes',
  function(AlertesProgrammes) {
    var self = this;
    self.$onInit = function() {
      AlertesProgrammes.query().$promise.then(function(data) {
        self.listAlertesProgrammes = data.data;
        return data.data;
      }).catch(function(err) {
        console.log('Promise error', err);
      });
    };
  },
]).controller('AlertesProgrammesPanelCtrl', [
  'AlertesProgrammes', '$uibModal',
  function(AlertesProgrammes, $uibModal) {
    var self = this;
    self.items = [];

    self.$onInit = function() {
      if (self.params.filtres) {
        AlertesProgrammes.query().$promise.then(function(response) {
          self.nombreResultat = response.total;
          self.items = response.data;
        }).catch(function(err) {
          console.log('Promise error', err);
        });
      }
    };

    self.openModalAlerteProgramme = function(item) {
      var modalInstance = $uibModal.open({
        templateUrl: 'alertes-programmes/modal-alerte-programme.html',
        controller: 'AlerteProgrammeModalCtrl',
        controllerAs: '$ctrl',
        resolve: {
          alerteProgramme: function() {
            return item;
          },
        },
      });
      modalInstance.result.then(function(selectedItem) {
        self.selected = selectedItem;
      }, function() {
        $log.info('Modal dismissed at: ' + new Date());
      });
    };
  }
]).controller('AlerteProgrammeModalCtrl', [
  '$uibModalInstance', 'alerteProgramme', '$sce',
  function($uibModalInstance, alerteProgramme, $sce) {
    var self = this;

    self.$onInit = function() {
      self.alerteProgramme = alerteProgramme;
    };

    self.trustSrc = function (src) {
      return $sce.trustAsResourceUrl(src);
    };

    self.annuler = function() {
      $uibModalInstance.dismiss('cancel');
    };
  },
]);
