angular.module('App.Interceptors.Service', [])
.factory('vendeursInterceptor', ['ConfigService',
    function(ConfigService) {
        var requestInterceptor = {
            request: function(request) {
                if(request.params && request.params.ignoreVendeursInterceptor) {
                    delete request.params.ignoreVendeursInterceptor;
                    return request;
                }
                var paramsService = ConfigService.getParams();
                if(paramsService
                    && paramsService.vendeur_id !== undefined
                    && paramsService.vendeur_id !== ''
                    && request.params) {

                    request.params.vendeur_id = paramsService.vendeur_id;
                }
                else {
                    if(request.params && request.params.vendeur_id) {
                        delete request.params.vendeur_id;
                    }
                }
                return request;
            }
        }
        return requestInterceptor;
    },
]);