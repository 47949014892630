/**
 * Module definition and dependencies
 */
angular
  .module('App.Panier.Controller', ['paniersService.Service', 'ngMessages'])

  /**
   * Controller
   */
  .controller('PanierCtrl', [
    '$uibModal',
    'NgTableParams',
    'Paniers',
    function($uibModal, NgTableParams, Paniers) {
      let self = this;
      self.lotPanier = true;
      self.$onInit = function() {
        self.showDeleteBtn = true;
        if (self.contact) {
          self.titlePanier =
            'Sélection de ' + self.contact.nom + ' ' + self.contact.prenom;
        }
        else {
          self.titlePanier = 'Ma sélection';
        }
        self.listLots = new NgTableParams(
          {},
          {
            getData: function(params) {
              let query = {
                limit: params.count(),
                page: params.page(),
                order: params.sorting()
                  ? params.sorting()[Object.keys(params.sorting())[0]]
                  : '',
                sort: params.sorting() ? Object.keys(params.sorting())[0] : '',
              };
              if (self.contact) {
                query.contact_id = self.contact.id;
              }
              return Paniers.query(query)
                .$promise.then(function(data) {
                  self.total_logements = data.total;
                  params.total(data.total);
                  return data.data;
                })
                .catch(function(err) {
                  console.log('promise error', err);
                });
            },
          }
        );
      };
      /*
       * Modal openModalConfirmationPanier
       */
      self.openModalPanierConfirmation = function(lot, contact) {
        let modalInstance = $uibModal.open({
          templateUrl: 'panier/modal-panier-confirmation.html',
          controller: 'PanierModalConfirmationCtrl',
          controllerAs: '$ctrl',
          resolve: {
            lot: function() {
              return lot;
            },
            contact: function() {
              return contact;
            },
          },
        });
        modalInstance.result.then(
          function(selectedItem) {
            self.selected = selectedItem;
          },
          function() {
            $log.info('Modal dismissed at: ' + new Date());
          }
        );
      };
    },
  ])
  .controller('PanierModalCtrl', [
    '$state',
    '$uibModalInstance',
    'lot',
    'Contacts',
    'toaster',
    'Paniers',
    function($state, $uibModalInstance, lot, Contacts, toaster, Paniers) {
      let self = this;
      self.$onInit = function() {
        self.lot = lot;
        self.contact = new Contacts();
      };
      self.savePanier = function() {
        let query = {};
        self.panier = new Paniers();
        query.lot_id = self.lot.id;
        if (self.contact) {
          query.contact_id = self.contact.id;
        }
        self.panier
          .$save(query)
          .then(function(response) {
            $uibModalInstance.close();
            toaster.pop({
              type: 'success',
              title: '',
              body: 'Votre sélection a été enregistrée.',
            });
            $state.reload();
          })
          .catch(function(err) {
            console.log(err);
          });
      };
      self.annuler = function() {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('PanierModalConfirmationCtrl', [
    '$state',
    '$uibModalInstance',
    'lot',
    'contact',
    'toaster',
    'Paniers',
    function($state, $uibModalInstance, lot, contact, toaster, Paniers) {
      let self = this;
      self.deleteLotPanier = function(item) {
        let query = {};
        self.panier = new Paniers();
        query.id = lot.id;
        if (contact) {
          query.contact_id = contact.id;
        }
        self.panier
          .$delete(query)
          .then(function(data) {
            $uibModalInstance.close();
            toaster.pop({
              type: 'success',
              title: '',
              body: 'Le lot à été supprimé de votre sélection.',
            });
            $state.reload();
          })
          .catch(function(err) {
            toaster.pop({
              type: 'error',
              title: '',
              body:
                'Erreur lors de la suppression du lot de votre sélection.' +
                err,
            });
          });
      };
      self.annuler = function() {
        $uibModalInstance.dismiss('cancel');
      };
    },
  ])
  .controller('PanierBtnCtrl', [
    'toaster',
    'Paniers',
    '$rootScope',
    function(toaster, Paniers, $rootScope) {
      let self = this;

      self.$onInit = function() {
        if (
          self.panierContact &&
          self.panierContact.length > 0 &&
          self.lot &&
          self.lot.id
        ) {
          if (self.panierContact.find(item => item.id === self.lot.id)) {
            self.lot.showBtn = 'delete';
          }
          else {
            self.lot.showBtn = 'add';
          }
        }
        else {
          self.lot.showBtn = 'add';
        }
      };

      self.addLotPanier = function() {
        let query = {};
        self.panier = new Paniers();
        query.lot_id = self.lot.id;
        if (self.contact) {
          query.contact_id = self.contact.id;
        }
        self.panier
          .$save(query)
          .then(function(response) {
            self.lot.showBtn = 'delete';
            self.panierContact.push(self.lot);
            toaster.pop({
              type: 'success',
              title: '',
              body:
                'Le lot ' +
                self.lot.nom +
                ' à été ajouté à la sélection de ' +
                self.contact.nom.toUpperCase() +
                ' ' +
                self.contact.prenom,
            });
          })
          .catch(function(err) {
            toaster.pop({
              type: 'error',
              title: '',
              body: 'Erreur lors de l\'enregistrement.' + err,
            });
          });
      };

      self.deleteLotPanier = function(item) {
        let query = {};
        self.panier = new Paniers();
        query.id = self.lot.id;

        if (
          !$rootScope.membreConnecter ||
          (self.contact &&
            self.contact.id &&
            $rootScope.membreConnecter &&
            $rootScope.membreConnecter.id &&
            self.contact.id !== $rootScope.membreConnecter.id)
        ) {
          query.contact_id = self.contact.id;
        }
        self.panier
          .$delete(query)
          .then(function(data) {
            self.lot.showBtn = 'add';
            let indexLotPanier = self.panierContact.indexOf(self.lot);
            if (indexLotPanier !== -1) {
              self.panierContact.splice(indexLotPanier, 1);
            }
            toaster.pop({
              type: 'success',
              title: '',
              body:
                'Le lot à été supprimé de la sélection de ' +
                self.contact.nom.toUpperCase() +
                ' ' +
                self.contact.prenom,
            });
          })
          .catch(function(err) {
            toaster.pop({
              type: 'error',
              title: '',
              body: 'Erreur lors de la suppression du lot.' + err,
            });
          });
      };
    },
  ]);
