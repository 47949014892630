/**
 * Module definition and dependencies
 */
angular.module('App.MesContactsDenonces', [
    'App.MesContactsDenonces.Controller',
    //'App.Crm.FiltreDenonces',
    'ngTable',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('mesContactsDenonces', {
            parent: 'app',
            url: '/mes-contacts-denonces',
            component: 'mesContactsDenoncesRoute',
            data: {
                auth: true,
            },
        });
    })

    /**
     * Route component
     */
    .component('mesContactsDenoncesRoute', {
        controller: 'MesContactsDenoncesCtrl',
        templateUrl: 'mes-contacts-denonces/mes-contacts-denonces.html',
        bindings : {
            filtre : '<',
        }
    });
