
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.Action', [
    'App.Crm.Action.Controller',
])

.config($stateProvider => {
    $stateProvider.state('monAction', {
        parent: 'app',
        url: '/action',
        component: 'tableAction',
        data: {
            auth: true,
        },
    });
})
.component('tableAction', {
    controller: 'TableActionCtrl',
    templateUrl: 'crm/action/table-action.html',
    bindings: {
        contact: '<',
    },
})
.component('action', {
    controller: 'actionCtrl',
    templateUrl: 'crm/action/action.html',
    bindings: {
        action: '=',
    },
});
