/**
 * Module definition and dependencies
 */
angular.module('App.AlertesProgrammes', [
  'App.AlertesProgrammes.Controller',
])

/**
 * Config
 */
  .config($stateProvider => {
    $stateProvider.state('alertesProgrammes', {
      parent: 'app',
      url: '/dashboard/alertes-programmes',
      component: 'alertesProgrammesRoute',
    });
  })

  /**
   * Route component
   */
  .component('alertesProgrammesRoute', {
    controller: 'AlertesProgrammesCtrl',
    templateUrl: 'alertes-programmes/alertes-programmes.html',
  })
  .component('listeAlertesProgrammes', {
    controller: 'ListeAlertesProgrammesCtrl',
    templateUrl: 'alertes-programmes/liste-alertes-programmes.html',
  }).component('alertesProgrammesPanel', {
  controller: 'AlertesProgrammesPanelCtrl',
  templateUrl: 'alertes-programmes/alertes-programmes-panel.html',
  bindings: {
    params: '<',
  },
});
