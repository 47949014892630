/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsActionsRealisee.Controller', [
    'statsService.Service',
])

    .controller('ListeStatsActionsRealisee', ['$scope', 'StatsActions', '$state',
        function ($scope, StatsActions, $state) {
            var self = this;


            self.datePickerOptions = {
                dateDisabled: disabled,
                formatYear: 'yy',
                maxDate: new Date(),
                startingDay: 1,
            };

            function disabled(data) {
                var date = data.date,
                    mode = data.mode;
                return mode === 'day' && (date.getDay() === 0 || date.getDay() === 6);
            }

            self.lienActionGroupe = function (id, datestart) {
                var paramsFiltresActions = {
                    done: 1,
                    groupe_action: id.toString(),
                    start_date: moment(datestart).format('YYYY-MM-DD'),
                };
                $state.go('mesActions', {paramsFiltresActions: paramsFiltresActions});
            }

            self.$onInit = function () {
                self.getStatsActions({
                    done: 1,
                    start_date: '',
                });
            };

            self.getStatsActions = function (params) {
                getStatsActions(params).then(function (response) {
                    self.totalStatsActionsRealisee = response.data.total;
                    self.statsActionsRealisee = response.data.data;
                    if ($scope.dateRealiser === undefined) {
                        $scope.dateRealiser = new Date(moment(response.data.start_date.date).format('YYYY-MM-DD'));
                    }
                });
            };

            self.getPercent = function (val) {
                if (self.totalStatsActionsRealisee === 0) {
                    return 0;
                }
                return Math.round(val / self.totalStatsActionsRealisee * 100);
            };

            $scope.$watch('dateRealiser', function (newValue) {
                if ($scope.dateRealiser !== undefined) {
                    self.getStatsActions({
                        done: 1,
                        start_date: moment(newValue).format('YYYY-MM-DD'),
                    });
                }
            });

            function getStatsActions(params) {
                return StatsActions.getStatsActions(params).then(function successCallback(response) {
                    return response;
                }, function errorCallback(response) {
                    console.log('Erreur factory StatsActions getStatsActions : ' + response);
                    return false;
                });
            }
        }
    ]);
