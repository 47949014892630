angular.module('App.Auth.Controller', [
    'Login.Service',
    'RenewPassword.Service',
    'ui.bootstrap'
])
    .controller('LoginCtrl', ['Login', 'Token', '$state', 'toaster', '$rootScope', 'Auth',
        function (Login, Token, $state, toaster, $rootScope, Auth) {
            var self = this;
            self.$onInit = function()  {
                self.selectSociete = false;
                if (Auth.isAuthenticated()) {
                    $state.go('home');
                }
            }
            self.prelogin = function () {
                var message = self.checkParamsConnexion();
                if(message) {
                    toaster.pop({
                        type: 'error',
                        title: '',
                        body: message,
                    });
                    return false;
                }
                if (self.user.employeur_id) {
                    self.login(self.user);
                }
                else {
                    Login.prelogin(self.user.email, self.user.password).then(function (response) {
                        //Un seul employeur
                        if (response.data.length === 1) {
                            self.user.employeur_id = response.data[0].employeur_id;
                            self.login(self.user);
                        }
                        //Plusieurs employeurs : on invite l'utilisateur à sélectionner l'employeur.
                        else if (response.data.length > 1) {
                            angular.forEach(response.data, function(value, key) {
                                if(value.employeur_id === value.rattachement_id) {
                                    value.label = value.employeur_nom;
                                }
                                else {
                                    value.label = value.employeur_nom + ' pour ' + value.rattachement_nom;
                                }
                            });
                            self.selectSociete = true;
                            self.listEmployeurs = response.data;
                        }
                        else {
                            toaster.pop({
                                type: 'error',
                                title: '',
                                body: 'Identifiant ou mot de passe incorrect.',
                            });
                        }
                    }).catch(function (error) {
                        if(error.status === 403) {
                            toaster.pop({
                                type: 'error',
                                title: '',
                                body: 'Vous n\'êtes pas autorisé à vous connecter',
                            });
                        }
                        else {
                            console.log(error);
                            toaster.pop({
                                type: 'error',
                                title: '',
                                body: 'Erreur : ' + error,
                            });
                        }
                    });
                }
            }
            self.login = function (user) {
                user.discr = 'membre';
                Auth.loginWithCredentials(user);
            };

            self.checkParamsConnexion = function() {
                var message = '';
                if(self.user === undefined) {
                    message = 'Veuillez saisir votre email et mot de passe.';
                    return message;
                }
                else if(self.user && self.user.email === undefined) {
                    message = 'Veuillez saisir votre email.';
                    return message;
                }
                else if(self.user && self.user.password === undefined) {
                    message = 'Veuillez saisir votre mot de passe.';
                    return message;
                }
                else if(self.selectSociete && self.user.employeur_id === undefined) {
                    message = 'Vous devez préciser la société avec laquelle vous désirez vous connecter';
                    return message;
                }
                else {
                    return false;
                }
            }
        }
    ])
    .controller('RenewPasswordCtrl', ['RenewPassword',
        function (RenewPassword) {
            var self = this;
            self.message = '';
            self.renewPassword = function () {
                if (self.user.email) {
                    RenewPassword.renewPassword(this.user.email).then((response) => {
                        if (response.success) {
                            self.message = 'Un email vient de vous être envoyé';
                        } else {
                            self.message = 'Aucun email trouvé';
                        }
                    }, (err) => {
                        self.message = 'Une erreur s\'est produite';
                    });
                }
            };
        }
    ])

    .controller('ResetPasswordCtrl', ['$stateParams', '$state', 'ResetPassword', '$uibModal',
        function ($stateParams, $state, ResetPassword, $uibModal) {
            var self = this;
            self.resetPassword = function() {
                self.passwordError = '';
                let token = $stateParams.token;
                if (this.user.password === this.user.passwordConfirmation) {
                    ResetPassword.resetPassword(this.user.id, token, this.user.password).then((data) => {
                        return $uibModal.open({
                          templateUrl: 'auth/modal-reset-password.html'
                        }).result;
                    }).then(() => {
                      $state.go('auth.login');
                    });
                } else {
                    self.passwordError = 'Erreur lors de la confirmation du mot de passe';
                }
            }
        }
    ]);
