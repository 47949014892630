/**
 * Module definition and dependencies
 */
angular.module('App.AvancementCommercial.Controller', [
    'contactsService.Service'
])

/**
 * Controller
 */
.controller('AvancementCommercialCtrl', ['$q', 'ConfigsContacts', 'toaster',
    function($q, ConfigsContacts, toaster) {
        var self = this;

        self.$onInit = function() {

            $q.all([
                ConfigsContacts.getGroupesAvancements().then(function successCallback(response) {
                    self.listGroupesAvancements = response.data;
                }, function errorCallback(response) {
                    console.log('Erreur factory ConfigsContacts getGroupeAvancements: ' + response);
                    return false;
                }),
                ConfigsContacts.getAvancements().then(function successCallback(response) {
                    self.listAvancements = response.data;
                }, function errorCallback(response) {
                    console.log('Erreur factory ConfigsContacts getAvancements: ' + response);
                    return false;
                })
            ]).then(function(response) {
                self.listGroupesAvancementsView = [];
                angular.forEach(self.listGroupesAvancements, function (value, key) {
                    angular.forEach(self.listAvancements, function (v, k) {
                        if (v.groupe_avancement_id === value.id) {
                        self.listGroupesAvancementsView.push({
                            groupe_avancement_id: value.id,
                            groupe_avancement_label: value.label,
                            avancement_id: v.id,
                            avancement_label: v.label,
                        });
                        }
                    });
                });
            }).catch(function(err) {
                console.log(err);
            });

        }

    }
])
.controller('ModalAvancementCommercialCtrl', ['$uibModalInstance', 'ListeContacts', 'toaster', 'ContactsAvancements', '$rootScope',
    function($uibModalInstance, ListeContacts, toaster, ContactsAvancements, $rootScope) {
        var self = this;

        self.$onInit = function() {
            self.contactsSelectionner = ListeContacts.getListeContacts();
        }

        self.save = function() {
            var params = {
                contact_id: self.contactsSelectionner.map(item => item.id).join(',').toString(),
                avancement_id: self.avancementCommercialId,
            }

            return ContactsAvancements.save(params).$promise.then(function successCallback(response) {
                var toasterType = 'success';
                if(response.success) {
                    $uibModalInstance.close();
                    //$rootScope.$broadcast('updateContacts');
                }
                else {
                    toasterType = 'error';
                }
                toaster.pop({
                    type: toasterType,
                    title: '',
                    body: response.message,
                });

            }, function errorCallback(error) {
                console.log('Erreur factory ConfigsContacts getGroupeAvancements: ' + error);
                toaster.pop({
                    type: 'error',
                    title: '',
                    body: error,
                });
                return false;
            });
        }
    }
]);
