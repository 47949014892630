/**
 * Module definition and dependencies
 */
angular
  .module('App.ContacterContacts', ['App.ContacterContacts.Controller'])
  .config($stateProvider => {
    $stateProvider.state('contacterContacts', {
      parent: 'app',
      url: '/messages',
      component: 'contacterContacts',
      data: {
        auth: true,
      },
    });
  });
