/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsChiffreAffaire.Controller', [
  'statsService.Service',
  'configsService.Service',
]).controller('ListeStatsChiffreAffaireCtrl', ['$scope', 'StatsCa', 'ConfigService',
  function($scope, StatsCa, ConfigService) {
    var self = this;
    var query = {};

    self.$onInit = function() {
      var params = ConfigService.getParams();
      if (params && params.vendeur_id) {
        query.vendeur_id = params.vendeur_id;
      }
      getStatsCa(query).then(function(response) {
        self.totalStatsCa = response.data.total;
        self.statsCa = response.data.data;
      });
    };

    function getStatsCa(query) {

      if (self.startDate instanceof Date) {
        query.start_date = moment(self.startDate).format('YYYY-MM-DD');
      }

      if (self.endDate instanceof Date) {
        query.end_date = moment(self.endDate).format('YYYY-MM-DD');
      }

      return StatsCa.getStatsCa(query).then(function successCallback(response) {
        return response;
      }, function errorCallback(response) {
        console.log('Erreur factory StatsCa getStatsCa : ' + response);
        return false;
      });
    }

    $scope.$watch(() => this.startDate, (val, old) => {
        getStatsCa(query).then(function(response) {
          self.totalStatsCa = response.data.total;
          self.statsCa = response.data.data;
        });
    }, true);

    $scope.$watch(() => this.endDate, (val, old) => {
        getStatsCa(query).then(function(response) {
          self.totalStatsCa = response.data.total;
          self.statsCa = response.data.data;
        });
    }, true);
  },
]);
