/**
 * Module definition and dependencies
 */
angular.module('App.Abonnement.Controller', [])

/**
 * Controller
 */
.controller('AbonnementCtrl', ['$uibModalInstance', '$scope', 'vendeurs', '$rootScope', 'ConfigsCalendriers', 'debounce',
    function($uibModalInstance, $scope, vendeurs, $rootScope, ConfigsCalendriers, debounce) {
        var self = this;
        self.$onInit = function() {
            if(vendeurs) {
                self.vendeurs = vendeurs;
            }
            else {
                if($rootScope.membreConnecter && $rootScope.membreConnecter.id) {
                    self.vendeurs = $rootScope.membreConnecter.id;
                }
            }
            self.getConfigsCalendriers();
        }

        self.getConfigsCalendriers = function() {
            if(!self.vendeurs && $rootScope.membreConnecter && $rootScope.membreConnecter.id) {
                self.vendeurs = $rootScope.membreConnecter.id;
            }
            ConfigsCalendriers.getConfigs({
                vendeur_id: self.vendeurs,
                ignoreVendeursInterceptor: true,
            }).then(function(response) {
                self.listVendeurs = response.data;
            }, function errorCallback(error) {
                console.log('Erreur factory ConfigsCalendriers getConfig : ' + error);
                return false;
            });
        }

        $scope.$watch(() => self.vendeurs, debounce(200, function(val, old) {
			self.getConfigsCalendriers();
		}), true);

        self.annuler = function() {
            $uibModalInstance.dismiss('cancel');
        };
    },
]);