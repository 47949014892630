/**
 * Module definition and dependencies
 */
angular.module('App.Dashboard.Controller', [])

/**
 * Controller
 */
    .controller('DashboardCtrl', ['$rootScope', '$scope', '$state',
        function ($rootScope, $scope, $state) {
            var self = this;

            self.$onInit = function() {
                self.today = new Date();
                self.startDate = new Date(self.today.getFullYear(), '0', '1');
                self.endDate = self.today;
                initDatePickerOptionsStartDate();
                initDatePickerOptionsEndDate();
            }

            function initDatePickerOptionsStartDate() {
                self.datePickerOptionsStartDate = {
                    startingDay: 1,
                    maxDate: self.today,
                    timezone: 'Europe/Paris',
                };
            }

            function initDatePickerOptionsEndDate() {
                self.datePickerOptionsEndDate = {
                    startingDay: 1,
                    minDate: self.startDate,
                    timezone: 'Europe/Paris',
                };
            }

            $rootScope.hideMenu = true;
            $scope.$on('$destroy', function () {
                $rootScope.hideMenu = false;
            });
            $scope.$on('recherche', function () {
                $state.go('recherche');
            });
            $scope.$watch(() => self.contact, (val) => {
                val && $state.go('formContact.historique', {
                    contactId: val.id,
                });
            });
            $scope.$watch(() => self.startDate, (val) => {
               initDatePickerOptionsEndDate();
            })
        }
    ]);
