/**
 * Module definition and dependencies
 */
angular.module('App.Auth', [
    'App.Auth.Service',
    'App.Auth.Token.Model',
    'App.Auth.AuthInterceptor.Service',
    'Login.Service',
    'App.Auth.Controller',
    'ResetPassword.Service',
    'ui.bootstrap',
  ])

  /**
   * Config
   */

  .config(($httpProvider, TokenProvider, Config) => {

    //Add auth interceptor (must be before the error interceptor)
    if (Config.MOCK_HEADERS) {
      $httpProvider.interceptors.unshift('MockInterceptor');
    } else {
      $httpProvider.interceptors.unshift('AuthInterceptor');
    }


    //Configure token provider
    TokenProvider.setClientId(Config.AUTH_CLIENT_IDENTIFIER);

  })

  /**
   * Run logic
   */
  .run(($interval, $state, $log, $transitions, Auth) => {

    /**
     * Check token expiration
     */
    $interval(() => {
      if (Auth.isAuthenticated() && Auth.isExpired()) {
        Auth.logout(true);
        //Auth.refresh().catch(() => Auth.logout(true));
      }
    }, 10000);

    /**
     * Authentication guard
     */
    $transitions.onBefore({
      to(state) {
        return (state.data && state.data.auth === true);
      },
    }, transition => {

      //Get roles
      const to = transition.to();
      const roles = to.data.roles;
      const target = transition.targetState();

      //Not authenticated?
      if (!Auth.isAuthenticated()) {
        $log.info('State', to.name, 'requires authentication.');

        return Auth.goToLoginState(target).$promise.then(function (
          response) {}).catch(function (err) {
          console.log('promise error', err);
        });
        /*
        return Auth
            .refresh()
            .then(() => $state.target(target))
            .catch(() => Auth.goToLoginState(target));
        */
      }

      //Specific roles only?
      if (roles && !roles.some(role => Auth.hasRole(role))) {
        $log.info('State', to.name, 'is role restricted.');
        return transition.router.stateService.target('home');
      }
    });

    /**
     * Remember last successful transition
     */
    $transitions.onSuccess({}, transition => {
      Auth.setLastState(transition.targetState());
    });
  })
  .config($stateProvider => {
    $stateProvider.state('auth', {
      url: '',
      virtual: true,
      component: 'authRoute',
      resolve:{
        theme:function($rootScope){
          $rootScope.cheminThemeCss = 'css/theme-front.css';
        }
      }
    });
  })
  .component('authRoute', {
    templateUrl: 'auth/auth.html',
  })
  .config($stateProvider => {
    $stateProvider.state('auth.login', {
      url: '/login',
      component: 'login',
      data: {
        auth: false,
      }
    });
  })
  .component('login', {
    controller: 'LoginCtrl',
    templateUrl: 'auth/login.html',
  })
  .config($stateProvider => {
    $stateProvider.state('auth.renewPassword', {
      url: '/renew-password',
      component: 'renewPassword',
      data: {
        auth: false,
      }
    });
  })
  .component('renewPassword', {
    controller: 'RenewPasswordCtrl',
    templateUrl: 'auth/renewPassword.html',
  })
  .config($stateProvider => {
    $stateProvider.state('resetPassword', {
      parent: 'auth',
      url: '/reset-password/:token',
      component: 'resetPassword',
      resolve: {
        user: ['ResetPassword', '$stateParams', '$uibModal','$state', (ResetPassword,
          $stateParams,$uibModal,$state) => {
          return ResetPassword.retrieveTokenInformation($stateParams
            .token).catch(e => {
            return $uibModal.open({
              templateUrl: 'auth/modal-renew-password-failure.html'
            }).result.then(() => {
              $state.go('auth.login');
            });
          })
        }]
      }
    });
  })
  .component('resetPassword', {
    controller: 'ResetPasswordCtrl',
    templateUrl: 'auth/resetPassword.html',
    bindings: {
      'user': '<',
    }
  });
