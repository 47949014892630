/**
 * Module definition and dependencies
 */
angular.module('App.IframeComponent', [
  'App.IframeComponent.Controller',
])

  /**
   * Route component
   */
  .component('iframeComponent', {
    controller: 'IframeComponentCtrl',
    templateUrl: 'iframe/iframe.html',
    bindings: {
      item: '<',
      programme: '<',
      lot : '<',
      width: '<',
      height: '<',
    },
  });
