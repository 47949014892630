/**
 * Module definition and dependencies
 */
angular.module('profilService.Service', [
  'ngResource',
])

/**
 * Service
 */
  .factory('Profil', ['$resource', 'Config', function ($resource, Config) {
    var api = $resource(Config.API_BASE_URL + '/profil/', {}, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: false,
      },
    });
    api.prototype.createOrUpdate = function () {
      if (this.id) {
        return this.$update();
      } else {
        return this.$save();
      }
    }
    return api;
  }]);
