/**
 * Module definition and dependencies
 */
angular.module('App.AvancementCommercial', [
    'App.AvancementCommercial.Controller',
])

/**
 * Route component
 */
.component('avancementCommercial', {
    controller: 'AvancementCommercialCtrl',
    templateUrl: 'avancement-commercial/avancement-commercial.html',
    bindings: {
        avancementCommercialId: '=',
    },
})
