/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsContacts', [
  'App.Crm.StatsContacts.Controller',
])
  .component('listeStatsContacts', {
    controller: 'ListeStatsContactsCtrl',
    templateUrl: 'crm/stats-contacts/liste-stats-contacts.html',
  });
