/**
 * Module definition and dependencies
 */
angular
  .module("App.TransactionsContact.Controller", [
    "transactionsService.Service",
    "ngMessages",
  ])

  /**
   * Controller
   */ .controller("TransactionsContactCtrl", [
    "NgTableParams",
    "TransactionsClient",
    "$uibModal",
    "$log",
    "VerifTemplates",
    function (
      NgTableParams,
      TransactionsClient,
      $uibModal,
      $log,
      VerifTemplates
    ) {
      const self = this;
      self.$onInit = () => {
        self.listTransactions = new NgTableParams(
          {},
          {
            getData: (params) => {
              const query = {
                limit: params.count(),
                page: params.page(),
                order: params.sorting()
                  ? params.sorting()[Object.keys(params.sorting())[0]]
                  : "",
                sort: params.sorting() ? Object.keys(params.sorting())[0] : "",
              };
              angular.extend(query, params.filter());
              return TransactionsClient.query({
                clientId: self.contact.id,
                etat: 4,
                signature_procedure_status: "null,0,10,20,30,40,45,50,52,60,65",
              })
                .$promise.then((data) => {
                  params.total(data.total);
                  self.totalTransactions = data.total;
                  data.data.forEach((transaction) => {
                    this.verifTemplates(transaction);
                  });
                  return data.data;
                })
                .catch((err) => {
                  console.log("promise error", err);
                });
            },
          }
        );
      };

      this.verifTemplates = (transaction) => {
        VerifTemplates.get({
          programmeId: transaction.programme_id,
        }).$promise.then((response) => {
          transaction.isTemplateAvailable = response.template;
        });
      };

      /*
       * Modal
       */
      self.openModal = function (item, typeAction) {
        if (typeAction === "valider" && item.rang !== "1") {
          return false;
        }
        var modalInstance = $uibModal.open({
          templateUrl: "templates/modal/modal-transaction.html",
          controller: "MesTransactionsModalCtrl",
          controllerAs: "$ctrl",
          resolve: {
            item: function () {
              return item;
            },
            typeAction: function () {
              return typeAction;
            },
            filtresTransactions: function () {
              return null;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            self.selected = selectedItem;
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
    },
  ])
  .controller("TransactionsContactModalCtrl", [
    "$scope",
    "Transactions",
    "item",
    function ($scope, Transactions, item) {
      $scope.item = item;
      $scope.valider = function (idTransaction) {
        Transactions.delete({
          id: idTransaction,
        });
        $scope.$close();
      };
      $scope.annuler = function () {
        $scope.$dismiss("cancel");
      };
    },
  ]);
