angular.module('RenewPassword.Service', [])
    .factory('RenewPassword', ['Config', '$http', function (Config, $http) {
        return {
            renewPassword: function (email) {
                return $http.post(Config.API_BASE_URL_PUBLIC + '/auth/password_renew',{
                  email,
                  discr: 'membre',
                  promoteur: 'serge-mas-promotion',
                });
            },
        }
    }
    ]);
