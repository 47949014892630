/**
 * Module definition and dependencies
 */
angular.module('App.Dashboard', [
    'App.Dashboard.Controller',
    'App.Crm.StatsActionsAtraiter',
    'App.Crm.StatsActionsRealisee',
    'App.Crm.StatsBusiness',
    'App.Crm.StatsContacts',
])

/**
 * Config
 */.config($stateProvider => {
    $stateProvider.state('dashboard', {
        parent: 'app',
        url: '/dashboard',
        component: 'dashboardRoute',
        hideMenu: true,
        data: {
            auth: true,
            isActive: 'contacts',
        },
    });
})

/**
 * Route component
 */.component('dashboardRoute', {
    controller: 'DashboardCtrl',
    templateUrl: 'dashboard/dashboard.html',
});
