/**
 * Module definition and dependencies
 */
angular
  .module("App.Calendrier.Controller", [
    "actionsService.Service",
    "toaster",
    "ui.calendar",
    "ngIcal",
    "base64",
  ])

  /**
   * Controller
   */
  .controller("CalendrierCtrl", [
    "toaster",
    "$uibModal",
    "debounce",
    "$scope",
    "ical",
    "$http",
    "$sce",
    "$base64",
    "uiCalendarConfig",
    "$compile",
    "Transactions",
    "Actions",
    "$state",
    function (
      toaster,
      $uibModal,
      debounce,
      $scope,
      ical,
      $http,
      $sce,
      $base64,
      uiCalendarConfig,
      $compile,
      Transactions,
      Actions,
      $state
    ) {
      let self = this;

      self.filtreCalendrier = [
        {
          id: "eventsActionsCours",
          label: "Actions en cours",
          color: "#986441",
          checked: true,
        },
        {
          id: "eventsActionsRealisees",
          label: "Actions réalisées",
          color: "#e6b026",
          checked: true,
        },
        {
          id: "eventsTransactions",
          label: "Transactions",
          color: "#986598",
          checked: true,
        },
      ];

      self.updateEventsCalendar = function () {
        let calendar = uiCalendarConfig.calendars.calendar;
        if (calendar) {
          calendar.fullCalendar("refetchEvents");
        }
      };

      $scope.eventRender = function (event, element, view) {
        if (event.popover) {
          element.attr({
            "popover-trigger": '"mouseenter"',
            "popover-placement": '"top"',
            "popover-title": event.popover.title,
            "uib-popover-html": event.popover.content,
          });
          $compile(element)($scope);
        }
      };

      $scope.eventDrop = function (
        event,
        delta,
        revertFunc,
        jsEvent,
        ui,
        view
      ) {
        //edit action
        if (event.action_id && event.vendeur_id && event.start) {
          self.action = new Actions();
          self.action.id = event.action_id;
          self.action.vendeur_id = event.vendeur_id;
          self.action.date_echeance = event.start.format("YYYY-MM-DD HH:mm:ss");
          self.action.$update().then(
            function successCallback(response) {
              if (response.date_echeance && response.date_echeance.date) {
                toaster.pop({
                  type: "success",
                  title: "",
                  body:
                    "L'action à bien été mise à jour au " +
                    moment(response.date_echeance.date).format(
                      "DD/MM/YYYY à HH:mm"
                    ),
                });
              }
            },
            function errorCallback(response) {
              toaster.pop({
                type: "error",
                title: "",
                body: "Une erreur s'est produite lors de la mise à jour de l'action.",
              });
              console.log("Erreur factory Actions $update : " + response);
              return false;
            }
          );
        }
      };

      $scope.eventClick = function (event, jsEvent, view) {
        // Redirection vers la fiche contact
        if (event && !event.contact_id) {
          return;
        }

        $state.go("formContact.historique", {
          contactId: event.contact_id,
        });
      };

      $scope.uiConfig = {
        calendar: {
          locale: "fr",
          themeSystem: "bootstrap3",
          contentHeight: 600,
          editable: true,
          header: {
            left: "month basicWeek agendaDay",
            center: "title",
            right: "today prev,next",
          },
          eventRender: $scope.eventRender,
          eventDrop: $scope.eventDrop,
          eventClick: $scope.eventClick,
          eventResize: $scope.eventDrop,
        },
      };

      $scope.eventsActionsCours = {
        title: "actions",
        color: "#FFFFFF",
        borderColor: self.filtreCalendrier[0].color,
        backgroundColor: self.filtreCalendrier[0].color,
        editable: true,
        events: function (start, end, timezone, callback) {
          self
            .getEventsActions({
              start_date: start.format("YYYY-MM-DD"),
              end_date: end.format("YYYY-MM-DD"),
              limit: -1,
            })
            .then(callback);
        },
      };

      $scope.eventsActionsRealisees = {
        title: "actions",
        color: "#FFFFFF",
        borderColor: self.filtreCalendrier[1].color,
        backgroundColor: self.filtreCalendrier[1].color,
        editable: true,
        events: function (start, end, timezone, callback) {
          self
            .getEventsActions({
              start_date: start.format("YYYY-MM-DD"),
              end_date: end.format("YYYY-MM-DD"),
              limit: -1,
              done: 1,
            })
            .then(callback);
        },
      };

      $scope.eventsTransactions = {
        title: "transactions",
        color: "#FFFFFF",
        borderColor: self.filtreCalendrier[2].color,
        backgroundColor: self.filtreCalendrier[2].color,
        editable: false,
        events: function (start, end, timezone, callback) {
          self
            .getEventsTransactions({
              start_period: start.format("YYYY-MM-DD"),
              end_period: end.format("YYYY-MM-DD"),
              etat: 4,
              limit: -1,
            })
            .then(callback);
        },
      };

      $scope.addRemoveEventSource = function (sources, source) {
        let evalSource = $scope.$eval(source);
        let index = sources.indexOf(evalSource);
        if (index >= 0) {
          sources.splice(index, 1);
        } else {
          sources.push(evalSource);
        }
      };

      $scope.eventSources = [
        $scope.eventsActionsCours,
        $scope.eventsActionsRealisees,
        $scope.eventsTransactions,
      ];

      $scope.$watch(
        () => self.vendeurs,
        debounce(200, function (val, old) {
          self.updateEventsCalendar();
        }),
        true
      );

      /*******************************************************************************
       *
       * getEventsTransactions
       *
       ******************************************************************************/

      self.getEventsTransactions = function (query) {
        let arrayEvents = [];
        if (self.vendeurs) {
          query.vendeur_id = self.vendeurs;
        }
        query.ignoreVendeursInterceptor = true;
        return Transactions.query(query).$promise.then(
          function successCallback(response) {
            response.data.forEach((item) => {
              let eventId,
                eventStartDate,
                eventEndDate,
                eventTitle,
                eventPopover;
              eventId = "transaction-" + item.id;
              eventTitle =
                "Transaction : " + item.client_nom + " " + item.client_prenom;

              eventPopover = {
                title: "Transaction",
                content: '"',
              };

              eventPopover.content +=
                "<strong>Contact :</strong> " +
                item.client_nom +
                " " +
                item.client_prenom +
                "<br />";
              if (item.client_tel) {
                eventPopover.content +=
                  "<strong>Téléphone :</strong> " + item.client_tel + "<br />";
              }
              if (item.client_email) {
                eventPopover.content +=
                  "<strong>Email :</strong> " + item.client_email + "<br />";
              }
              if (item.programme_nom) {
                eventPopover.content +=
                  "<strong>Programme :</strong> " +
                  item.programme_nom +
                  "<br />";
              }
              if (item.lot_nom) {
                eventPopover.content +=
                  "<strong>Lot nom :</strong> " + item.lot_nom + "<br />";
              }
              if (item.lot_type) {
                eventPopover.content +=
                  "<strong>Lot type :</strong> " + item.lot_type + "<br />";
              }
              if (item.lot_surface) {
                eventPopover.content +=
                  "<strong>Lot surface :</strong> " +
                  item.lot_surface +
                  "<br />";
              }
              if (item.lot_etage) {
                eventPopover.content +=
                  "<strong>Lot étage :</strong> " + item.lot_etage + "<br />";
              }
              if (item.vendeur_nom && item.vendeur_prenom) {
                eventPopover.content +=
                  "<strong>Vendeur :</strong> " +
                  item.vendeur_nom +
                  " " +
                  item.vendeur_prenom +
                  "<br />";
              }
              eventPopover.content += '"';

              if (item.date_debut && item.date_debut.date) {
                eventStartDate = item.date_debut.date;
              }

              if (item.date_validite && item.date_validite.date) {
                eventEndDate = item.date_validite.date;
              } else if (
                item.duree_validite &&
                item.date_debut &&
                item.date_debut.date
              ) {
                eventEndDate = moment(item.date_debut.date).add(
                  item.duree_validite,
                  "days"
                );
              }

              if (eventTitle && eventStartDate && eventEndDate) {
                arrayEvents.push({
                  id: eventId,
                  contactId: item.id,
                  transaction_id: item.id,
                  title: eventTitle,
                  start: eventStartDate,
                  end: eventEndDate,
                  location: "",
                  popover: eventPopover,
                });
              }
            });
            return arrayEvents;
          },
          function errorCallback(error) {
            console.log(error);
          }
        );
      };

      /*******************************************************************************
       *
       * getEventsActions
       *
       ******************************************************************************/

      self.getEventsActions = function (query) {
        let arrayEvents = [];
        if (self.vendeurs) {
          query.vendeur_id = self.vendeurs;
        }
        query.ignoreVendeursInterceptor = true;
        return Actions.query(query).$promise.then(
          function successCallback(response) {
            response.data.forEach((item) => {
              let eventId,
                eventStartDate,
                eventEndDate,
                eventTitle,
                eventPopover;
              eventId = "actions-" + item.id;
              eventTitle = "Actions : " + item.action_label;

              eventPopover = {
                title: item.action_label,
                content: '"',
              };

              if (item.contact_nom && item.contact_prenom) {
                eventPopover.content +=
                  "<strong>Contact :</strong> " +
                  item.contact_prenom +
                  " " +
                  item.contact_nom +
                  "<br />";
              }
              if (item.contact_email) {
                eventPopover.content +=
                  "<strong>Email :</strong> " + item.contact_email + "<br />";
              }
              if (item.vendeur_nom && item.vendeur_prenom) {
                eventPopover.content +=
                  "<strong>vendeur :</strong> " +
                  item.vendeur_nom +
                  " " +
                  item.vendeur_prenom +
                  "<br />";
              }
              eventPopover.content += '"';

              if (item.date_echeance && item.date_echeance.date) {
                eventStartDate = eventEndDate = item.date_echeance.date;
              }
              if (eventTitle && eventStartDate && eventEndDate) {
                arrayEvents.push({
                  id: eventId,
                  action_id: item.id,
                  vendeur_id: item.vendeur_id,
                  contact_id: item.contact_id,
                  title: eventTitle,
                  start: eventStartDate,
                  end: eventEndDate,
                  location: "",
                  popover: eventPopover,
                });
              }
            });
            return arrayEvents;
          },
          function errorCallback(error) {
            console.log(error);
          }
        );
      };

      /*******************************************************************************
       *
       * addSourceICS
       *
       ******************************************************************************/

      self.addSourceICS = function () {
        // var url = 'http://user-14090:74ang191@https://baikal2.arka-studio.fr/cal.php/calendars/user-14090/default?export';
        let url =
          "https://baikal2.arka-studio.fr/cal.php/calendars/user-14090/default?export";
        let base64Authorization = $base64.encode("user-14090:74ang191");
        return $http({
          method: "GET",
          url: url,
          headers: {
            Authorization: "Basic " + base64Authorization,
          },
          ignoreNetworkError: true,
          ignoreAccessToken: true,
        }).then(
          function successCallback(response) {
            return self.getEventsICS(response.data);
          },
          function errorCallback(error) {
            console.log("---- error addSourceCalendar ----");
            console.log(error);
            console.log("---------------------------------");
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de la récupération du calendrier.",
            });
          }
        );
      };

      /*******************************************************************************
       *
       * getEventsICS
       *
       ******************************************************************************/

      self.getEventsICS = function (events) {
        let arrayEvents = [];
        let parsedEvents = ICAL.parse(events);
        parsedEvents[2].forEach(function (event) {
          if (event[0] !== "vevent") {
            return;
          }
          let summary, location, start, end;
          event[1].forEach(function (event_item) {
            switch (event_item[0]) {
              case "location":
                location = event_item[3];
                break;
              case "summary":
                summary = event_item[3];
                break;
              case "dtstart":
                start = event_item[3];
                break;
              case "dtend":
                end = event_item[3];
                break;
            }
          });
          if (summary && start && end) {
            arrayEvents.push({
              title: summary,
              start: start,
              end: end,
              location: location,
            });
          }
        });
        return arrayEvents;
      };
    },
  ]);
