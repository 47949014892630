
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsActionsRealisee', [
  'App.Crm.StatsActionsRealisee.Controller',
])
.component('listeStatsActionsRealisee', {
    controller: 'ListeStatsActionsRealisee',
    templateUrl: 'crm/stats-actions-realisee/liste-stats-actions-realisee.html',
});
