/**
 * Module definition and dependencies
 */
angular
  .module('App.Programme', [
    'App.Programme.Controller',
    'programmesService.Service',
  ])

  /**
   * Config
   */
  .config($stateProvider => {
    $stateProvider
      .state('programme', {
        parent: 'app',
        abstract: true,
        url: '/programme/{id: int}',
        params: {
          params: null,
        },
        component: 'programme',
        resolve: {
          programme: function(Programme, $stateParams) {
            return Programme.get({
              programmeId: $stateParams.id,
            }).$promise;
          },
          programmeContenus: function(ProgrammeContenus, $stateParams) {
            return ProgrammeContenus.get({
              programmeId: $stateParams.id,
            }).$promise;
          },
          programmeTypes: function(ConfigsLots, $stateParams) {
            return ConfigsLots.getTypes({
              programme_id: $stateParams.id,
            }).then(function(response) {
              return response.data;
            });
          },
          programmeSurfaces: function(ConfigsLots, $stateParams) {
            return ConfigsLots.getSurfaces({
              programme_id: $stateParams.id,
            }).then(function(response) {
              return response.data;
            });
          },
          statLot: function(Lot, $stateParams) {
            return Lot.query({
              limit: 1,
              programme_id: $stateParams.id,
            }).$promise.then(function(response) {
              return {
                total: response.total,
              };
            });
          },
        },
        data: {
          auth: true,
          isActive: 'programmes',
        },
      })
      .state('programme.presentation', {
        url: '/presentation',
        templateUrl: 'programme/tabs/presentation.html',
        controller: 'ProgrammePresentationCtrl',
      })
      .state('programme.situation', {
        url: '/situation',
        templateUrl: 'programme/tabs/situation.html',
        controller: 'ProgrammeSituationCtrl',
      })
      .state('programme.projet3d', {
        url: '/projet3d',
        templateUrl: 'programme/tabs/projet3d.html',
        controller: 'ProgrammeProjetCtrl',
      })
      .state('programme.videos', {
        url: '/videos',
        templateUrl: 'programme/tabs/videos.html',
        controller: 'ProgrammeVideosCtrl',
      })
      .state('programme.images', {
        url: '/images',
        templateUrl: 'programme/tabs/images.html',
        controller: 'ProgrammeImagesCtrl',
      })
      .state('programme.planmasse', {
        url: '/planmasse',
        templateUrl: 'programme/tabs/planmasse.html',
        controller: 'ProgrammePlanMasseCtrl',
      })
      .state('programme.piecesjointes', {
        url: '/piecesjointes',
        templateUrl: 'programme/tabs/piecesjointes.html',
        controller: 'ProgrammePiecesJointesnCtrl',
      })
      .state('programme.lotsdisponibles', {
        url: '/lotsdisponibles',
        templateUrl: 'programme/tabs/lotsdisponibles.html',
        controller: 'ProgrammeLotsDisponiblesCtrl',
      })
      .state('programme.transactions', {
        url: '/transactions',
        templateUrl: 'programme/tabs/transactions.html',
        controller: 'ProgrammeTransactionsCtrl',
      })
      .state('programme.actualites', {
        url: '/actualites',
        templateUrl: 'programme/tabs/actualites.html',
        controller: 'ProgrammeActualitesCtrl',
      })
      .state('programme.ensavoirplus', {
        url: '/ensavoirplus',
        templateUrl: 'programme/tabs/ensavoirplus.html',
        controller: 'ProgrammeEnSavoirPlusCtrl',
      });
  })
  /**
   * Route component
   */
  .component('programme', {
    controller: 'ProgrammeCtrl',
    templateUrl: 'programme/programme.html',
    bindings: {
      programme: '<',
      programmeContenus: '<',
      programmeTypes: '<',
      programmeSurfaces: '<',
    },
  });
