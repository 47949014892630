/**
 * Module definition and dependencies
 */
angular.module('App.EspaceDocuments.Controller', [
])

/**
 * Controller
 */
  .controller('EspaceDocumentsCtrl', ['NgTableParams', 'Contacts', 'Documents', '$filter', '$scope',
    function (NgTableParams, Contacts, Documents, $filter, $scope) {
      var self = this;
      self.activeTab = 0;
      self.rechercheContact = '';
      self.rowSelectDocuments = [];
      self.rowSelectDestinataires = [];
      self.labelMultiSelect = {
        'itemsSelected': 'éléments sélectionnés',
        'selectAll': 'Tous sélectionner',
        'unselectAll': 'Tous déselectionner',
        'search': 'Chercher',
        'select': 'Sélectionner',
      };

      self.listDocuments = new NgTableParams({}, {
        getData: function (params) {
          //return self.tempDocuments;
          var query = {
            'limit': params.count(),
            'page': params.page(),
            'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
            'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
          }
          angular.extend(query, params.filter());
          return Documents.query(query).$promise.then(function (data) {
            params.total(data.total);
            return data.data;
          }).catch(function (err) {
            console.log('promise error', err);
          });
        },
      });

      self.listRowsDocuments = new NgTableParams({
        count: self.rowSelectDocuments.length,
      }, {
        counts: [],
        getData: function (params) {
          return self.rowSelectDocuments;
        },
      });

      self.listContacts = new NgTableParams({}, {
        getData: function (params) {
          var query = {
            'limit': params.count(),
            'page': params.page(),
            'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
            'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
          }
          angular.extend(query, params.filter());
          return Contacts.query(query).$promise.then(function (data) {
            params.total(data.total);
            return data.data;
          }).catch(function (err) {
            console.log('promise error', err);
          });
        },
      });

      self.listRowsDestinataires = new NgTableParams({
        count: self.rowSelectDestinataires.length,
      }, {
        counts: [],
        getData: function (params) {
          return self.rowSelectDestinataires;
        },
      });

      self.search = function () {
        if ($scope.rechercheContact.length > 2) {
          angular.extend(self.listContacts.filter(), {'q': $scope.rechercheContact});
          self.searchParams = true;
        }
        else if (self.searchParams) {
          angular.extend(self.listContacts.filter(), {'q': ''});
        }
      };

      self.addRow = function (destination, item, prefixClass) {
        var filterElement = $filter('filter')(destination, {id: item.id}, true)[0];
        if (filterElement === undefined) {
          destination.push(item);
          angular.item(document.querySelector(prefixClass + item.id)).addClass('row-selected');
        }
        else {
          destination.splice(destination.indexOf(filterElement), 1);
          angular.item(document.querySelector(prefixClass + item.id)).removeClass('row-selected');
        }
      };

      self.rowClass = function (destination, item) {
        var filterElement = $filter('filter')(destination, {id: item.id}, true)[0];
        if (filterElement === undefined) {
          return false;
        }
        else {
          return true;
        }
      };

      self.next = function(index) {

      }
    }
  ]);
