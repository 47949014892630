/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FichesContactsConsultees.Controller', [
    'contactsService.Service',

])

.controller('TableFichesContactsConsulteesCtrl',['ContactFicheConsultees','NgTableParams', function (ContactFicheConsultees, NgTableParams) {
    var self = this;


    self.listFicheConsultees = new NgTableParams({ }, {
        counts: [] ,
        getData: function () {
            return ContactFicheConsultees.getContactFicheConsultees().then(function (data) {
                return data.data;
            }).catch(function (err) {
                console.log('promise error', err);
            });
        },
    });

}]);
