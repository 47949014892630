/**
 * Module definition and dependencies
 */
angular
  .module("App.Header.Component", [
    "membresService.Service",
    "profilService.Service",
    "commercialisateursService.Service",
    "App.Crm.RechercheContacts",
    "paniersService.Service"
  ])

  /**
   * Component
   */ .component("appHeader", {
    templateUrl: "app/header/header.html",
    controller:
      ("HeaderCtrl",
      [
        "$scope",
        "$state",
        "$sce",
        "$rootScope",
        "moment",
        "Auth",
        "$log",
        "Paniers",
        "Token",
        "$cacheFactory",
        function(
          $scope,
          $state,
          $sce,
          $rootScope,
          moment,
          Auth,
          $log,
          Paniers,
          Token,
          $cacheFactory
        ) {
          var self = this;
          self.$onInit = function() {
            Paniers.get().$promise.then(function(response) {
              self.nombreLotsPanier = response.total;
              self.labelLotsPanier =
                self.nombreLotsPanier <= 1 ? "lot" : "lots";
            });
          };
          $rootScope.status = {
            isopen: false
          };
          $rootScope.toggled = function(open) {
            $log.log("Dropdown is now: ", open);
          };
          $rootScope.toggleDropdown = function($event) {
            $event.preventDefault();
            $event.stopPropagation();
            $rootScope.status.isopen = !$rootScope.status.isopen;
          };
          var self = this;
          self.initDate = moment({});
          self.changeCollapseMobile = function() {
            if (self.collapseMobile) {
              self.collapseMobile = false;
            } else {
              self.collapseMobile = true;
            }
          };
          self.deconnexion = function() {
            var $httpDefaultCache = $cacheFactory.get("$http");
            $httpDefaultCache.removeAll();
            Auth.onUnauthenticated(false, $rootScope.url_source);
            Token.clear();
          };
          self.trustSrc = function(src) {
            return $sce.trustAsResourceUrl(src);
          };

          $scope.$watch(
            () => self.contact,
            val => {
              val &&
                $state.go("formContact.historique", {
                  contactId: val.id
                });
            }
          );
        }
      ]),
    bindings: {
      collapseMobile: "="
    }
  });
