/**
 * Module definition and dependencies
 */
angular
  .module("App.Admin.DeleteContact.Controller", ["contactsService.Service"])

  /**
   * Controller
   */
  .controller("BtnDeleteContactCtrl", [
    "$uibModal",
    function ($uibModal) {
      var self = this;

      self.$onInit = function () {
        if (!self.addClass) {
          self.addClass = "btn btn-primary";
        }
      };

      self.deleteContact = function () {
        var modalInstance = $uibModal.open({
          templateUrl:
            "admin/delete-contact/modal-confirmation-delete-contact.html",
          controller: "DeleteContactCtrl",
          controllerAs: "$ctrl",
          resolve: {
            contact: function () {
              return self.contact;
            },
          },
        });
        modalInstance.result.then(
          function (selectedItem) {
            self.selected = selectedItem;
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
    },
  ])
  .controller("DeleteContactCtrl", [
    "$uibModalInstance",
    "$state",
    "toaster",
    "contact",
    "Contacts",
    "$q",
    function ($uibModalInstance, $state, toaster, contact, Contacts, $q) {
      var self = this;

      self.$onInit = function () {
        self.contact = contact;
      };

      self.saveAction = function () {
        let tabPromises = [];
        let tabContacts;

        if (self.contact && typeof self.contact === "number") {
          tabContacts = [self.contact];
        } else if (
          self.contact &&
          typeof self.contact === "object" &&
          self.contact.id
        ) {
          tabContacts = [self.contact.id];
        } else {
          tabContacts = self.contact.split(",");
        }

        angular.forEach(tabContacts, function (value, key) {
          let ContactPromise = Contacts.delete({
            contactId: value,
          }).$promise.then(
            function (response) {
              return response;
            },
            function errorCallback(response) {
              console.log(
                "Erreur factory DocumentsDemandes query : " + response
              );
              return false;
            }
          );
          tabPromises.push(ContactPromise);
        });

        return $q
          .all(tabPromises)
          .then(function (response) {
            let message = "";
            let toasterType = "success";
            let contactIsDeleted = true;

            if (tabPromises.length > 1) {
              message = "Les contacts ont bien été supprimés";
            } else {
              contactIsDeleted = response && !response[0].message;
              message = contactIsDeleted
                ? "Le contact a bien été supprimé"
                : response[0].message;
              toasterType = contactIsDeleted ? "success" : "danger";
            }

            toaster.pop({
              type: toasterType,
              title: "",
              body: message,
            });

            $uibModalInstance.close({ success: true });

            if (contactIsDeleted) {
              $state.go("mesContacts");
            }
          })
          .catch(function (err) {
            toaster.pop({
              type: "error",
              title: "",
              body: "Erreur lors de la demande de documents.",
            });
          });
      };
    },
  ]);
