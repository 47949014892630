/**
 * Module definition and dependencies
 */
angular.module('App.DenoncerContact', [
  'App.DenoncerContact.Controller',
])

  /**
   * Route component
   */
  .component('denoncerContactModal', {
    controller: 'DenoncerContactCtrl',
    templateUrl: 'denoncer-contact/modal-denoncer-contact.html',
    bindings: {
      contact: '<',
      close: '&',
      dismiss: '&',
    },
  })
  .component('listeDenonciations', {
    controller: 'DenonciationsCtrl',
    templateUrl: 'denoncer-contact/liste-denonciations.html',
    bindings: {
      contact: '<',
    },
  });
