/**
 * Module definition and dependencies
 */

function controller(
  NotificationsMessages,
  $scope,
  $rootScope,
  NotificationRT,
  Config,
  toaster,
  $state
) {
  let self = this;
  self.notifications = [];

  let tabIcons = [
    {
      type: 'historiques_tracker',
      icon: 'email',
    },
    {
      type: 'messages',
      icon: 'email',
    },
    {
      type: 'contacts_attribution',
      icon: 'account_circle',
    },
    {
      type: 'contacts_avancements_bulk',
      icon: 'flash_on',
    },
    {
      type: 'contacts_action',
      icon: 'settings',
    },
    {
      type: 'contacts_reactivation',
      icon: 'person',
    },
    {
      type: 'generations',
      icon: 'email'
    }
  ];

  self.$onInit = function() {
    NotificationRT.connect(
      Config.PREFIX_IOT_TOPIC + '-user-' + self.contact.id
    );
    NotificationsMessages.get().$promise.then(
      function(response) {
        self.totalNotificationsUnread = response.Unread;
        angular.forEach(response.Items, function(value) {
          value.dateCreation = moment.unix(value.creationDate);
          self.notifications.push(new NotificationsMessages(value));
        });
      },
      function errorCallback(response) {
        console.log(
          'Erreur factory Notifications NotificationsMessages : ' + response
        );
        return false;
      }
    );
  };

  self.read = function(notification) {
    if (notification && notification.isRead === 0) {
      updateNotification(notification);
    }

    if (
      notification &&
      (notification.ressource === 'historiques_tracker' ||
        notification.ressource === 'contacts_attribution' ||
        notification.ressource === 'messages' ||
        notification.ressource === 'contacts_action' ||
        notification.ressource === 'contacts_reactivation')
    ) {
      $state.go('formContact.historique', {
        contactId: notification.params.contactId,
      });
    }

    // redirection contacts_avancements_bulk
    if (
      notification &&
      notification.ressource === 'contacts_avancements_bulk'
    ) {
      $state.go('mesContacts');
    }
  };

  function updateNotification(notification) {
    notification.isRead = 1;
    notification.$update().then(
      function(response) {
        notification = response;
        notification.dateCreation = moment.unix(response.creationDate);
        self.totalNotificationsUnread = self.totalNotificationsUnread - 1;
      },
      function errorCallback(response) {
        console.log(
          'Erreur factory Notifications NotificationsMessages : ' + response
        );
        return false;
      }
    );
  }

  function getIcon(ressouce) {
    let mapIcons = tabIcons.map(i => i.type);
    let indexIcon = mapIcons.indexOf(ressouce);
    if (indexIcon > -1) {
      return tabIcons[indexIcon].icon;
    }
    return '';
  }

  $scope.$on('notification', function(evt, data) {
    if (data && data.toaster === undefined) {
      let icons = getIcon(data.ressource);
      toaster.pop({
        type: 'info',
        title: '',
        timeout: 90000,
        iconTemplate: '<i class="material-icons">' + icons + '</i>',
        body: JSON.stringify({
          template: 'templates/notifications/toaster.html',
          data: { item: data },
        }),
        bodyOutputType: 'templateWithData',
      });
      data.toaster = true;
    }
    
    self.notifications.unshift(new NotificationsMessages(data));
    
    if (data.isRead === 0) {
      data.dateCreation = moment.unix(data.creationDate);
      if (self.totalNotificationsUnread === undefined) {
        self.totalNotificationsUnread = 1;
      }
      else {
        self.totalNotificationsUnread = self.totalNotificationsUnread + 1;
      }
    }

    if (!data || !data.params || !data.ressource) {
      return;
    }
    // Generation contrat
    if (data.ressource === "generations") {
        $rootScope.$broadcast("notification_contrat_generer", data.params);
    }
  });
}

controller.$inject = [
  'NotificationsMessages',
  '$scope',
  '$rootScope',
  'NotificationRT',
  'Config',
  'toaster',
  '$state',
];
angular
  .module('App.Notifications.Controller', ['notificationsService.Service'])
  .controller('dropdownNotificationsCtrl', controller);
