angular.module('verifTemplatesService.Service', [
    'ngResource',
])

/**
 * Service
 */.factory("VerifTemplates", ["$resource", "Config", function ($resource, Config) {
  var api = $resource(Config.API_BASE_URL + "/documents/verif-templates/:programmeId", {
    programmeId: "@programmeId"
  }, {
    query: {
      isArray: false
    }
  });
  return api;
}]);
