/**
 * Module definition and dependencies
 */
angular.module('App.UX.Slider', [
    'rzModule',
])
/**
 * Route component
 */
.component('idskSlider', {
    templateUrl: 'slider/slider.html',
    bindings: {
    sliderModel: '=',
    sliderHigh: '=',
    sliderOptions: '<',
    sliderDisabled: '<'
    }
});