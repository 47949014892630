
/**
 * Module definition and dependencies
 */
angular.module('Telephone.Filter', [])

/**
 * Filter definitions
 */
  .filter('telephone', function() {
    return function (telephone) {
      if (!telephone) { return ''; }
      telephone = telephone.toString().trim();
      telephone = telephone.replace(/(0{2}\d{1}|\d{2}|\+\d{2,3})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1 $2 $3 $4 $5');
      return telephone;
    }
  });
