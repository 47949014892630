
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsActionsAtraiter', [
  'App.Crm.StatsActionsAtraiter.Controller',
])
.component('listeStatsActionsAtraiter', {
    controller: 'ListeStatsActionsAtraiterCtrl',
    templateUrl: 'crm/stats-actions-atraiter/liste-stats-actions-atraiter.html',
});
