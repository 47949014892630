/**
 * Module definition and dependencies
 */
angular
  .module('App.Programme.Controller', [
    'ngMessages',
    'ngMap',
    'Crm.ModelStringToArray',
  ])

  /**
   * Controller
   */ .controller('ProgrammeCtrl', [
     '$stateParams',
     'Programmes',
     '$state',
     function($stateParams, Programmes, $state) {
       let self = this;
       self.$onInit = function() {
         self.tabsData = [
           {
             heading: 'Présentation du projet',
             route: 'programme.presentation',
             icon: 'location_city',
           },
           {
             heading: 'Situation',
             route: 'programme.situation',
             icon: 'room',
           },
           {
             heading: 'Projet 3D',
             route: 'programme.projet3d',
             icon: '3d_rotation',
           },
           {
             heading: 'Vidéos',
             route: 'programme.videos',
             icon: 'movie',
           },
           {
             heading: 'Images',
             route: 'programme.images',
             icon: 'panorama',
           },
           {
             heading: 'Pièce(s) jointe(s)',
             route: 'programme.piecesjointes',
             icon: 'attach_file',
           },
           {
             heading: 'Lots disponibles',
             route: 'programme.lotsdisponibles',
             icon: 'search',
           },
           {
             heading: 'Transactions',
             route: 'programme.transactions',
             icon: 'alarm_on',
           },
           {
             heading: 'Actualités',
             route: 'programme.actualites',
             icon: 'alarm_on',
           },
          // {
          //   heading: 'En savoir plus',
          //   route: 'programme.ensavoirplus',
          //   icon : 'add',
          // },
         ];

         self.showNavItem = false;
         if (
          $stateParams &&
          $stateParams.params &&
          $stateParams.params.index != undefined &&
          $stateParams.params.total != undefined
        ) {
           self.showNavItem = true;
           self.index = $stateParams.params.index;
           self.total = $stateParams.params.total;
           self.prevIndex = self.index > 0 ? self.index - 1 : self.index;
           self.nextIndex =
            self.index < self.total ? self.index + 1 : self.total;

           self.btnPrevDisabled = self.index == 0 ? true : false;
           self.btnNextDisabled = self.index + 1 == self.total ? true : false;

           Programmes.query({
             range: self.prevIndex + '-' + self.nextIndex,
           }).$promise.then(
            function(response) {
              self.prevProgramme = response.data[0];
              self.nextProgramme = response.data[response.data.length - 1];
            },
            function errorCallback(response) {
              console.log('Erreur factory Programmes query : ' + response);
              return false;
            }
          );
         }
       };

       self.prevItem = function() {
         if (
          self.prevProgramme &&
          self.prevProgramme.id &&
          self.prevIndex != undefined &&
          self.total != undefined
        ) {
           $state.go('programme.presentation', {
             id: self.prevProgramme.id,
             params: {
               index: self.prevIndex,
               total: self.total,
             },
           });
         }
       };

       self.nextItem = function() {
         if (
          self.nextProgramme &&
          self.nextProgramme.id &&
          self.nextIndex != undefined &&
          self.total != undefined
        ) {
           $state.go('programme.presentation', {
             id: self.nextProgramme.id,
             params: {
               index: self.nextIndex,
               total: self.total,
             },
           });
         }
       };
     },
   ])
  .controller('ProgrammePresentationCtrl', [
    '$scope',
    'programme',
    'programmeContenus',
    'programmeTypes',
    'programmeSurfaces',
    'statLot',
    '$sce',
    function(
      $scope,
      programme,
      programmeContenus,
      programmeTypes,
      programmeSurfaces,
      statLot,
      $sce
    ) {
      let self = this;
      self.$onInit = function() {
        $scope.programme = programme;
        $scope.programmeContenus = programmeContenus;
        $scope.programmeTypes = programmeTypes;
        $scope.programmeSurfaces = programmeSurfaces;
        $scope.statLot = statLot;
      };
      $scope.trustAsHtml = function(string) {
        return $sce.trustAsHtml(string);
      };
    },
  ])
  .controller('ProgrammeSituationCtrl', [
    '$sce',
    '$scope',
    'programme',
    'programmeContenus',
    'Config',
    function($sce, $scope, programme, programmeContenus, Config) {
      let self = this;
      self.$onInit = function() {
        $scope.showGoogleMaps = false;
        if (Config.GOOGLE_API_URL && Config.GOOGLE_API_KEY) {
          $scope.showGoogleMaps = true;
          $scope.googleMapsUrl = Config.GOOGLE_API_URL + Config.GOOGLE_API_KEY;
        }
        $scope.programme = programme;
        $scope.programmeContenus = programmeContenus;
        $scope.center = programme.latitude + ',' + programme.longitude;
      };
    },
  ])
  .controller('ProgrammeProjetCtrl', [
    '$sce',
    '$scope',
    'programme',
    'programmeContenus',
    function($sce, $scope, programme, programmeContenus) {
      let self = this;
      self.$onInit = function() {
        $scope.programme = programme;
        $scope.programmeContenus = programmeContenus;
      };
    },
  ])
  .controller('ProgrammeVideosCtrl', [
    '$sce',
    '$scope',
    'programmeContenus',
    function($sce, $scope, programmeContenus) {
      let self = this;
      self.$onInit = function() {
        $scope.programmeContenus = programmeContenus;
      };
    },
  ])
  .controller('ProgrammeImagesCtrl', [
    '$sce',
    '$scope',
    'programme',
    'ProgrammeImages',
    function($sce, $scope, programme, ProgrammeImages) {
      let self = this;
      self.$onInit = function() {
        ProgrammeImages.get({
          programmeId: programme.id,
        })
          .$promise.then(function(response) {
            $scope.listImagesProgramme = response;
          })
          .catch(function(err) {
            console.log('Promise error', err);
          });
      };
    },
  ])
  .controller('ProgrammePlanMasseCtrl', [
    '$sce',
    '$scope',
    function($sce, $scope) {
      let self = this;
      self.$onInit = function() {
        //TODO
      };
    },
  ])
  .controller('ProgrammePiecesJointesnCtrl', [
    '$sce',
    '$scope',
    'programme',
    function($sce, $scope, programme) {
      let self = this;
      self.$onInit = function() {
        $scope.programme = programme;
      };
    },
  ])
  .controller('ProgrammeLotsDisponiblesCtrl', [
    '$sce',
    '$scope',
    'programme',
    function($sce, $scope, programme) {
      let self = this;
      self.$onInit = function() {
        $scope.filtresLots = { programme_id: programme.id, statut_id: 1 };
        $scope.programme = programme;
        refreshSlider();
      };

      function refreshSlider() {
        setTimeout(function() {
          $scope.$broadcast('rzSliderForceRender');
        }, 50);
      }
    },
  ])
  .controller('ProgrammeTransactionsCtrl', [
    '$sce',
    '$scope',
    'programme',
    function($sce, $scope, programme) {
      let self = this;
      self.$onInit = function() {
        $scope.programme = programme;
      };
    },
  ])
  .controller('ProgrammeActualitesCtrl', [
    '$sce',
    '$scope',
    'programme',
    function($sce, $scope, programme) {
      let self = this;
      self.$onInit = function() {
        $scope.programme = programme;
      };
    },
  ])
  .controller('ProgrammeEnSavoirPlusCtrl', [
    '$sce',
    '$scope',
    'programmeContenus',
    function($sce, $scope, programmeContenus) {
      let self = this;
      self.$onInit = function() {
        $scope.programmeContenus = programmeContenus;
      };
    },
  ]);
