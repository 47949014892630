/**
 * Module definition and dependencies
 */
angular.module('App.FiltreOrder', [
  'App.FiltreOrder.Controller',
]).component('filtreOrder', {
  controller: 'filtreOrderCtrl',
  templateUrl: 'filtre-order/filtre-order.html',
  bindings: {
    filtres: '=',
    listeElements: '<',
    defaultSort: '<',
  },
});
