/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreContacts', [
    'App.Crm.FiltreContacts.Controller',
  ])
  .component('tableFiltreContacts', {
    controller: 'TableFiltreContactsCtrl',
    templateUrl: 'crm/filtre-contacts/table-filtre-contacts.html',
    bindings: {
      filtresContacts: '=',
      loading: '<',
    }
  })
  .constant('selection', {
    contactsSelectionner: null,
  });
