/**
 * Module definition and dependencies
 */
angular.module('App.Admin.DeleteContact', [
    'App.Admin.DeleteContact.Controller',
])

.component('btnDeleteContact', {
    controller: 'BtnDeleteContactCtrl',
    templateUrl: 'admin/delete-contact/btn-delete-contact.html',
    bindings: {
        contact: '<',
        addClass: '<',
    },
});