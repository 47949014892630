/**
 * Module definition and dependencies
 */
angular.module('App.AvancementsMultiselect', [
  'App.AvancementsMultiselect.Controller',
])

  /**
   * Route component
   */
  .component('avancementsMultiselect', {
    controller: 'AvancementsMultiselectCtrl',
    templateUrl: 'avancements/avancements-multiselect.html',
    bindings: {
      loading: '<',
      avancements: '=',
    },
  });
