/**
 * Module definition and dependencies
 */
angular.module('App.Lot.Controller', [
    'ngMessages',
    'lotsService.Service',
])

/**
 * Controller
 */.controller('LotCtrl', [
    '$sce', '$state', 'Lots', 'LotService', '$uibModal', '$rootScope', 'DocumentsLot', '$stateParams',
    function($sce, $state, Lots, LotService, $uibModal, $rootScope, DocumentsLot, $stateParams) {
        var self = this;
        self.btnPrevNavigation = false;
        self.btnNextNavigation = false;
        self.btnNavigation = false;
        self.hasMaquette = false;
        self.$onDestroy = function() {
            $rootScope.classUiView = '';
        };
        self.$onInit = function() {

            if($stateParams && $stateParams.params && $stateParams.params.index != undefined && $stateParams.params.total != undefined && $stateParams.params.query) {
                self.showNavItem = true;
                self.index = $stateParams.params.index;
                self.total = $stateParams.params.total;
                self.prevIndex = self.index > 0 ? self.index - 1 : self.index;
                self.nextIndex = self.index < self.total ? self.index + 1 : self.total;

                self.btnPrevDisabled = self.index == 0 ? true : false;
                self.btnNextDisabled = self.index + 1 == self.total ? true : false;

                self.paramsQuery = $stateParams.params;
                var query = self.paramsQuery.query;
                query.range = self.prevIndex + '-' + self.nextIndex;

                Lots.query(query).$promise.then(function(response) {
                    self.prevLot = response.data[0];
                    self.nextLot = response.data[response.data.length - 1];
                }, function errorCallback(response) {
                    console.log('Erreur factory Programmes query : ' + response);
                return false;
                });
            }
            if (self.lot['maquette_iframe'] && self.lot['maquette_iframe'].length) {
                self.hasMaquette = true;
            }
            else {
                $rootScope.classUiView = 'container mt-3 mb-3 main';
            }

            if (!self.hasMaquette) {
                DocumentsLot.get({lotId: self.lot.id, categorie_id: 1}).$promise.then(function successCallback(response) {
                    self.listPlanVente = response.data;
                }, function errorCallback(response) {
                    console.log('Erreur factory DocumentsLot get : ' + response);
                    return false;
                });
            }
        };

        self.prevItem = function() {
            if(self.prevLot && self.prevLot.id && self.prevIndex != undefined && self.total != undefined && $stateParams.params) {
              self.paramsQuery.index = self.prevIndex;
              $state.go('lot', {
                id: self.prevLot.id,
                params: self.paramsQuery
              });
            }
          }

          self.nextItem = function() {
            if(self.nextLot && self.nextLot.id && self.nextIndex != undefined && self.total != undefined && $stateParams.params) {
              self.paramsQuery.index = self.nextIndex;
              $state.go('lot', {
                id: self.nextLot.id,
                params: $stateParams.params
              });
            }
          }

        self.checkBtnnavigation = function(index, total) {
            if ((parseInt(index) + 1) === parseInt(total)) {
                self.btnNextNavigation = true;
            }
            if (parseInt(index) === 0) {
                self.btnPrevNavigation = true;
            }
        };

        self.trustHtml = function(value) {
            return $sce.trustAsHtml(value);
        };

        /*
         * Modal openModalPanier
         */
        self.openModalPanier = function(lot) {
            var modalInstance = $uibModal.open({
                templateUrl: 'panier/modal-panier.html',
                controller: 'PanierModalCtrl',
                controllerAs: '$ctrl',
                resolve: {
                    lot: function() {
                        return lot;
                    },
                },
            });
            modalInstance.result.then(function(selectedItem) {
                self.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
    },
]);
