/**
 * Module definition and dependencies
 */
angular.module('App.Admin.SelectVendeur', [
    'App.Admin.SelectVendeur.Controller',
    'App.Interceptors.Service',
])
/**
 * Config
 */.config(($httpProvider) => {
    $httpProvider.interceptors.push('vendeursInterceptor');
})
/**
 * Components
 */.component('selectVendeur', {
    controller: 'SelectVendeurCtrl',
    templateUrl: 'admin/select-vendeur/select-vendeur.html',
    bindings: {
        showNonAttribue: '<',
        vendeurSelected: '=',
        titleSelect: '<',
        titleNonAttribue: '<',
        reload: '<',
        loadMembreConnecter: '=',
        noSave: '<',
        isSelect: '<',
    },
})
.component('multiSelectVendeur', {
    controller: 'SelectVendeurCtrl',
    templateUrl: 'admin/select-vendeur/multi-select-vendeur.html',
    bindings: {
        showNonAttribue: '<',
        vendeurSelected: '=',
        titleNonAttribue: '<',
        reload: '<',
        titleSelect: '<',
        noSave: '<',
    },
});