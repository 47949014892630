/**
 * Module definition and dependencies
 */
angular.module('App.Crm.ListLotTransactions', [
  'App.ListLotTransactions.Controller',
])

  .component('listLotTransactions', {
    controller: 'ListLotTransactionsCtrl',
    templateUrl: 'crm/list-lot-transactions/list-lot-transactions.html',
    bindings: {
      lot: '<',
    },
  });
