angular.module('actionsService.Service', [
    'ngResource',
])

/**
 * Service
 */.factory('Actions', [
    '$resource', 'Config', function($resource, Config) {
        var actionsApi = $resource(Config.API_BASE_URL + '/actions/:id', {id: '@id'}, {
            update: {
                method: 'PUT',
            },
            query: {
                isArray: false,
                transformResponse: function(data) {
                    var result = angular.fromJson(data);
                    result.data = result.data.map(a => new actionsApi(a));
                    return result;
                },
            },
            queryNoTransformResponse: {
                isArray: false,
                method: 'GET',
            },
        });
        actionsApi.prototype.createOrUpdate = function() {
            if (this.id) {
                return this.$update();
            } else {
                return this.$save();
            }
        };
        return actionsApi;
    }]).factory('ListActions', [
    '$resource', 'Config', function($resource, Config) {
        var actionsApi = $resource(Config.API_BASE_URL + '/actions/', {}, {
            update: {
                method: 'PUT',
            },
            query: {
                isArray: false,
            },
        });
        actionsApi.prototype.createOrUpdate = function() {
            if (this.id) {
                return this.$update();
            } else {
                return this.$save();
            }
        };
        return actionsApi;
    }]).factory('ActionsClient', [
    '$http', 'Config',
    function($http, Config) {
        return {
            'getActionsClient': function(params) {
                return $http({
                    method: 'GET',
                    url: Config.API_BASE_URL + '/actions?is_client=1',
                    params: {
                        done: params.done,
                    },
                });
            },
        };
    },
]).factory('ActionsContact', [
    '$http', 'Config',
    function($http, Config) {
        return {
            'getActionsContact': function(params) {
                return $http({
                    method: 'GET',
                    url: Config.API_BASE_URL + '/actions?is_contact=1',
                    params: {
                        done: params.done,

                    },
                });
            },
        };
    },
]);