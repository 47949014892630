
/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsChiffreAffaire', [
  'App.Crm.StatsChiffreAffaire.Controller',
])
.component('listeStatsChiffreAffaire', {
    controller: 'ListeStatsChiffreAffaireCtrl',
    templateUrl: 'crm/stats-chiffre-affaire/liste-stats-chiffre-affaire.html',
     bindings: {
      startDate: '<',
      endDate: '<',
    },
});
