/**
 * Module definition and dependencies
 */
angular.module('programmesService.Service', [
  'ngResource',
])

/**
 * Service
 */
  .factory('AlertesProgrammes', ['$resource', 'Config', function ($resource, Config) {
    var alertesProgrammesApi = $resource(Config.API_BASE_URL + '/programmes/alertes', {}, {
      query: {
        isArray: false,
      }
    });
    return alertesProgrammesApi;
  }])
  .factory('Programmes', ['$resource', 'Config', function ($resource, Config) {
    var programmesApi = $resource(Config.API_BASE_URL + '/programmes/', {}, {
      query: {
        isArray: false,
      }
    });
    return programmesApi;
  }])
  .factory('Programme', ['$resource', 'Config', function ($resource, Config) {
    var programmeApi = $resource(Config.API_BASE_URL + '/programmes/:programmeId', {
      programmeId: '@programmeId',
    }, {
      query: {
        isArray: false,
      }
    });
    return programmeApi;
  }])
  .factory('ProgrammeContenus', ['$resource', 'Config', function ($resource, Config) {
    var programmeContenusApi = $resource(Config.API_BASE_URL + '/programmes/contenus/:programmeId', {
      programmeId: '@programmeId',
    }, {
      query: {
        isArray: false,
      }
    });
    return programmeContenusApi;
  }])
  .factory('ProgrammeImages', ['$resource', 'Config', function ($resource, Config) {
    var programmeContenusApi = $resource(Config.API_BASE_URL + '/programmes/images/:programmeId', {
      programmeId: '@programmeId',
    }, {
      query: {
        isArray: false,
      },
      get: {
        isArray: true,
      }
    });
    return programmeContenusApi;
  }])
  .service('ProgrammeService', function() {
    var programme;
    return {
      getProgramme: function() {
        return programme;
      },
      setProgramme: function(value) {
        programme = value;
      },
    };
  })
  .factory('ProgrammeActualites', ['$resource', 'Config', function ($resource, Config) {
    var api = $resource(Config.API_BASE_URL + '/programmes/:programmeId/actualites/', {
      programmeId: '@programmeId'
    }, {
      update: {
        method: 'PUT',
      },
      query: {
        isArray: true,
        transformResponse: function(data) {
          data = angular.fromJson(data);
          var result = data.map(a => new api(a));
          return result;
        },
      },
    });
    api.prototype.createOrUpdate = function() {
      if (this.id) {
          return this.$update();
      } else {
          return this.$save();
      }
    };
    return api;
  }]);
