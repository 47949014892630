/**
 * Module definition and dependencies
 */
angular.module('App.Recherche.Controller', [
    'lotsService.Service',
    'ngMessages',
    'btorfs.multiselect',
    'rt.debounce',
    'Crm.ModelStringToArray',
])

/**
 * Controller
 */.controller('FormRechercheLotsCtrl', [
    '$scope', 'ConfigsLots', 'ConfigsProgrammes', '$filter', 'debounce', '$q',
    function($scope, ConfigsLots, ConfigsProgrammes, $filter, debounce, $q) {
        var self = this;
        self.labelMultiSelect = {
            'itemsSelected': 'éléments sélectionnés',
            'selectAll': 'Tout sélectionner',
            'unselectAll': 'Tout déselectionner',
            'search': 'Chercher',
        };
        self.$onInit = function() {
            var promises = [];
            self.initialized = false;
            self.configsLots = {};
            self.listBesoins = ['Habiter', 'Investir'];

            self.sliderVilleSouhaitRayonOptions = {
                id: 'sliderRayon',
                floor: 10,
                ceil: 50,
                step: 5,
                disabled: !self.filtreLots.ville
            };

            if (!self.filtresLots) {
                self.filtresLots = {};
            }

            self.filtresLots.rayon = 10;

            const initVilleSelected = {};

            if (self.filtreLots && self.filtreLots.ville && self.filtreLots.lat && self.filtreLots.lng) {
                initVilleSelected.ville = self.filtreLots.ville ? self.filtreLots.ville : null;
                initVilleSelected.coordinates = [self.filtreLots.lng, self.filtreLots.lat];
            }

            self.villeSelected = Object.assign(initVilleSelected);

            self.labelVilleSouhaitee = self.filtreLots.ville && self.filtreLots.code_postal ? `${self.filtreLots.ville} (${self.filtreLots.code_postal})` : '';
            self.filtresLots.ville = self.filtreLots.ville ? self.filtreLots.ville : '';

            if (self.filtreLots.periode_achat) {
                self.selectedPeriodeAchat(self.filtreLots.periode_achat);
            }

            self.tabPeriodeAchat = self.getQuarter(self.filtreLots.periode_achat, 5);

            if (self.filtresLots && self.filtresLots.programme_id) {
                self.configsLots.programme_id = self.filtresLots.programme_id;
            }

            self.listNatures = [];
            promises.push(ConfigsLots.getNatures(self.configsLots).then(function successCallback(response) {
                response = response.data;
                self.listNatures = response.map(item => item.label);
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getNatures : ' + response);
                return false;
            }));

            self.listStatuts = [];
            promises.push(ConfigsLots.getStatuts(self.configsLots).then(function successCallback(response) {
                self.listStatuts = response.data;
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getStatuts : ' + response);
                return false;
            }));

            self.listFiscalites = [];
            promises.push(ConfigsLots.getFiscalites().then(function successCallback(response) {
                self.listFiscalites = response.data;
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getFiscalites : ' + response);
                return false;
            }));

            self.listAvancements = [];
            promises.push(ConfigsProgrammes.getAvancementsProgrammes().then(function successCallback(response) {
                response = response.data;
                self.listAvancements = response.map(item => item.label);
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsProgrammes getListAvancementsProgramme : ' + response);
                return false;
            }));

            self.listTypes = [];
            ConfigsLots.getTypes(self.configsLots).then(function successCallback(response) {
                self.listTypes = response.data;
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getTypes : ' + response);
                return false;
            });

            self.listEtages = [];
            ConfigsLots.getEtages().then(function successCallback(response) {
                self.listEtages = response.data;
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getTypes : ' + response);
                return false;
            });

          self.listVilles = [];
          ConfigsProgrammes.getVilles().then(function successCallback(response) {
            self.listVilles = response.data;
          }, function errorCallback(response) {
            console.log('Erreur factory ConfigsProgrammes getVilles : ' + response);
            return false;
          });

            self.listProgrammes = [];
            ConfigsProgrammes.getProgrammes().then(function successCallback(response) {
                self.listProgrammes = response.data.data;
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsProgrammes getProgrammes : ' + response);
                return false;
            });

            promises.push(ConfigsLots.getSurfaces().then(function successCallback(response) {
                var surfaceMin = self.initMinMaxSlider(self.filtresLots.surface_min, response.data.surface_min);
                var surfaceMax = self.initMinMaxSlider(self.filtresLots.surface_max, response.data.surface_max);
                self.sliderSurfaces = {
                    initValue: {
                        min: surfaceMin,
                        max: surfaceMax,
                    },
                    min: surfaceMin,
                    max: surfaceMax,
                    options: {
                        id: 'sliderSurfaces',
                        floor: Math.ceil(response.data.surface_min),
                        ceil: Math.ceil(response.data.surface_max),
                        pushRange: true,
                        onChange: self.setValueSliderSurfaces,
                        translate: function(value) {
                            return value + '&nbsp;m<sup>2</sup>';
                        },
                    },
                    onStart: refreshSlider(),
                };
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getSurfaces : ' + response);
                return false;
            }));

            promises.push(ConfigsLots.getPrix().then(function successCallback(response) {
                var prixMin = self.initMinMaxSlider(self.filtresLots.prix_min, response.data.prix_min);
                var prixMax = self.initMinMaxSlider(self.filtresLots.prix_max, response.data.prix_max);
                self.sliderPrixLots = {
                    initValue: {
                        min: prixMin,
                        max: prixMax,
                    },
                    min: prixMin,
                    max: prixMax,
                    options: {
                        id: 'sliderPrixLots',
                        floor: Math.ceil(response.data.prix_min),
                        ceil: Math.ceil(response.data.prix_max),
                        pushRange: true,
                        onChange: self.setValueSliderPrixLots,
                        translate: function(value) {
                            return $filter('currency')(value, response.data.unite, 0);
                        },
                    },
                    onStart: refreshSlider(),
                };
            }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getPrix : ' + response);
                return false;
            }));

            if (self.etatAvancement !== undefined) {
                self.filtresLots.etat_avancement = self.etatAvancement;
            }
            $q.all(promises).then(() => {
                if (!self.btnShow) {
                    $scope.$watch(() => self.filtreLots, debounce(200, function(val, old) {
                        if (self.initFiltreLots && self.initFiltreLots.statut_id && !self.initFiltreLots.isInit) {
                            var arrayFiltreLots = [];
                            angular.forEach(self.listStatuts, function(val, k) {
                                if (self.initFiltreLots.statut_id.indexOf(val.id) !== -1) {
                                    arrayFiltreLots.push(val);
                                }
                            });
                            self.initFiltreLots.isInit = true;
                            if (arrayFiltreLots.length) {
                                self.filtreLots.statut_id = arrayFiltreLots;
                            }
                        }
                        if (val && old && val !== old) {
                            compileFiltres();
                        }
                    }), true);
                }
                self.initialized = true;
            });
        };

        self.setValueSliderSurfaces = function(str,min,max) {
            self.filtreLots.surface_min = parseInt(min);
            self.filtreLots.surface_max = parseInt(max);
        };

        self.setValueSliderPrixLots = function(str,min,max) {
            self.filtreLots.prix_min = parseInt(min);
            self.filtreLots.prix_max = parseInt(max);
        };

        self.initMinMaxSlider = function(valeur, valeurDefault) {
            if (valeur !== null && valeur !== undefined) {
                return Math.ceil(parseFloat(valeur));
            } else {
                return Math.ceil(parseFloat(valeurDefault));
            }
        };
        self.btnRecherche = function() {
            self.display = true;
            compileFiltres();
        };

        function compileFiltres() {
            var query = {};
            //self.filtresLots = {};
            angular.extend(query, self.filtreLots);

            // delete query.ville;
            delete query.code_postal;



            angular.forEach(query, function(value, key) {
                if (value === true) {
                    query[key] = 1;
                }
                if (value === false) {
                    query[key] = 0;
                }
                if (Array.isArray(value)) {
                    if (isObject(value[0])) {
                        query[key] = value.map(item => item.id).join(',').toString();
                    } else {
                        query[key] = value.join(',').toString();
                    }
                }

            });
            query.search = true;

            angular.extend(self.filtresLots, query);

            if(self.villeSelected && self.villeSelected.coordinates) {
              self.filtresLots.lng = self.villeSelected.coordinates[0];
              self.filtresLots.lat = self.villeSelected.coordinates[1];
            }
            else {
              delete self.filtresLots.rayon;
              delete self.filtresLots.lat;
              delete self.filtresLots.lng;
            }
        }

        self.getQuarter = function(preferencesPeriodeAchat, nbYears) {
            var tabReturn = [];
            var currentQuarter = moment().quarter();
            var currentYear = moment().format('YYYY');
            if (preferencesPeriodeAchat) {
                var quarterPreferencesPeriodeAchat = parseInt(moment(preferencesPeriodeAchat.date).quarter());
                var yearPreferencesPeriodeAchat = parseInt(moment(preferencesPeriodeAchat.date).format('YYYY'));
                if (yearPreferencesPeriodeAchat < currentYear) {
                    tabReturn.push(self.getQuarterRange(quarterPreferencesPeriodeAchat, yearPreferencesPeriodeAchat));
                }
            }
            for (var q = currentQuarter; q <= 4; q++) {
                tabReturn.push(self.getQuarterRange(q, parseInt(currentYear)));
            }
            for (var y = 1; y <= nbYears; y++) {
                for (var q = 1; q <= 4; q++) {
                    tabReturn.push(self.getQuarterRange(q, parseInt(currentYear) + y));
                }
            }
            return tabReturn;
        };

        self.getQuarterRange = function(quarter, year) {
            var start = moment({
                year: year,
            }).quarter(quarter).startOf('quarter');
            var end = moment({
                year: year,
            }).quarter(quarter).endOf('quarter').format('DD/MM/YYYY');
            var label = quarter === 1 ? quarter + 'er trimestre ' + year : quarter + 'eme trimestre ' + year;
            var value = start.format('YYYY-MM-DD hh:mm:ss');
            start = start.format('DD/MM/YYYY');
            return {
                start,
                end,
                year,
                label,
                value,
            };
        };

        self.selectedPeriodeAchat = function(date) {
            if (date.date) {
                date = date.date;
            }
            var selectQuarter = moment(date).quarter();
            var selectYear = moment(date).format('YYYY');
            var quarterRange = self.getQuarterRange(selectQuarter, selectYear);
            self.labelSelectedPreferencesPeriodeAchat = 'du ' + quarterRange.start + ' au ' + quarterRange.end;
        };

        self.reinitialiserFiltres = function() {
            self.filtreLots = angular.copy(self.initFiltreLots);
            self.filtresLots = {};
            //Slider prixLots
            self.reinitialiserRZSlider(self.sliderPrixLots);
            //Slider surfaces
            self.reinitialiserRZSlider(self.sliderSurfaces);
            refreshSlider();
            compileFiltres();
        }

        self.reinitialiserRZSlider = function(rzslider) {
            if(rzslider && rzslider.initValue && rzslider.initValue.min !== undefined) {
                rzslider.min = rzslider.initValue.min;
            }
            if(rzslider && rzslider.initValue && rzslider.initValue.max !== undefined) {
                rzslider.max = rzslider.initValue.max;
            }
        }

        function isObject(value) {
            return value && typeof value === 'object' && value.constructor === Object;
        };

        function refreshSlider() {
            setTimeout(function() {
                $scope.$broadcast('rzSliderForceRender');
            }, 800);
        }

        $scope.$watch(() => {return this.villeSelected},() => {
            this.sliderVilleSouhaitRayonOptions.disabled = !this.villeSelected;
            compileFiltres();
        },true);

        $scope.$watch(() => {return this.filtreLots; }, debounce(200, (val, old) => {
            if (angular.equals(val, old)) {
                return;
            }
            compileFiltres();
        }), true);

    },
]).controller('RechercheLotsListCtrl', [
    'NgTableParams', '$scope', 'Lots', '$uibModal', '$stateParams',
    function(NgTableParams, $scope, Lots, $uibModal, $stateParams) {
        var self = this;
        self.$onInit = function() {
            if ($stateParams.paramsRecherche) {
                self.filtresLots = $stateParams.paramsRecherche;
            }
            if ($stateParams.page) {
                self.pageTitle = $stateParams.page.pageTitle;
            }
            if (self.showTitle === undefined) {
                self.showTitle = true;
            }
            self.listLots = new NgTableParams({}, {
                getData: function(params) {
                    self.loadingLots = true;
                    var query = {
                        'limit': params.count(),
                        'page': params.page(),
                        'order': params.sorting() ? params.sorting()[Object.keys(params.sorting())[0]] : '',
                        'sort': params.sorting() ? Object.keys(params.sorting())[0] : '',
                    };
                    angular.extend(query, params.filter());
                    if (query.search) {
                        return Lots.query(query).$promise.then(function(data) {
                            self.loadingLots = false;
                            self.totalResultLots = data.total;
                            params.total(data.total);
                            return data.data;
                        }).catch(function(err) {
                            console.log('promise error', err);
                        });
                    }
                },
            });
        };

        $scope.$watch(() => {
            return this.filtresLots;
        }, (val, old) => {
            if (val.search) {
                angular.extend(self.listLots.filter(false), val);
            }
        }, true);

        /*
         * Modal openModalPanier
         */
        self.openModalPanier = function(lot) {
            var modalInstance = $uibModal.open({
                templateUrl: 'panier/modal-panier.html',
                controller: 'PanierModalCtrl',
                controllerAs: '$ctrl',
                resolve: {
                    lot: function() {
                        return lot;
                    },
                },
            });
            modalInstance.result.then(function(selectedItem) {
                self.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
    },
]).controller('RechercheMinFormCtrl', [
    function() {
        var self = this;
        self.$onInit = function() {
            self.initializedProgramme = false;
        };
    },
]).controller('RechercheBiensCtrl', [
    '$stateParams', 'Contacts', '$scope', 'debounce', 'Paniers',
    function($stateParams, Contacts, $scope, debounce, Paniers) {
        var self = this;
        self.showColProgramme = true;
        self.filtreLots = {};
        self.filtresLots = {search: false};
        self.$onInit = function() {
            if ($stateParams.id) {

                Paniers.query({
                    contact_id: $stateParams.id
                }).$promise.then(function (response) {
                    self.panierContact = response.data;
                }).catch(function (err) {
                    console.log('promise error', err);
                });

                Contacts.get({contactId: $stateParams.id}).$promise.then(function successCallback(response) {
                    var periode_achat;
                    self.contact = response;
                    if (self.contact.complement_date_periode_achat && self.contact.complement_date_periode_achat.date) {
                        periode_achat = moment(self.contact.complement_date_periode_achat.date).format('YYYY-MM-DD');
                    }
                    self.filtreLots = {
                        search: true,
                        paramsCount: self.paramsCount,
                        besoin: self.contact.preferences_besoin,
                        etage: self.contact.preferences_etage,
                        periode_achat: periode_achat,
                        programme_id: self.contact.preferences_programmes,
                        type: self.contact.preferences_type,
                        ville: self.contact.preferences_ville,
                        code_postal: self.contact.preferences_code_postal,
                        prix_min: self.contact.preferences_min_prix,
                        prix_max: self.contact.preferences_max_prix,
                        surface_min: self.contact.preferences_min_surface,
                        surface_max: self.contact.preferences_max_surface,
                        programme_venir: self.contact.preferences_programme_venir,
                        rayon: self.contact.preferences_ville_rayon ? self.contact.preferences_ville_rayon : 10,
                        lat: self.contact.preferences_lat,
                        lng: self.contact.preferences_lng,
                        statut_id: '1,2',
                    };
                    angular.extend(self.filtresLots, self.filtreLots);
                }, function errorCallback(response) {
                    console.log('Erreur factory Contacts get : ' + response);
                    return false;
                });
            }
        };

},
]).controller('RechercheLotsListTableCtrl', [
    '$scope', 'Lots', '$uibModal', '$stateParams', '$q', 'debounce',
    function($scope, Lots, $uibModal, $stateParams, $q, debounce) {
        var self = this;

        self.$onInit = function() {
            self.nombreResultat = 0;
            self.allLoaded = false;
            self.listLots = [];
            self.loadingPromise = $q.resolve();
            self.filtresLotsScroll = {};
            self.filtresLotsScroll.page = 0;
            self.filtresLotsScroll.limit = 15;
            if ($stateParams.paramsRecherche) {
                self.filtresLots = $stateParams.paramsRecherche;
            }
            if ($stateParams.page) {
                self.pageTitle = $stateParams.page.pageTitle;
            }
            if (self.showTitle === undefined) {
                self.showTitle = true;
            }
            angular.extend(self.filtresLotsScroll, self.filtresLots);
        };

        self.queryLots = function() {
            return self.loadingPromise = self.loadingPromise.then(function() {
                self.loading = true;
                let query = Object.assign(self.filtresLotsScroll);
                let queryCopy = angular.copy(query);
                delete queryCopy.page;
                if (!self.allLoaded) {
                    self.filtresLotsScroll.page = self.filtresLotsScroll.page + 1;
                }
                if (!self.allLoaded && self.filtresLotsScroll.search) {
                    self.loadingLots = true;
                    return Lots.query(query).$promise.then(function(response) {
                        self.loadingLots = false;
                        self.nombreResultat = response.total;
                        angular.forEach(response.data, function(item, key) {
                            let lot = new Lots(item);
                            lot.paramsQuery = {};
                            lot.paramsQuery.query = queryCopy;
                            lot.paramsQuery.total = response.total;
                            lot.paramsQuery.index = key * parseInt(response.page);
                            self.listLots.push(lot);
                        });
                        if (self.nombreResultat <= self.listLots.length) {
                            self.allLoaded = true;
                            return $q.resolve();
                        }
                    });
                }
            }).then(function() {
                self.loading = false;
            }).catch(function(err) {
                console.log('promise error', err);
            });
        };

        $scope.$watch(() => {
            return this.filtresLots;
        }, debounce(200, (val, old) => {
            self.loadingPromise.then(() => {
                self.nombreResultat = 0;
                self.allLoaded = false;
                self.listLots = [];
                self.filtresLotsScroll = {};
                angular.extend(self.filtresLotsScroll, val);
                self.filtresLotsScroll.page = 0;
                $scope.$broadcast('filter');
                self.queryLots();
            });
        }), true);

        self.filtreSort = function(sort) {
            if(!self.paramsFiltres) {
                self.paramsFiltres = {};
            }
            self.paramsFiltres.order = (self.paramsFiltres.order === 'asc' && self.paramsFiltres.sort === sort) ? 'desc' : 'asc';
            self.paramsFiltres.sort = sort !== undefined ? sort : '';
            self.filtresLots.sort = self.paramsFiltres.sort;
            self.filtresLots.order = self.paramsFiltres.order;
        }

        self.checkParamsFiltres = function(sort, order) {
            if(self.paramsFiltres && self.paramsFiltres.sort && self.paramsFiltres.sort === sort && self.paramsFiltres.order && self.paramsFiltres.order === order) {
                return true;
            }
            return false;
        }

        /*
         * Modal openModalPanier
         */
        self.openModalPanier = function(lot) {
            var modalInstance = $uibModal.open({
                templateUrl: 'panier/modal-panier.html',
                controller: 'PanierModalCtrl',
                controllerAs: '$ctrl',
                resolve: {
                    lot: function() {
                        return lot;
                    },
                },
            });
            modalInstance.result.then(function(selectedItem) {
                self.selected = selectedItem;
            }, function() {
                $log.info('Modal dismissed at: ' + new Date());
            });
        };
    },
]);
