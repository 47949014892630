/**
 * Module definition and dependencies
 */
angular
  .module("App.Coacquereurs.Controller", ["transactionsService.Service"])

  /**
   * Controller
   */
  .controller("coacquereursCtrl", [
    "Contacts",
    "TransactionsClient",
    function (Contacts, TransactionsClient) {
      let self = this;

      self.$onInit = function () {
        self.columns = ["nom", "prenom", "email", "tel", "tel_mobile"];
        self.coAcquereurs = [];

        self.listTransactions = TransactionsClient.query({
          clientId: self.contact.id,
          etat: 4,
          signature_procedure_status: "null,0,10,20,30,40,45,50,60",
        })
          .$promise.then((data) => {
            data.data.forEach((e) => {
              if (e.coclient_id) {
                self.coAcquereurs.push({
                  id: e.coclient_id,
                  nom: e.coclient_nom,
                  prenom: e.coclient_prenom,
                  email: e.coclient_email,
                  tel: e.coclient_tel,
                  tel_mobile: e.tel_mobile ? e.tel_mobile : null,
                });
              }
            });
          })
          .catch((err) => {
            console.log("promise error", err);
          });

        self.listCoacquereurs = Contacts.queryCoacqs({
          contactId: self.contact.id,
          limit: -1,
        })
          .$promise.then(function (data) {
            data.data.forEach((e) => {
              self.coAcquereurs.push({
                id: e.id,
                nom: e.nom,
                prenom: e.prenom,
                email: e.email,
                tel: e.tel,
                tel_mobile: e.tel_mobile,
              });
            });
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };
    },
  ]);
