/**
 * Module definition and dependencies
 */
angular.module('App.Abonnement', [
    'App.Abonnement.Controller',
])
/**
 * Components
 */.component('modal-abonnement', {
    controller: 'AbonnementCtrl',
    templateUrl: 'abonnement/modal-abonnement.html',
    bindings: {
        vendeurs: '<',
    },
});