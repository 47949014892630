/**
 * Module definition and dependencies
 */
angular.module('App.Programmes.Controller', [
    'ngMessages',
    'infinite-scroll',
    'programmesService.Service',
    'configsService.Service',
    'smoothScroll',
])

/**
 * Controller
 */.controller('ProgrammesCtrl', [
    'Programmes', 'ConfigsLots', '$filter',
    function(Programmes, ConfigsLots, $filter) {
        var self = this;
        var page = 1;
        var totalProgrammes = 0;
        var limitPage = 3;
        var params;
        var listeNatures;
        self.programmes = [];

        self.$onInit = function() {
            self.queryProgrammes();
        };

        self.queryProgrammes = function() {
            var query = {
                'limit': limitPage,
                'page': page,
                'order': '',
                'sort': '',
                'programme_allowed': true
            };

            Programmes.query(query).$promise.then(function(response) {
                self.nombreResultat = response.total;
                totalProgrammes = response.total;

                angular.forEach(response.data, function(programme, key) {

                    /* ---------- getNatures ---------- */
                    ConfigsLots.getNatures({programme_id: programme.id}).then(function successCallback(respNatures) {
                        self.listNatures = respNatures.data.map(item => item.label);

                        angular.forEach(self.listNatures, function(nature, k) {

                            /* ---------- getTypes ---------- */
                            ConfigsLots.getTypes({programme_id: programme.id, nature: nature}).then(function successCallback(respTypes) {
                                if (programme.types === undefined) {
                                    programme.types = [];
                                }
                                if (programme.types[k] === undefined) {
                                    programme.types[k] = {};
                                }
                                programme.types[k].nature = nature.toLowerCase();
                                programme.types[k].type = respTypes.data;
                                programme.types[k].label_types = self.getLabelTypes(programme.types[k]);
                            }, function errorCallback(response) {
                                console.log('Erreur factory StatsActions getStatsActions : ' + response);
                                return false;
                            });

                            /* ---------- getPrix ---------- */
                            ConfigsLots.getPrix({programme_id: programme.id, nature: nature}).then(function successCallback(respPrix) {
                                if (programme.types === undefined) {
                                    programme.types = [];
                                }
                                if (programme.types[k] === undefined) {
                                    programme.types[k] = {};
                                }
                                programme.types[k].prix = respPrix.data;
                                programme.types[k].label_prix = self.getLabelPrix(programme.types[k]);
                            }, function errorCallback(response) {
                                console.log('Erreur factory StatsActions getStatsActions : ' + response);
                                return false;
                            });

                        });

                    }, function errorCallback(response) {
                        console.log('Erreur factory ConfigsLots getNatures : ' + response);
                        return false;
                    });

                    self.programmes.push(programme);

                });
            }).catch(function(err) {
                console.log('promise error', err);
            });
        };

        self.scroll = function() {
            if (limitPage * page < totalProgrammes) {
                page++;
                self.queryProgrammes();
            }
            if (self.nombreResultat && self.nombreResultat <= self.programmes.length) {
                self.allLoaded = true;
            }
        };

        self.getLabelTypes = function(programmeType) {
            var nature = '';
            if (programmeType.nature === 'bureau') {
                nature = programmeType.nature.toLowerCase() + 'x';
            }
            else {
                nature = programmeType.nature.toLowerCase() + 's';
            }
            if (programmeType.type.length === 1) {
                if (nature === 'commerces' || nature === 'bureaux' || nature === 'parkings') {
                    return nature;
                }
                else {
                    return nature + ' ' + programmeType.type[0].label;
                }
            }
            else {
                return nature + ' du ' + programmeType.type[0].label.toUpperCase() + ' au ' +
                    programmeType.type[programmeType.type.length - 1].label.toUpperCase();
            }
        };

        self.getLabelPrix = function(programmeType) {
            if (programmeType.prix) {
                if (programmeType.prix.prix_min === programmeType.prix.prix_max) {
                    return 'à ' + $filter('currency')(programmeType.prix.prix_min, programmeType.prix.unite, 0);
                }
                else {
                    return 'de ' + $filter('currency')(programmeType.prix.prix_min, programmeType.prix.unite, 0) + ' à ' + $filter('currency')(programmeType.prix.prix_max, programmeType.prix.unite, 0);
                }
            }
            return '';
        };
    },
]).filter('ucfirst', function() {
    return function(input) {
        return (!!input) ? input.charAt(0).toUpperCase() + input.substr(1) : '';
    };
});
