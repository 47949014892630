
/**
 * Module definition and dependencies
 */
angular.module('App.Footer.Component', [])

/**
 * Component
 */
.component('appFooter', {
  templateUrl: 'app/footer/footer.html',
});
