/**
 * Module definition and dependencies
 */
angular
  .module("App.Actualites.Controller", [
    "programmesService.Service",
    "actualitesService.Service",
    "Crm.AsDateDirective",
    "trumbowyg-ng",
    "ngAnimate",
    "toaster"
  ])

  /**
   * Controller
   */
  .controller("ActualitesProgrammeCtrl", [
    "ProgrammeActualites",
    "ActualitesProgrammeMedias",
    "$scope",
    "$uibModal",
    function(
      ProgrammeActualites,
      ActualitesProgrammeMedias,
      $scope,
      $uibModal
    ) {
      let self = this;

      self.$onInit = function() {
        if (self.programme && self.programme.id) {
          self.ProgrammeActualites();
        }
      };

      self.ProgrammeActualites = function() {
        ProgrammeActualites.query({
          programmeId: self.programme.id,
          sort: 'DESC'
        })
          .$promise.then(function(response) {
            self.listActualitesProgramme = response;
            angular.forEach(self.listActualitesProgramme, function(item, key) {
              if (item && item.date && item.date.date) {
                item.date = item.date.date;
              }
              if (item && item.id) {
                self.ActualitesProgrammeMedias(item);
              }
              if (item && item.categorie_id) {
                item.categorie_id = item.categorie_id.toString();
              }
            });
          })
          .catch(function(err) {
            console.log("promise error", err);
          });
      };

      self.ActualitesProgrammeMedias = function(actualite) {
        ActualitesProgrammeMedias.query({
          actualite_id: actualite.id
        })
          .$promise.then(function(response) {
            actualite.images = response;
          })
          .catch(function(err) {
            console.log("promise error", err);
          });
      };

      self.openModalEditerActualite = function(actualite) {
        let modalInstance = $uibModal.open({
          templateUrl: "actualites/modal-actualite.html",
          controller: "AjouterActualiteProgrammeCtrl",
          controllerAs: "$ctrl",
          resolve: {
            programme: function() {
              return self.programme;
            },
            actualite: function() {
              return actualite;
            }
          }
        });
        modalInstance.result.then(
          function(selectedItem) {
            self.selected = selectedItem;
          },
          function() {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };

      $scope.$on("updateProgrammeActualites", function(event, data) {
        self.ProgrammeActualites();
      });
    }
  ])
  .controller("BtnAjouterActualiteProgrammeCtrl", [
    "$uibModal",
    function($uibModal) {
      let self = this;

      self.openModalAjouterActualite = function() {
        let modalInstance = $uibModal.open({
          templateUrl: "actualites/modal-actualite.html",
          controller: "AjouterActualiteProgrammeCtrl",
          controllerAs: "$ctrl",
          resolve: {
            programme: function() {
              return self.programme;
            },
            actualite: function() {
              return undefined;
            }
          }
        });
        modalInstance.result.then(
          function() {},
          function() {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
    }
  ])
  .controller("AjouterActualiteProgrammeCtrl", [
    "programme",
    "Config",
    "Upload",
    "ActualitesProgramme",
    "$rootScope",
    "$uibModalInstance",
    "actualite",
    "$filter",
    "ActualitesProgrammeMedias",
    "$uibModal",
    function(
      programme,
      Config,
      Upload,
      ActualitesProgramme,
      $rootScope,
      $uibModalInstance,
      actualite,
      $filter,
      ActualitesProgrammeMedias,
      $uibModal
    ) {
      let self = this;

      self.view = 1;

      self.datePickerOptions = {
        formatYear: "yy",
        startingDay: 1,
        timezone: "Europe/Paris"
      };

      self.maxSizeFiles = 4400000;

      self.datas = {};

      self.listDeleteImages = [];

      self.$onInit = function() {
        self.programme = programme;

        if (actualite) {
          self.actualite = actualite;
          self.titreModal = "Édition de l'actualité - " + self.actualite.titre;
          if (self.actualite.date && self.actualite.date.date) {
            self.actualite.date = moment(self.actualite.date.date);
          }
        } else {
          self.actualite = new ActualitesProgramme();
          self.actualite.date = moment();
          self.actualite.programme_id = self.programme.id;
          self.titreModal =
            "Ajouter une actualité au programme " +
            self.programme.nom.toUpperCase();
        }
      };

      self.saveActualite = function() {
        if (self.actualite.programme_id) {
          self.view = 2;
          self.actualite.date = moment(self.actualite.date).format(
            "YYYY-MM-DD HH:mm:ss"
          );

          return self.actualite.createOrUpdate().then(
            function(response) {
              self.actualite = response;

              if (self.listDeleteImages && self.listDeleteImages.length > 0) {
                angular.forEach(self.listDeleteImages, function(item, key) {
                  item
                    .$delete()
                    .then(function(response) {})
                    .catch(function(err) {
                      console.log("promise error", err);
                    });
                });
              }

              if (
                self.listUploadDocuments &&
                self.listUploadDocuments.length > 0
              ) {
                self.view = 3;
                self.uploadMultiPart(self.listUploadDocuments);
              } else {
                $uibModalInstance.close();
                $rootScope.$broadcast("updateProgrammeActualites");
              }
            },
            function errorCallback(response) {
              self.view = 4;
              self.messageError = "Une erreur est survenue.";
              self.error = response;
              console.log("Erreur factory Actualites $save : " + response);
              return false;
            }
          );
        }
      };

      self.totalSizeFiles = function() {
        self.totalSizeListUploadDocuments = 0;
        if (self.listUploadDocuments) {
          angular.forEach(self.listUploadDocuments, function(item, key) {
            if (item.size) {
              self.totalSizeListUploadDocuments += parseFloat(item.size);
            }
          });
        }
      };

      self.supprimerDocuments = function(tab, index) {
        tab.splice(index, 1);
        self.totalSizeFiles();
      };

      self.uploadMultiPart = function(files) {
        angular.forEach(files, function(file) {
          file.upload = Upload.upload({
            url:
              Config.API_BASE_URL +
              "/programmes/actualites/medias?actualite_id=" +
              self.actualite.id,
            arrayKey: ".i",
            data: {
              file: file
            },
            method: "POST"
          });

          file.upload.then(
            function(response) {
              if (!Array.isArray(self.arrayFilesUrls)) {
                self.arrayFilesUrls = [];
              }
              self.arrayFilesUrls.push(response.data);
              if (files.length === self.arrayFilesUrls.length) {
                $uibModalInstance.close();
                $rootScope.$broadcast("updateProgrammeActualites");
              }
            },
            function(response) {
              if (response.status > 0) {
                $scope.errorMsg = response.status + ": " + response.data;
              } else {
                self.view = 4;
                self.messageError = response;
              }
            },
            function(evt) {
              file.progress = Math.min(
                100,
                parseInt((100.0 * evt.loaded) / evt.total)
              );
            }
          );
        });
      };

      self.checkForDelete = function(image) {
        let img = $filter("filter")(
          self.listDeleteImages,
          {
            id: image.id
          },
          true
        )[0];
        let index = self.listDeleteImages.indexOf(img);
        if (index == -1) {
          return false;
        }
        return true;
      };

      self.deleteImage = function(image) {
        let img = $filter("filter")(
          self.listDeleteImages,
          {
            id: image.id
          },
          true
        )[0];
        let index = self.listDeleteImages.indexOf(img);
        if (index == -1) {
          self.listDeleteImages.push(image);
        } else {
          self.listDeleteImages.splice(index, 1);
        }
      };

      self.openModalSupprimerActualite = function() {
        $uibModalInstance.close();
        let modalInstance = $uibModal.open({
          templateUrl: "actualites/modal-confirmation-supprimer-actualite.html",
          controller: "SupprimerActualiteProgrammeCtrl",
          controllerAs: "$ctrl",
          resolve: {
            actualite: function() {
              return self.actualite;
            }
          }
        });
        modalInstance.result.then(
          function() {},
          function() {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
    }
  ])
  .controller("SupprimerActualiteProgrammeCtrl", [
    "actualite",
    "ActualitesProgramme",
    "toaster",
    "$rootScope",
    "$uibModalInstance",
    function(
      actualite,
      ActualitesProgramme,
      toaster,
      $rootScope,
      $uibModalInstance
    ) {
      let self = this;

      self.$onInit = function() {
        self.actualite = actualite;
      };

      self.valider = function() {
        let actu = new ActualitesProgramme();
        return actu.$delete({ id: self.actualite.id }).then(
          function(response) {
            $rootScope.$broadcast("updateProgrammeActualites");
            $uibModalInstance.close();
            toaster.pop({
              type: "success",
              title: "",
              body: "L'actualité a bien été supprimée."
            });
          },
          function errorCallback(response) {
            console.log("Erreur factory Actualites remove : " + response);
            return false;
          }
        );
      };
    }
  ]);
