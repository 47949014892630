/**
 * Module definition and dependencies
 */
angular.module('App.AvancementsMultiselect.Controller', [
  'configsService.Service',
])

/**
 * Controller
 */
.controller('AvancementsMultiselectCtrl', ['ConfigsContacts',
  function(ConfigsContacts) {

    this.$onInit = () => {
      this.getAvancements();
    };

    this.labelMultiSelect = {
      itemsSelected: 'éléments sélectionnés',
      selectAll: 'Tout sélectionner',
      unselectAll: 'Tout déselectionner',
      search: 'Chercher',
    };

    this.getAvancements = () => {
      ConfigsContacts.getAvancements().then(response => {
        this.liste = response.data;
      }).catch(error => {
        console.error('error ', error);
      });
    };

  },
]);
