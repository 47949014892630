/**
 * Module definition and dependencies
 */
angular.module("App", [
  //Angular & 3rd party
  "ui.router",
  //Meanie modules
  "Api.Service",
  "Analytics.Service",
  "Convert.Service",
  "DuplicateRequestsFilter.Decorator",
  "FormControls.Component",
  "KeyCodes.Service",
  "Log.Service",
  "Storage.Service",
  "Store.Service",
  "ui.bootstrap",
  "ngSanitize",
  "angular-loading-bar",
  "fancyboxplus",

  //Core modules
  "App.Config",
  "App.Templates",
  "App.Component",
  "App.Auth",
  "App.Error",

  //App components
  "App.Dashboard",
  "App.Home",
  "App.Admin.SelectVendeur",
  "App.Admin.Attribution",
  "App.Admin.Export",
  "App.Admin.DeleteContact",
  "App.Crm.RechercheContacts",
  "App.Crm.StatsActionsAtraiter",
  "App.Crm.StatsActionsRealisee",
  "App.Crm.StatsBusiness",
  "App.Crm.StatsChiffreAffaire",
  "App.Crm.StatsContacts",
  "App.Crm.StatsSourcesContacts",
  "App.Crm.FichesContactsConsultees",
  "App.Crm.ContactsDenonces",
  "App.Crm.FiltreContacts",
  "App.Crm.FiltreDenonces",
  "App.Crm.FiltreTransactions",
  "App.Crm.FiltreActions",
  "App.Crm.ListLotTransactions",
  "App.Crm.Action",
  "App.AlertesProgrammes",
  "App.MesContacts",
  "App.MesActions",
  "App.MesContactsDenonces",
  "App.MesTransactions",
  "Crm.FormContact",
  "App.Recherche",
  "App.Programme",
  "App.Programmes",
  "App.Lot",
  "App.Optionner",
  "App.Crm.TransactionsContact",
  "App.Profil",
  "App.DenoncerContact",
  // "App.Documents",
  "App.IframeComponent",
  "App.Panier",
  "App.FiltreOrder",
  "App.Calendrier",
  "App.Coacquereurs",
  "App.Abonnement",
  "App.Actualites",
  "App.AvancementCommercial",
  "App.Notifications",
  "App.Transaction",
  "App.AvancementsMultiselect",
  "App.FiltreSearch",

  //Shared services
  "Crm.Moment.Service",

  //lib-front-angular-crm
  "Telephone.Filter",
  "Bytes.Filter",
  "Crm.Moment.Filter",
  "Crm.Toggle",
  "Crm.BtnSignature",
  "Crm.StatutSignature",
  "Crm.Transaction",
  "Crm.Modal",
  "Crm.SelectContact",
  "Crm.ContacterContacts",
  "Crm.EspaceClient",
  "Crm.HistoriquesContact",
  "Crm.Documents",
  "Crm.RechercherContacts",
  "Crm.MesContactsLibFront",
  "Crm.Lot",
  "Crm.SearchInput",
  "Crm.SearchVille",
  "Crm.Files",
  "Crm.Country",
  "Crm.SignaturesSigners",
]);
