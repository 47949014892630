/**
 * Module definition and dependencies
 */
angular
  .module("App.Controller", [
    "contactsService.Service",
    "transactionsService.Service",
    "actionsService.Service",
    "configsService.Service",
    "hl.sticky",
  ])

  /**
   * Main application controller
   */
  .controller("AppCtrl", [
    "$rootScope",
    "$scope",
    "Profil",
    "Membre",
    "Commercialisateur",
    "$state",
    "$transitions",
    "ContactsAtraiter",
    "TransactionsOptions",
    "$window",
    "$cacheFactory",
    "$timeout",
    "ListActions",
    "ConfigService",
    "ConfigsCommercialisateurs",
    "Auth",
    "$location",
    "CommercialisateurService",
    "Config",
    "$http",
    "ConfigsContratReservation",
    function (
      $rootScope,
      $scope,
      Profil,
      Membre,
      Commercialisateur,
      $state,
      $transitions,
      ContactsAtraiter,
      TransactionsOptions,
      $window,
      $cacheFactory,
      $timeout,
      ListActions,
      ConfigService,
      ConfigsCommercialisateurs,
      Auth,
      $location,
      CommercialisateurService,
      Config,
      $http,
      ConfigsContratReservation
    ) {
      let self = this;
      let params;
      let queryParams = {};
      self.isPageProgrammesLot = false;
      $rootScope.classUiView = "";
      // Moment en français
      moment.locale("fr");

      $rootScope.pathImageAjaxLoader = "/images/ajax-loader.gif";

      self.getViewClasses = function () {
        let classes = [];
        if ($rootScope.classUiView) {
          return $rootScope.classUiView;
        }
        if ($rootScope.expandedSidebarNav) {
          classes.push("expandedSidebarNav");
        }
        if ($state.current.name === "home") {
          classes.push("container-fluid p-0 main");
        } else if (
          [
            "mesContacts",
            "mesActions",
            "mesTransactions",
            "calendrier",
            "transaction.transactionhistorique",
            "transaction.clienthistorique",
            "transaction.clientdocuments",
          ].indexOf($state.current.name) > -1
        ) {
          classes.push("container-fluid mt-3 mb-3 main");
        } else if (
          $state.current.name === "formContact.panier" ||
          $state.current.name === "formContact.historique" ||
          $state.current.name === "formContact.infoscomplementaires" ||
          $state.current.name === "formContact.documents" ||
          $state.current.name === "formContact.transactions"
        ) {
          classes.push("container  main");
        } else if (["lot"].indexOf($state.current.name) > -1) {
          classes.push("container-fluid mb-3 main");
        } else {
          classes.push("container main mt-3 mb-3");
        }
        return classes.join(" ");
      };

      self.submenuChildVisible = false;

      self.displaySubMenu = function (item) {
        if (item == "true") {
          if (self.submenuChildVisible == false) {
            self.submenuChildVisible = true;
          } else {
            self.submenuChildVisible = false;
          }
        }
      };

      self.$onInit = function () {
        params = ConfigService.getParams();
        if (params && params.vendeur) {
          queryParams.vendeur = params.vendeur;
        }
        self.rechercheMobile = false;
        self.collapseMobile = false;
        self.getViewClasses();
        self.contactsSelectionner = [];
        getTotaux();
        let paramsUrl = $location.search();
        if (paramsUrl && paramsUrl.url_source) {
          $rootScope.url_source = paramsUrl.url_source;
        }
        Profil.get().$promise.then(function (membre) {
          if (
            membre.employeur_id &&
            membre.rattachement_id &&
            membre.employeur_id === membre.rattachement_id
          ) {
            membre.is_partenaire = false;
          } else {
            membre.is_partenaire = true;
          }

          if (Auth.hasRole("admin")) {
            membre.is_admin = true;
          } else {
            membre.is_admin = false;
          }

          $rootScope.membreConnecter = membre;

          function initCommercialisateur(response) {
            CommercialisateurService.setCommercialisateur(response);
            $rootScope.commercialisateur = response;
            if (response.domaine) {
              let themePromoteur =
                "css/themes-promoteurs/theme-" + response.domaine + ".css";
              $rootScope.cheminThemePromoteurCss = themePromoteur;
              // Test Favicon
              const faviconUrl =
                "https://static.aws.immodesk.fr/favicon/" +
                response.domaine +
                ".ico";
              $http.get(faviconUrl).then(
                (r) => {
                  // Success
                  $rootScope.faviconUrl = faviconUrl;
                },
                (r) => {
                  $rootScope.faviconUrl = "favicon.png";
                }
              );
              ConfigsCommercialisateurs.getConfigCommercialisateurs().then(
                (response) => {
                  $rootScope.configsCommercialisateurs = response.data;
                }
              );
            }
          }

          Commercialisateur.get({
            commercialisateurId: membre.rattachement_id
              ? membre.rattachement_id
              : membre.employeur_id,
          }).$promise.then((response) => {
            if (membre.rattachement_id && response.useCommercialisateurTheme) {
              Commercialisateur.get({
                commercialisateurId: membre.employeur_id,
              }).$promise.then(initCommercialisateur);
            } else {
              initCommercialisateur(response);
            }
          });

          if (membre.membre_referent_id) {
            Membre.get({
              membreId: membre.membre_referent_id,
            }).$promise.then(function (mReferent) {
              $rootScope.membreReferent = mReferent;
              self.contentContactPromoteur = "Contactez votre ";
              self.contentContactPromoteur +=
                mReferent.civilite === "f"
                  ? "conseiller chez "
                  : "conseillère chez ";
              self.contentContactPromoteur += mReferent.employeur_nom;
            });
          }
        });

        $rootScope.base_url_avatars = Config.BASE_URL_AVATARS;

        $rootScope.inBack =
          paramsUrl && paramsUrl.inback === "true" ? true : false;

        self.checkFixedSidebar();

        ConfigsContratReservation.get()
          .$promise.then((response) => {
            $rootScope.configsContratReservation = response;
          })
          .catch((error) => {
            console.error("error : ", error);
          });
      };

      $rootScope.cheminThemeCss = "css/theme-front.css";

      angular.element($window).bind("scroll resize", function () {
        $timeout(function () {
          self.checkFixedSidebar();
        }, 80);
      });

      self.checkFixedSidebar = function () {
        self.fixedSidebar = false;
        let winInnerHeight = $window.innerHeight;
        let winOffset = $window.pageYOffset;
        if (
          (winInnerHeight <= 1024 && winOffset <= 160) ||
          winInnerHeight < 800
        ) {
          self.fixedSidebar = true;
        }
      };

      self.recherche = function (recherche, page) {
        $state.go("recherche", {
          paramsRecherche: recherche,
          page: page,
        });
      };

      function getTotaux() {
        getTotalContactsAtraiter(queryParams);
        //getTotalTransactions(queryParams);
        //getTotalActions(queryParams);
      }

      function getTotalContactsAtraiter(query) {
        ContactsAtraiter.getStatsContactsAtraiter(query).then(
          function (response) {
            return (self.totalContact = response.data.total);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsContacts getStatsContacts : " + response
            );
            return false;
          }
        );
      }

      function getTotalTransactions(query) {
        TransactionsOptions.getTransactionsPending(query).then(
          function (response) {
            return (self.totalTransaction = response.data.total);
          },
          function errorCallback(response) {
            console.log(
              "Erreur factory StatsContacts StatsTransactionsOptions : " +
                response
            );
            return false;
          }
        );
      }

      function getTotalActions(query) {
        query.done = 0;
        query.limit = 1;
        ListActions.get(query).$promise.then(
          function (response) {
            return (self.totalActions = response.total);
          },
          function errorCallback(response) {
            console.log("Erreur factory listActions get : " + response);
            return false;
          }
        );
      }

      $scope.$on("updateContacts", function (event, data) {
        getTotalContactsAtraiter(queryParams);
      });

      $scope.$on("actualiserStatsTransactions", function (event, data) {
        getTotalTransactions(queryParams);
      });

      $scope.$on("updateMesActions", function (event, data) {
        getTotalActions(queryParams);
      });

      self.closeTab = function () {
        self.activeTab = null;
      };

      $window.onclick = function () {
        if (
          self.activeTab !== null &&
          document.getElementsByClassName("modal").length === 0
        ) {
          self.activeTab = null;
          $scope.$apply();
        }
      };

      $scope.isActive = function (viewLocation) {
        if (
          $state.$current.data &&
          $state.$current.data.isActive &&
          $state.$current.data.isActive === viewLocation
        ) {
          return true;
        }
        return false;
      };

      $transitions.onSuccess({}, () => {
        self.activeTab = null;
      });

      //Vide le cache 5min pour les services config
      $timeout(function () {
        let $httpDefaultCache = $cacheFactory.get("$http");
        $httpDefaultCache.removeAll();
      }, 300000);
    },
  ]);
