angular
  .module("App.Transaction", [
    "Crm.Transaction",
    "Crm.Contact",
    "Crm.Vendeur",
    "Crm.LotTransaction",
    "Crm.Annexe",
  ])
  .config($stateProvider => {
    $stateProvider.state("transaction", {
      parent: "app",
      abstract: true,
      url: "/transaction/{id:int}",
      component: "transaction",
      params: {
        paramsFiltresTransactions: null,
        stateSource: 'mesTransactions'
      },
      resolve: {
        transaction: function(Transactions, $stateParams) {
          return Transactions.get({
            id: $stateParams.id
          }).$promise;
        },
        lot: function(transaction, Lot) {
          return Lot.get({
            lotId: transaction.lot_id
          }).$promise;
        },
        vendeur: function(transaction, Membre) {
          return Membre.get({
            membreId: transaction.vendeur_id
          }).$promise;
        },
        client: function(transaction, Contacts) {
          return Contacts.get({
            contactId: transaction.client_id
          }).$promise;
        }
      },
      data: {
        auth: true
      }
    })
    .state('transaction.transactionhistorique', {
      url: '/transaction-historique',
      templateUrl: 'src/transaction/tabs/transaction-historique.html',
      controller: function() {}
    })
    .state('transaction.clienthistorique', {
      url: '/client-historique',
      templateUrl: 'src/transaction/tabs/client-historique.html',
      controller: function() {}
    })
    .state('transaction.clientdocuments', {
      url: '/client-documents',
      templateUrl: 'src/transaction/tabs/client-documents.html',
      controller: function() {}
    })
  });
