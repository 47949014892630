angular.module('favorisService.Service', [
    'ngResource'
])

/**
 * Service
 */
    .factory('Favoris', ['$http', 'Config',
        function ($http, Config) {
            return {
                'getFavoris': function () {
                    return $http({
                        method: 'GET',
                        url: Config.API_BASE_URL + '/favoris',
                    });
                },
            }
        }
    ])
