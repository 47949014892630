/**
 * Module definition and dependencies
 */
angular.module('App.Admin.Export', [
    'App.Admin.Export.Controller',
])
/**
 * Components
 */.component('exportExcelContacts', {
    controller: 'exportContactsCtrl',
    templateUrl: 'admin/export/excel-contacts.html',
    bindings: {
        filtres: '<',
        nombreContacts: '<',
        addClass: '<',
    },
})
.component('exportExcelTransactions', {
    controller: 'exportTransactionsCtrl',
    templateUrl: 'admin/export/excel-transactions.html',
    bindings: {
        filtres: '<',
        addClass: '<',
    },
})
.component('exportExcelActions', {
    controller: 'exportActionsCtrl',
    templateUrl: 'admin/export/excel-actions.html',
    bindings: {
        filtres: '<',
        addClass: '<',
    },
});