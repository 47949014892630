/**
 * Module definition and dependencies
 */
angular.module('App.Crm.FiltreActions.Controller', [
  'statsService.Service',
  'actionsService.Service',
]).controller('TableFiltreActionsCtrl', [
  '$scope', 'TypesAction', 'debounce',
  function($scope, TypesAction, debounce) {
    var self = this;
    var saveDone;
    self.$onInit = function() {
      self.filtresActions = {};
      self.filtresActions.is_contact = true;
      self.filtresActions.is_client = true;

      if (self.paramsFiltresActions) {
        angular.extend(self.filtresActions, self.paramsFiltresActions);
        if (self.paramsFiltresActions.groupe_action) {
          var arrayGroupeAction = self.paramsFiltresActions.groupe_action.split(',');
          self.filtresActions.groupe_action = arrayGroupeAction.map(function(value) {
            return parseInt(value, 10);
          });
        }
      }
      else {
        self.paramsFiltresActions = {};
      }

      if (!self.filtresActions.done) {
        self.filtresActions.done = 0;
      }

      self.getTotalActionsContact();
      self.getTotalActionsClient();
      self.getTypesAction(self.filtresActions);
    };

    self.datePickerOptions = {
      formatYear: 'yy',
      startingDay: 1,
      showWeeks: false,
    };

    self.checkRadio = function checkRadio() {
      if(self.filtresActions.done === undefined && saveDone === undefined) {
        self.filtresActions.done = 0;
      }
      else if(self.filtresActions.done === undefined && saveDone !== undefined) {
          self.filtresActions.done = saveDone;
      }
      else {
        saveDone = self.filtresActions.done;
        self.getTotalActionsContact();
        self.getTotalActionsClient();
        self.updateStatsType();
      }
    };

    self.updateStatsType = function() {
      self.getTypesAction(self.filtresActions);
    };

    self.modifierSelection = function modifierSelection(choix) {
      if (!self.filtresActions.groupe_action) {
        self.filtresActions.groupe_action = [];
      }
      var index = self.filtresActions.groupe_action.indexOf(choix);
      if (index > -1) {
        self.filtresActions.groupe_action.splice(index, 1);
      }
      else {
        self.filtresActions.groupe_action.push(choix);
      }
    };

    self.getTypesAction = function getTypesAction(params) {
      var paramsTypesAction = [];
      angular.extend(paramsTypesAction, params);
      if (paramsTypesAction.groupe_action) {
        delete paramsTypesAction.groupe_action;
      }
      paramsTypesAction = self.formateFiltres(paramsTypesAction);
      return TypesAction.getTypesAction(paramsTypesAction).then(function successCallback(response) {
        self.filtreTypeTraiter = response.data.data;
      }, function errorCallback(response) {
        console.log('Erreur factory StatsActions getTypesAction : ' + response);
        return false;
      });
    };

    self.getTotalActionsContact = function getTotalActionsContact() {
      var params = {
        done: self.filtresActions.done,
      };
      return TypesAction.getTotalActionsContact(params).then(function successCallback(response) {
        return self.totalContacts = response.data.total;
      }, function errorCallback(response) {
        console.log('Erreur factory TypesAction getActionsContact : ' + response);
        return false;
      });
    };

    self.getTotalActionsClient = function getTotalActionsClient() {
      var params = {
        done: self.filtresActions.done,
      };
      return TypesAction.getTotalActionsClient(params).then(function successCallback(response) {
        return self.totalClients = response.data.total;
      }, function errorCallback(response) {
        console.log('Erreur factory TypesAction getActionsClient : ' + response);
        return false;
      });
    };

    self.formateFiltres = function(val) {
      var query = {};
      angular.extend(query, val);
      angular.forEach(query, function(value, key) {
        if (value === true) {
          query[key] = 1;
        }
        if (value === false) {
          query[key] = 0;
        }
        if (Array.isArray(value)) {
          query[key] = value.join(',').toString();
        }
      });
      return query;
    };

    $scope.$on('updateMesActions', function(event, data) {
      self.getTotalActionsContact();
      self.getTotalActionsClient();
      self.getTypesAction(self.filtresActions);
    });

    $scope.$watch(() => this.filtresActions, debounce(200, function() {
      angular.extend(self.paramsFiltresActions, self.formateFiltres(self.filtresActions));
    }), true);

    $scope.$watch(() => this.actualiserStats, debounce(200, function() {
      if (self.actualiserStats) {
        self.actualiserStats = false;
        self.getTypesAction(self.filtresActions);
      }
    }), true);
  },
]);
