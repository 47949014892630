/**
 * Module definition and dependencies
 */
angular.module('App.Crm.RechercheContacts', [
  'App.Crm.RechercheContacts.Controller',
])
  .component('rechecheContacts', {
    controller: 'RechecheContactsCtrl',
    templateUrl: 'crm/recherche-contacts/recherche-contacts.html',
  });
