/**
 * Module definition and dependencies
 */
angular.module('App.Home.Controller', [
    'statsService.Service',
    'favorisService.Service',
    'infinite-scroll',
    'programmesService.Service'
  ])

  /**
   * Controller
   */
  .controller('HomeCtrl', ['$scope' ,'StatsContacts', 'Favoris', '$state', 'ConfigsLots', '$filter',
    function($scope, StatsContacts, Favoris, $state, ConfigsLots, $filter) {
      var self = this;
      $scope.slides = [];

      // CAROUSEL
      self.intervalCarousel = 4000;
      self.noWrapSlides = false;
      self.activeCarousel = 0;
      self.noTransition = true;

      // Tableau de bord
      self.paramsContactsPanel = {
        title: 'Nouveaux contacts à traiter',
        icons: 'person',
        filtres: {
          groupe_avancement: 1,
          limit: 3,
        },
        panelBodyClass: 'contacts_a_traiter',
      }

      self.paramsTransactionsPanel = {
        title: 'Options en cours',
        icons: 'timer',
        filtres: {
          etat: 4,
          type_id: 1,
          order: 'asc',
          sort: 'date_validite',
          limit: 1,
          lot_statut: '1,2,5',
        },
          panelBodyClass: 'transactions',
      }

      self.paramsAlertesProgrammesPanel = {
        title: 'Alertes programmes',
        icons: 'warning',
        filtres: {
          limit: 3,
        },
        panelBodyClass: 'alertes_programmes',
      }

      self.paramsActionsPanel = {
        title: 'Actions à effectuer',
        icons: 'settings',
        filtres: {
          limit: 3,
        },
        panelBodyClass: 'actions_a_effectuer',
      }

      // onInit
      self.$onInit = function() {
        Favoris.getFavoris().then(function(response) {
          angular.forEach(response.data, function (programme, key) {

            //Programme en favoris
            if(programme.programme_id) {

              /* ---------- getNatures ---------- */
              ConfigsLots.getNatures({programme_id: programme.programme_id}).then(function successCallback(respNatures) {
                self.listNatures = respNatures.data.map(item => item.label);
                angular.forEach(self.listNatures, function(nature, k) {
                    /* ---------- getTypes ---------- */
                    ConfigsLots.getTypes({programme_id: programme.programme_id, nature: nature}).then(function successCallback(respTypes) {
                        if (programme.types === undefined) {
                            programme.types = [];
                        }
                        if (programme.types[k] === undefined) {
                            programme.types[k] = {};
                        }
                        programme.types[k].nature = nature.toLowerCase();
                        programme.types[k].type = respTypes.data;
                        programme.types[k].label_types = self.getLabelTypes(programme.types[k]);
                    }, function errorCallback(response) {
                        console.log('Erreur factory StatsActions getStatsActions : ' + response);
                        return false;
                    });
                    /* ---------- getPrix ---------- */
                    ConfigsLots.getPrix({programme_id: programme.programme_id, nature: nature}).then(function successCallback(respPrix) {
                        if (programme.types === undefined) {
                            programme.types = [];
                        }
                        if (programme.types[k] === undefined) {
                            programme.types[k] = {};
                        }
                        programme.types[k].prix = respPrix.data;
                        programme.types[k].label_prix = self.getLabelPrix(programme.types[k]);

                    }, function errorCallback(response) {
                        console.log('Erreur factory StatsActions getStatsActions : ' + response);
                        return false;
                    });
                });
              }, function errorCallback(response) {
                console.log('Erreur factory ConfigsLots getNatures : ' + response);
                return false;
              });

              $scope.slides.push(programme);

            }
          });
        }, function errorCallback(response) {
          console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
          return false;
        });

        self.getLabelTypes = function(programmeType) {
          var nature = '';
          if (programmeType.nature === 'bureau') {
              nature = programmeType.nature.toLowerCase() + 'x';
          }
          else {
              nature = programmeType.nature.toLowerCase() + 's';
          }
          if (programmeType.type.length === 1) {
              if (nature === 'commerces' || nature === 'bureaux' || nature === 'parkings' || nature === 'parking') {
                  return nature;
              }
              else {
                  return nature + ' ' + programmeType.type[0].label;
              }
          }
          else {
              return nature + ' du ' + programmeType.type[0].label.toUpperCase() + ' au ' +
                  programmeType.type[programmeType.type.length - 1].label.toUpperCase();
          }
      };

      self.getLabelPrix = function(programmeType) {
          if (programmeType.prix) {
              if (programmeType.prix.prix_min === programmeType.prix.prix_max) {
                  return 'à ' + $filter('currency')(programmeType.prix.prix_min, programmeType.prix.unite, 0);
              }
              else {
                  return 'de ' + $filter('currency')(programmeType.prix.prix_min, programmeType.prix.unite, 0) + ' à ' + $filter('currency')(programmeType.prix.prix_max, programmeType.prix.unite, 0);
              }
          }
          return '';
      };

      self.getStatsContacts({
          is_client: 1,
          is_contact: 1,
        });
      };

      self.getStatsContacts = function(params) {
        StatsContacts.getStatsContacts(params).then(function(response) {
          self.statsContacts = response.data.data;
        }, function errorCallback(response) {
          console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
          return false;
        });
      };

      self.getLabelTypes = function(programme) {
        if(programme.types.length === 1) {
          return programme.types[0].label;
        }
        else {
          return 'Du ' + programme.types[0].label + ' au ' + programme.types[programme.types.length-1].label;
        }
      };

      self.getLabelPrix = function(programme) {
        if(programme.prix.prix_min === programme.prix.prix_max) {
          return 'à ' + $filter('currency')(programme.prix.prix_min, programme.prix.unite, 0);
        }
        else {
          return 'de ' + $filter('currency')(programme.prix.prix_min, programme.prix.unite, 0) + ' à ' + $filter('currency')(programme.prix.prix_max, programme.prix.unite, 0);
        }
      };

      self.lienContactGroupe = function(id) {
        $state.go('mesContacts', {
          filtres: { transaction_types: id }
        });
      };

    }
  ]);
