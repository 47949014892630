/**
 * Module definition and dependencies
 */
angular
  .module("App.MesTransactions.Controller", [
    "transactionsService.Service",
    "ngMessages",
    "toaster",
    "ngAnimate",
    "verifTemplatesService.Service",
  ])

  /**
   * Controller
   */ .controller("MesTransactionsCtrl", [
    "$storage",
    "$stateParams",
    function ($storage, $stateParams) {
      let self = this;
      self.loading = false;
      self.vendeurSelected = null;

      self.$onInit = function () {
        self.filtresTransactions = self.filtresTransactions || {};
        self.totalTransactions = 0;
        let mode = $storage.local.get("transactionGridPref", "cards");
        self.viewMode = mode;

        if (self.programme) {
          self.filtresTransactions.programme_id = self.programme.id;
        }

        if ($stateParams.paramsFiltresTransactions) {
          self.filtresTransactions = $stateParams.paramsFiltresTransactions;
        } else {
          self.filtresTransactions = {};
        }

        if ($stateParams.paramsFiltresTransactionsSignature) {
          self.filtresTransactionsSignature =
            $stateParams.paramsFiltresTransactionsSignature;
        } else {
          self.filtresTransactionsSignature = {};
        }
      };

      self.setViewMode = function (mode) {
        $storage.local.set("transactionGridPref", mode);
        self.viewMode = mode;
      };

      self.dateDebutChange = function (val) {
        const date = new Date(val);
        self.filtresTransactions.start_date = date.toLocaleDateString("fr-CA");
      };
      self.dateFinChange = function (val) {
        const date = new Date(val);
        self.filtresTransactions.end_date = date.toLocaleDateString("fr-CA");
      };
    },
  ])
  .controller("MesTransactionsCardsCtrl", [
    "Transactions",
    "$scope",
    "$q",
    "$uibModal",
    "$log",
    "$state",
    "ListeContacts",
    "Contacts",
    "$window",
    "ContratSigne",
    "Contrat",
    "DocumentsProcedure",
    "$rootScope",
    "VerifTemplates",
    function (
      Transactions,
      $scope,
      $q,
      $uibModal,
      $log,
      $state,
      ListeContacts,
      Contacts,
      $window,
      ContratSigne,
      Contrat,
      DocumentsProcedure,
      $rootScope,
      VerifTemplates
    ) {
      let self = this;
      let limit = 6;
      let page = 1;
      self.transactions = [];
      self.totalTransactions = -1;
      self.loadingPromise = $q.resolve();
      self.allLoaded = false;

      self.queryTransactions = () => {
        if (!self.allLoaded) {
          return (self.loadingPromise = self.loadingPromise
            .then(() => {
              if (
                self.totalTransactions >= 0 &&
                self.totalTransactions <= self.transactions.length
              ) {
                self.allLoaded = true;
                return $q.resolve();
              }
              self.loading = true;

              const query = Object.assign({}, self.filtresTransactions);
              query.limit = limit;
              query.page = page;

              page++;
              return Transactions.query(query).$promise.then((response) => {
                self.totalTransactions = response.total;
                if (self.totalTransactions > 1) {
                  self.labelTransactions =
                    self.totalTransactions + " résultats";
                } else {
                  self.labelTransactions = self.totalTransactions + " resultat";
                }
                angular.forEach(response.data, (transaction) => {
                  transaction.client = {
                    id: transaction.client_id,
                    email: transaction.client_email,
                    nom: transaction.client_nom,
                    prenom: transaction.client_prenom,
                  };
                  if (transaction.type_id === 1) {
                    transaction.timerArkaLabel = "Option échue dans";
                  }
                  if (transaction.type_id === 2) {
                    transaction.timerArkaLabel = "Réservation échue dans";
                  }
                  if (transaction.recommande_additional_data) {
                    transaction.recommande_additional_data_parse = JSON.parse(
                      transaction.recommande_additional_data
                    );
                  }
                  //********************** */
                  transaction.label = undefined;
                  transaction.step = 0;
                  if (transaction.type_id === 1 && transaction.etat === 4) {
                    if (
                      [1, 2, 3, 4, 5, 6].includes(transaction.lot_statut) &&
                      transaction.rang !== "1"
                    ) {
                      transaction.step = 1;
                    }
                    if (
                      transaction.date_validite &&
                      transaction.date_validite.date
                    ) {
                      transaction.btnDisabled =
                        new Date() > new Date(transaction.date_validite.date);
                    }
                    transaction.step = 1;
                  } else if (
                    transaction.type_id === 2 &&
                    transaction.etat === 4 &&
                    (transaction.signature_procedure_status === null ||
                      [-30, -20].includes(
                        transaction.signature_procedure_status
                      ))
                  ) {
                    transaction.step = 2;
                  } else if (
                    transaction.type_id === 3 &&
                    transaction.etat === 4
                  ) {
                    transaction.step = 9;
                  } else if (
                    transaction.type_id === 4 &&
                    transaction.etat === 4
                  ) {
                    transaction.step = 4;
                  } else if (
                    transaction.type_id === 2 &&
                    transaction.etat === 4 &&
                    transaction.lot_statut === 3
                  ) {
                    if (transaction.signature_procedure_status === 0) {
                      transaction.step = 3;
                    } else if (transaction.signature_procedure_status === 10) {
                      transaction.step = 4;
                    } else if (transaction.signature_procedure_status === 20) {
                      transaction.step = 4;
                    } else if (transaction.signature_procedure_status === 30) {
                      transaction.step = 5;
                    } else if (transaction.signature_procedure_status === 52) {
                      transaction.step = 7;
                    } else if (
                      [40, 45].includes(transaction.signature_procedure_status)
                    ) {
                      transaction.step = 6;
                    }
                  } else if (
                    transaction.type_id === 5 &&
                    transaction.etat === 4 &&
                    transaction.lot_statut === 3 &&
                    (transaction.signature_procedure_status === null ||
                      [0, 10, 20, 30, 40, 45, 50, 52, 60].includes(
                        transaction.signature_procedure_status
                      ))
                  ) {
                    transaction.step = 7;
                  } else if (
                    transaction.type_id === 6 &&
                    transaction.etat === 4 &&
                    transaction.lot_statut === 3 &&
                    (transaction.signature_procedure_status === null ||
                      [0, 10, 20, 30, 40, 45, 50, 52, 60].includes(
                        transaction.signature_procedure_status
                      ))
                  ) {
                    transaction.step = 7;
                  } else if (
                    transaction.type_id === 7 &&
                    transaction.etat === 4 &&
                    transaction.lot_statut === 3 &&
                    (transaction.signature_procedure_status === null ||
                      [0, 10, 20, 30, 40, 45, 50, 52, 60].includes(
                        transaction.signature_procedure_status
                      ))
                  ) {
                    transaction.step = 7;
                  } else if (
                    transaction.type_id === 8 &&
                    transaction.etat === 4 &&
                    transaction.lot_statut === 6 &&
                    (transaction.signature_procedure_status === null ||
                      [0, 10, 20, 30, 40, 45, 50, 60].includes(
                        transaction.signature_procedure_status
                      ))
                  ) {
                    transaction.step = 8;
                  }

                  //********************** */
                  this.verifTemplates(transaction);
                  this.getProcedure(transaction);
                  self.transactions.push(transaction);
                });
                if (self.totalTransactions <= self.transactions.length) {
                  self.allLoaded = true;
                }
                self.loading = false;
              });
            })
            .then(function () {
              self.loading = false;
            })
            .catch(function (err) {
              console.log("promise error", err);
            }));
        }
      };

      this.verifTemplates = (transaction) => {
        VerifTemplates.get({
          programmeId: transaction.programme_id,
        }).$promise.then((response) => {
          transaction.isTemplateAvailable = response.template;
        });
      };

      this.getProcedure = (transaction) => {
        if (
          !transaction ||
          !transaction.id ||
          !transaction.contrat_reservation_id
        ) {
          return;
        }
        DocumentsProcedure.get({
          transactionId: transaction.id,
          documentId: transaction.contrat_reservation_id,
        })
          .$promise.then((response) => {
            const membreProcedureId =
              "immodesk-membre-" + $rootScope.membreConnecter.id;
            if (response.validators) {
              const validators = response.validators; // TODO a delete après update de l'API membre
              transaction.myValidator = validators.find(
                (v) => v.reference === membreProcedureId
              );
            }
            const reservant = response.reservant; // TODO a delete après update de l'API membre
            transaction.myReservant =
              reservant && reservant.reference === membreProcedureId
                ? reservant
                : null;
          })
          .catch((error) => {
            console.log(error);
            if (error.data && error.data.message) {
              $rootScope.$broadcast("toasterError", error.data.message);
            }
          });
      };

      self.writeToContact = function (contact) {
        ListeContacts.setListeContacts([contact]);
        $state.go("contacterContacts");
      };

      self.openLink = function (link) {
        $window.open(link, "_blank");
      };

      self.voirContrat = function (transaction, type) {
        let maWindow = window.open();
        if (transaction.contrat_reservation_id !== 0 && type === "generer") {
          //Récupération document généré
          Contrat.get({
            documentId: transaction.contrat_reservation_id,
          }).$promise.then(function (response) {
            if (response.document_url) {
              maWindow.location.href = response.document_url;
            }
          });
        }
        if (
          transaction.contrat_reservation_envoye_id !== 0 &&
          type === "signer"
        ) {
          //Récupération document signé
          ContratSigne.get({
            documentId: transaction.contrat_reservation_envoye_id,
          }).$promise.then(function (response) {
            if (response.signed_document_url) {
              maWindow.location.href = response.signed_document_url;
            }
          });
        }
      };

      self.getTypeLabel = function (item) {
        let label = item.type_label;
        if (item.type_id === 1) {
          label += " au rang " + item.rang;
        }
        return label;
      };

      /*
       * Modal
       */
      self.openModal = function (item, typeAction) {
        if (typeAction === "valider" && item.rang !== "1") {
          return false;
        }
        let modalInstance = $uibModal.open({
          templateUrl: "src/transactions/modal-transaction.html",
          controller: "TransactionsModalCtrl",
          controllerAs: "$ctrl",
          resolve: {
            item: function () {
              return item;
            },
            typeAction: function () {
              return typeAction;
            },
            filtresTransactions: function () {
              return self.filtresTransactions;
            },
          },
        });
        modalInstance.result.then(
          function (typeAction) {
            if (typeAction === "abandonner") {
              self.transactions.splice(self.transactions.indexOf(item), 1);
            }
          },
          function () {
            $log.info("Modal dismissed at: " + new Date());
          }
        );
      };
      self.cardStateList = [
        { values: 1, label: "Option" },
        { values: 2, label: "Réservation" },
        { values: 3, label: "Génération contrat" },
        { values: 4, label: "Signature" },
        { values: 5, label: "Contre-sign." },
        { values: 6, label: "Envoi SRU" },
        { values: 7, label: "Purge SRU" },
        { values: 8, label: "Signature notaire" },
        { values: 9, label: "vente" },
      ];

      /*
       * Modal openModalContact
       */
      self.openModalContact = function (item) {
        console.log("call open modal transaction");
        console.log(item);
        Contacts.get({
          contactId: item.client_id,
        })
          .$promise.then(function (response) {
            console.log(response);
            ListeContacts.setListeContacts([response]);
            console.log("step 1");
            let modalInstance = $uibModal.open({
              windowTemplateUrl: "templates/modal/modal-window-lg.html",
              templateUrl:
                "Crm/contacter-contacts/modal-contacter-contacts.html",
              controller: "ContacterContactsCtrl",
              controllerAs: "$ctrl",
            });
            console.log(modalInstance);
            modalInstance.result.then(
              function (selectedItem) {
                self.selected = selectedItem;
              },
              function () {
                $log.info("Modal dismissed at: " + new Date());
              }
            );
          })
          .catch(function (err) {
            console.log("promise error", err);
          });
      };

      $scope.$on("actualiserTransactions", (event) => {
        $window.location.reload();
      });

      $scope.$watch(
        () => this.filtresTransactions,
        (val, old) => {
          if (Object.keys(this.filtresTransactions).length !== 0) {
            self.transactions.length = 0;
            self.allLoaded = false;
            self.totalTransactions = -1;
            page = 1;
            self.queryTransactions();
          }
        },
        true
      );
    },
  ])
  .controller("TransactionsPanelCtrl", [
    "Transactions",
    "$state",
    "Programme",
    function (Transactions, $state, Programme) {
      let self = this;
      self.items = [];

      self.$onInit = function () {
        if (self.params.filtres) {
          Transactions.query(self.params.filtres).$promise.then(function (
            response
          ) {
            self.nombreResultat = response.total;
            angular.forEach(response.data, function (transaction) {
              Programme.get({
                programmeId: transaction.programme_id,
              }).$promise.then(
                function (responseProgramme) {
                  transaction.programme = responseProgramme;
                  if (transaction.type_id === 1) {
                    transaction.timerArkaLabel = "Option échue dans";
                  }
                  if (transaction.type_id === 2) {
                    transaction.timerArkaLabel = "Réservation échue dans";
                  }
                  self.items.push(transaction);
                },
                function errorCallback(resp) {
                  console.log("Erreur factory Programme get : " + resp);
                  return false;
                }
              );
            });
          });
        }
      };

      self.goToPage = function () {
        $state.go("mesTransactions");
      };
    },
  ])
  .controller("TransactionTopBarCtrl", [
    function () {
      this.label = undefined;
      this.step = 0;
      this.$onInit = () => {
        this.initLabel();
      };
      this.initLabel = () => {
        const transactionDateValidite =
          this.transaction.data_validite && this.transaction.data_validite.date
            ? moment(this.transaction.data_validite.date)
            : undefined;

        this.date_creation = moment(this.transaction.date_creation.date).format(
          "DD/MM/YY à HH:mm"
        );
        const currentDate = moment();

        if (
          this.transaction.type_id === 1 &&
          this.transaction.etat === 4 &&
          (!transactionDateValidite || transactionDateValidite < currentDate)
        ) {
          if (
            [1, 2, 5].includes(this.transaction.lot_statut) &&
            this.transaction.rang !== "1"
          ) {
            this.label = `Option de rang ${this.transaction.rang}`;
          } else if ([3, 4, 6].includes(this.transaction.lot_statut)) {
            this.label = "Option gelée";
          }
          this.step = 1;
        } else if (
          this.transaction.type_id === 2 &&
          this.transaction.etat === 4 &&
          (this.transaction.signature_procedure_status === null ||
            [-30, -20].includes(this.transaction.signature_procedure_status))
        ) {
          this.label = "Réservé";
          this.step = 2;
        } else if (
          this.transaction.type_id === 3 &&
          this.transaction.etat === 4
        ) {
          this.label = "Vendu";
          this.step = 9;
        } else if (
          this.transaction.type_id === 4 &&
          this.transaction.etat === 4
        ) {
          this.label = "En cours de signature";
          this.step = 4;
        } else if (
          this.transaction.type_id === 2 &&
          this.transaction.etat === 4 &&
          this.transaction.lot_statut === 3
        ) {
          if (this.transaction.signature_procedure_status === 0) {
            this.label = "Contrat généré";
            this.step = 3;
          } else if (this.transaction.signature_procedure_status === 10) {
            this.label = "En attente signature client";
            this.step = 4;
          } else if (this.transaction.signature_procedure_status === 20) {
            this.label = "En attente validateurs internes";
            this.step = 4;
          } else if (this.transaction.signature_procedure_status === 30) {
            this.label = "En attente contre signature";
            this.step = 5;
          } else if (
            [40, 45].includes(this.transaction.signature_procedure_status)
          ) {
            this.label = "En attente AR24";
            this.step = 6;
          }
        } else if (
          this.transaction.type_id === 5 &&
          this.transaction.etat === 4 &&
          this.transaction.lot_statut === 3 &&
          (this.transaction.signature_procedure_status === null ||
            [0, 10, 20, 30, 40, 45, 50, 60].includes(
              this.transaction.signature_procedure_status
            ))
        ) {
          this.label = "En attente dépôt dossier bancaire";
          this.step = 7;
        } else if (
          this.transaction.type_id === 6 &&
          this.transaction.etat === 4 &&
          this.transaction.lot_statut === 3 &&
          (this.transaction.signature_procedure_status === null ||
            [0, 10, 20, 30, 40, 45, 50, 60].includes(
              this.transaction.signature_procedure_status
            ))
        ) {
          this.label = "En attente accord bancaire";
          this.step = 7;
        } else if (
          this.transaction.type_id === 7 &&
          this.transaction.etat === 4 &&
          this.transaction.lot_statut === 3 &&
          (this.transaction.signature_procedure_status === null ||
            [0, 10, 20, 30, 40, 45, 50, 60].includes(
              this.transaction.signature_procedure_status
            ))
        ) {
          this.label = "En attente offre de prêt";
          this.step = 7;
        } else if (
          this.transaction.type_id === 8 &&
          this.transaction.etat === 4 &&
          this.transaction.lot_statut === 6 &&
          (this.transaction.signature_procedure_status === null ||
            [0, 10, 20, 30, 40, 45, 50, 60].includes(
              this.transaction.signature_procedure_status
            ))
        ) {
          this.label = "En attente signature notaire";
          this.step = 8;
        }
      };
    },
  ]);
