/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsSourcesContacts.Controller', [
    'statsService.Service',
    'configsService.Service',
    'chart.js',

]).controller('StatsSourcesContactsCtrl', [
    'ConfigsProgrammes', 'StatsContacts', 'StatsClients', '$scope', 'debounce', '$filter', 'orderByFilter',
    function(ConfigsProgrammes, StatsContacts, StatsClients, $scope, debounce, $filter, orderBy) {
        var self = this;
        var colorHash = new ColorHash();

        self.$onInit = function() {
            self.init = false;
            self.sourcesProspects = {};
            self.sourcesClients = {};
            self.sourcesClients.options = {};
            self.sourcesProspects.options = {};
            self.filtreSourcesContacts = {};
            self.chartColorsClients = [];
            self.chartColorsProspects = [];
            self.sourcesClients.filtresTable = {propertyName: 'total', reverse: true};
            self.sourcesProspects.filtresTable = {propertyName: 'total', reverse: true};

            //params sources des prospects
            self.paramsSourcesProspects = {
                is_contact: 1,
            };

            //params sources des clients
            self.paramsSourcesClients = {
                is_client: 1,
            };

            ConfigsProgrammes.getProgrammes().then(function(response) {
                self.listProgrammes = response.data.data;
            }, function errorCallback(response) {
                console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
                return false;
            });
        };

        self.sortBy = function(propertyName, data) {
            data.filtresTable.reverse = (propertyName !== null && data.filtresTable.propertyName === propertyName) ? !data.filtresTable.reverse : false;
            data.filtresTable.propertyName = propertyName;
            data.response = orderBy(data.response, data.filtresTable.propertyName, data.filtresTable.reverse);
        };

        function getStatsSourcesClients() {
            StatsClients.getStatsSourcesClients(self.paramsSourcesClients).then(function(response) {
                self.sourcesClients.response = response.data.data;
                self.sourcesClients.labels = response.data.data.map(item => item.label);
                self.sourcesClients.data = response.data.data.map(item => parseInt(item.total));
                self.sourcesClients.total = response.data.total;
                getStatsSourcesProspects();
            }, function errorCallback(response) {
                console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
                return false;
            });
        };

        function getStatsSourcesProspects() {
            StatsContacts.getStatsSourcesProspects(self.paramsSourcesProspects).then(function(response) {
                self.sourcesProspects.response = response.data.data;
                self.sourcesProspects.labels = response.data.data.map(item => item.label);
                self.sourcesProspects.data = response.data.data.map(item => parseInt(item.total));
                self.sourcesProspects.total = response.data.total;
                angular.forEach(self.sourcesProspects.response, function(value, key) {
                    var color = colorHash.hex(value.id);
                    value.color = color;
                    self.chartColorsProspects.push(color);
                    value.total = parseInt(value.total);
                });
                angular.forEach(self.sourcesClients.response, function(value, key) {
                    var color = colorHash.hex(value.id);
                    value.color = color;
                    self.chartColorsClients.push(color);
                    value.total = parseInt(value.total);
                    var item = $filter('filter')(self.sourcesProspects.response, {id: value.id}, true)[0];
                    if(item) {
                        value.tauxTransformation = (parseInt(value.total) * 100) / (parseInt(value.total) + parseInt(item.total));
                    }
                    else {
                        value.tauxTransformation = 100;
                    }
                });
                self.sortBy('total', self.sourcesProspects);
                self.sortBy('total', self.sourcesClients);
                self.init = true;
            }, function errorCallback(response) {
                console.log('Erreur factory StatsContacts getStatsContacts : ' + response);
                return false;
            });
        };

        function compileFiltres(filtre) {
            var query = {};
            angular.extend(query, filtre);
            angular.forEach(query, function(value, key) {
                if (value === true) {
                    query[key] = 1;
                }
                if (value === false) {
                    query[key] = 0;
                }
                if (Array.isArray(value)) {
                    if (isObject(value[0])) {
                        query[key] = value.map(item => item.id).join(',').toString();
                    } else {
                        query[key] = value.join(',').toString();
                    }
                }
            });
            angular.extend(self.paramsSourcesProspects, query);
            angular.extend(self.paramsSourcesClients, query);
            getStatsSourcesClients();
        }

        function isObject(value) {
            return value && typeof value === 'object' && value.constructor === Object;
        };

        $scope.$watch(() => this.filtreSourcesContacts, debounce(200, function(val, old) {
            self.init = false;
            self.chartColorsClients = [];
            self.chartColorsProspects = [];
            compileFiltres(val);
        }), true);

    },
]);
