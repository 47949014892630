angular
  .module('App.FiltreSearch', ['App.FiltreSearch.Controller'])
  .component('filtreSearch', {
    controller: 'FiltreSearchCtrl',
    templateUrl: 'filtres/filtre-search.html',
    bindings: {
      filtres: '=',
      placeholder: '@',
    },
  });
