/**
 * Module definition and dependencies
 */
angular.module('App.Programmes', [
    'App.Programmes.Controller',
])

/**
 * Config
 */
    .config($stateProvider => {
        $stateProvider.state('programmes', {
            parent: 'app',
            url: '/programmes',
            component: 'programmes',
            data: {
                auth: true,
                isActive: 'programmes',
            },
        });
    })
    /**
     * Route component
     */
    .component('programmes', {
        controller: 'ProgrammesCtrl',
        templateUrl: 'programmes/programmes.html',
    });
