/**
 * Module definition and dependencies
 */
angular.module('App.Crm.StatsActionsAtraiter.Controller', [
    'statsService.Service'
])
.controller('ListeStatsActionsAtraiterCtrl', ['StatsActions', '$state',
    function (StatsActions, $state) {
        var self = this;
        self.$onInit = function () {
            getStatsActions({
                done: 0,
                start_date: '',
            }).then(function (response) {
                self.totalStatsActionsTraiter = response.data.total;
                self.statsActionsTraiter = response.data.data;
                self.start_date = response.data.start_date.date;
            });
        };

        self.lienActionGroupe = function (id) {
            var paramsFiltresActions = {
                done:0,
                groupe_action: id.toString(),
            };
            $state.go('mesActions', {paramsFiltresActions: paramsFiltresActions});
        }
        function getStatsActions(params) {
            return StatsActions.getStatsActions(params).then(function successCallback(response) {
                return response;
            }, function errorCallback(response) {
                console.log('Erreur factory StatsActions getStatsActions : ' + response);
                return false;
            });
        }

        self.getPercent = function (val) {
            if (self.totalStatsActionsTraiter === 0) {
                return 0;
            }
            return Math.round(val / self.totalStatsActionsTraiter * 100);
        };

    }
]);
